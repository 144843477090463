import * as Dialog from '@radix-ui/react-dialog';
import {useRef, useState, type FC, useEffect} from 'react';
import SVG from 'react-inlinesvg';
import {IconWithCallbackButton} from 'src/components/ui/button/icon-with-callback-button.tsx';
import Delete from '../../../assets/delete.svg';
import EditSquare from '../../../assets/edit-square.svg';
import Save from '../../../assets/save.svg';
import {Modal} from '../../../components/modal/modal.tsx';
import {Button} from '../../../components/ui/button';
import {
  useAddProzessGroupMutation,
  useDeleteProzessGroupMutation,
  useUpdateProzessGroupMutation,
} from './prozessgroups.generated.ts';

type ProzessGruppe = {
  id: string;
  name: string;
};
export type ProzessGruppenVerwaltenProps = {
  gruppen: ProzessGruppe[];
  onSaveDeleteOrUpdate: () => void;
};

export const ProzessGruppenVerwalten: FC<ProzessGruppenVerwaltenProps> = ({
  gruppen,
  onSaveDeleteOrUpdate,
}) => {
  const [, addProzessGroupMutation] = useAddProzessGroupMutation();

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant='outlined'>Prozessgruppen verwalten</Button>
      </Dialog.Trigger>
      <Modal title='Gruppen verwalten'>
        <div className='flex flex-col'>
          <p className='font-bold'>Prozessgruppen:</p>
          {gruppen.map((g) => (
            <EditableTextInput
              key={g.id}
              onSaveDeleteOrUpdate={onSaveDeleteOrUpdate}
              gruppe={g}
            />
          ))}

          <Button
            className='ml-auto mt-3'
            variant='outlined'
            onClick={async () => {
              await addProzessGroupMutation({
                input: {
                  name: 'Neue Prozessgruppe',
                },
              });
              onSaveDeleteOrUpdate();
            }}
          >
            + Neue Prozessgruppe erstellen
          </Button>
        </div>
      </Modal>
    </Dialog.Root>
  );
};

const EditableTextInput: FC<{
  onSaveDeleteOrUpdate: () => void;
  gruppe: ProzessGruppe;
}> = ({onSaveDeleteOrUpdate, gruppe}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const [error, setError] = useState('');

  const [, updateProzessGroupMutation] = useUpdateProzessGroupMutation();
  const [, deleteProzessGroupMutation] = useDeleteProzessGroupMutation();

  useEffect(() => {
    if (saveEnabled) {
      inputRef.current?.focus();
    }
  }, [saveEnabled]);

  return (
    <>
      <div className='mt-2 flex items-center justify-between rounded-md border'>
        <input
          ref={inputRef}
          disabled={!saveEnabled}
          className='mr-3 w-full rounded-md p-2 focus:bg-slate-200 focus:outline-none disabled:bg-transparent '
          type='text'
          id={gruppe.id}
          defaultValue={gruppe.name}
        />
        {saveEnabled ? (
          <IconWithCallbackButton
            onClick={() => {
              const inputValue = inputRef.current?.value;
              if (inputValue && inputValue.length > 0) {
                updateProzessGroupMutation({
                  input: {
                    id: gruppe.id,
                    name: inputValue,
                  },
                });
              } else {
                setError('Der Gruppenname darf nicht leer sein.');
              }
              setSaveEnabled(!saveEnabled);
            }}
            iconSrc={Save}
          />
        ) : (
          <IconWithCallbackButton
            onClick={() => {
              setSaveEnabled(!saveEnabled);
            }}
            iconSrc={EditSquare}
          />
        )}

        <button
          type='button'
          className='material-symbols-outlined mx-2 flex gap-2 text-red-500'
          onClick={async () => {
            const response = await deleteProzessGroupMutation({
              input: {
                id: gruppe.id,
              },
            });
            onSaveDeleteOrUpdate();
            const errors = response.data?.deleteProzessGroup?.errors?.[0];
            if (!errors) return;
            switch (errors.__typename) {
              case 'ProzessGroupAssignedToProzess':
                setError(
                  'Die Gruppe ist einem Prozess zugeordnet und kann deshalb nicht gelöscht werden.',
                );
                break;
              case 'ProzessGroupNotFound':
                setError(
                  'Die Gruppe existiert nicht und konnte deshalb nicht gelöscht werden.',
                );
                break;
              default:
                setError('Ein Fehler ist aufgetreten.');
            }
          }}
        >
          <SVG src={Delete} className='fill-red-500' />
        </button>
      </div>
      {error && <div className='text-sm text-red-600'>{error}</div>}
    </>
  );
};
