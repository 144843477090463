import {AssignedAusfuehrungenFilter} from '../../../__generated__/graphql.ts';
import {useAusfuehrungenQuery} from '../ausfuehrungen.generated';
import {AusfuehrungenList} from '../components/ausfuehrungen-list.tsx';

export const NeueAntraege = () => {
  const [{data}] = useAusfuehrungenQuery({
    variables: {filter: AssignedAusfuehrungenFilter.Neu},
  });

  const my = data?.my;

  if (!my) return null;

  const ausfuehrungen =
    'assignedAusfuehrungen' in my ? my.assignedAusfuehrungen : [];

  return (
    <div className='container flex h-full flex-col gap-4 py-8'>
      <h1 className='text-2xl font-bold'>Neue Anträge</h1>
      <p className='w-1/2'>
        In der Tabelle finden Sie alle neuen Anträge, welche zur Bearbeitung
        stehen. Zusätzlich können Sie nach Antragstypen filtern oder gezielte
        Anträge suchen.
      </p>
      {ausfuehrungen.length ? (
        <AusfuehrungenList ausfuehrungen={ausfuehrungen} />
      ) : (
        <p className='mt-12 font-bold'>Keine neuen Anträge</p>
      )}
    </div>
  );
};
