import {type FC, forwardRef, useState} from 'react';
import {MdOutlineVisibility, MdOutlineVisibilityOff} from 'react-icons/md';
import {getFieldError} from '../common';
import {
  InputField,
  type InputFieldProps,
  type InputFormFieldProps,
} from '../input-field';

export type PasswordInputFieldProps = Omit<
  InputFieldProps,
  'type' | 'endAdornment'
>;
export const PasswordInputField = forwardRef<
  HTMLInputElement,
  PasswordInputFieldProps
>(({...props}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const endAdornment = (
    <button
      type='button'
      className='size-4'
      onClick={() => setShowPassword((prev) => !prev)}
    >
      {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
    </button>
  );

  return (
    <InputField
      {...props}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      endAdornment={endAdornment}
    />
  );
});
PasswordInputField.displayName = 'PasswordInputField';

export type PasswordInputFormFieldProps = Omit<
  InputFormFieldProps,
  'type' | 'endAdornment'
>;
export const PasswordInputFormField: FC<PasswordInputFormFieldProps> = ({
  on,
  rules,
  ...props
}) => (
  <PasswordInputField {...on(rules)} {...props} error={getFieldError(on)} />
);
