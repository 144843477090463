import {useCallback} from 'react';
import {
  type Node as ReactFlowNode,
  type Edge as ReactFlowEdge,
} from 'reactflow';
import {useProzessEditorStore} from './index.ts';

export const useProzessEditorSelectionHandler = () => {
  const prozess = useProzessEditorStore((state) => state.prozess);

  const schritte = prozess.schritte;
  const verbindungen = prozess.verbindungen;

  const selectSchritt = useProzessEditorStore((state) => state.selectSchritt);
  const selectVerbindung = useProzessEditorStore(
    (state) => state.selectVerbindung,
  );

  return useCallback(
    (nodes: ReactFlowNode[], edges: ReactFlowEdge[]) => {
      const firstSelectedNode = nodes[0];

      if (!firstSelectedNode || schritte.length === 0) {
        selectSchritt(null);
      } else {
        const selectedSchritt =
          schritte.find((s) => s.id === firstSelectedNode.id) ?? null;

        selectSchritt(selectedSchritt);
      }

      const firstSelectedEdge = edges[0];
      if (!firstSelectedEdge || verbindungen.length === 0) {
        selectVerbindung(null);
      } else {
        const selectedVerbindung =
          verbindungen.find((v) => v.id === firstSelectedEdge.id) ?? null;

        selectVerbindung(selectedVerbindung);
      }
    },
    [schritte, selectSchritt, selectVerbindung, verbindungen],
  );
};
