import {useAuth} from '../context/auth/auth.tsx';
import {asymmetricUnwrapKeyWithCache} from '../crypto/crypto.tsx';
import {useAsyncMemo} from './use-async-memo.ts';
import {type CryptoCache} from './use-crypto-cache.ts';

export function useDecryptedKey(
  encryptedKey: string | undefined | null,
  cryptoCache?: CryptoCache,
) {
  const {organisationPrivateKey} = useAuth();

  return useAsyncMemo(async () => {
    if (organisationPrivateKey === undefined || !encryptedKey) {
      return undefined;
    }

    return await asymmetricUnwrapKeyWithCache(
      organisationPrivateKey,
      encryptedKey,
      cryptoCache,
    );
  }, [encryptedKey, organisationPrivateKey, cryptoCache]);
}
