import {useFormBuilder} from '@atmina/formbuilder';
import {type FC} from 'react';
import SVG from 'react-inlinesvg';
import {InputFormField} from 'src/components/form/fields/input-field';
import {SelectFormField} from 'src/components/form/fields/select-field';
import {Button} from 'src/components/ui/button';
import Search from '../../../assets/search.svg';
import {NeuenProzessAnlegenButton} from './components/neuen-prozess-anlegen-button.tsx';
import {ProzessRow} from './components/prozess-row.tsx';
import {ProzessGruppenVerwalten} from './prozess-gruppen-verwalten';
import {useProzessUebersichtQuery} from './prozesse.generated';

type ProzesseSearch = {
  search: string;
  gruppe: string;
};

const queryContext = {additionalTypenames: ['ServicePortalLink']};

export const Prozesse: FC = () => {
  const formBuilder = useFormBuilder<ProzesseSearch>();

  const search = formBuilder.fields.search.$useWatch();
  const gruppe = formBuilder.fields.gruppe.$useWatch();

  const [{data}, refetch] = useProzessUebersichtQuery({
    variables: {groupId: gruppe, search: search},
    context: queryContext,
  });

  const userFacingProzesse =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.prozesseUndLeistungen
      : [];

  const prozessGroups =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.prozessGroups
      : [];

  return (
    <div className='container flex h-full flex-col gap-6 px-4 pt-20'>
      <div className='flex flex-wrap gap-4'>
        <div className='flex-1'>
          <h1 className='text-2xl font-bold'>Prozessverwaltung</h1>
          <p>
            In der Tabelle finden Sie eine Übersicht aller Prozesse und die
            Möglichkeit diese zu bearbeiten. Mit der Suche können Sie einzelne
            Prozesse suchen.
          </p>
        </div>
        <div className='flex flex-wrap content-center gap-4'>
          <ProzessGruppenVerwalten
            gruppen={prozessGroups}
            onSaveDeleteOrUpdate={async () => {
              refetch({requestPolicy: 'network-only'});
            }}
          />
        </div>
      </div>
      <div className='flex flex-wrap items-center gap-4'>
        <InputFormField
          on={formBuilder.fields.search}
          label='Nach Prozessnamen suchen...'
          endAdornment={
            <Button
              variant='solid'
              className='flex w-min items-center gap-2 rounded-l-none px-4'
            >
              <SVG src={Search} className='size-5 fill-white' />
              Suchen
            </Button>
          }
          className='w-[38rem]'
        />
        <SelectFormField
          on={formBuilder.fields.gruppe}
          label='Nach Gruppen filtern'
          items={[
            {key: 'alle', name: 'Alle Gruppen', value: undefined},
            ...prozessGroups.map((g) => ({
              key: g.id,
              value: g.id,
              name: g.name,
            })),
          ]}
          className='w-60'
        />
        <NeuenProzessAnlegenButton />
      </div>
      <table>
        <thead className='text-left'>
          <tr>
            <th className='pb-2 pl-5 pr-12'>Name</th>
            <th className='pb-2'>Gruppe</th>
            <th className='pb-2 pr-20'>Version</th>
            <th />
          </tr>
        </thead>
        <tbody className='border-t-2'>
          {userFacingProzesse.map((p) => (
            <ProzessRow key={p.id} prozess={p} prozessGroups={prozessGroups} />
          ))}
        </tbody>
        {userFacingProzesse.length === 0 && (
          <tfoot>
            <tr>
              <td colSpan={3}>Keine Prozesse gefunden.</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
