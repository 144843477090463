import {type FC} from 'react';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {type AntragDetailAusfuehrung} from '../../detail.generated.ts';

export type TimeFormularFeldProps = {
  slotName: string;
  ausfuehrung: AntragDetailAusfuehrung;
};

export const TimeFormularFeld: FC<TimeFormularFeldProps> = ({
  slotName,
  ausfuehrung,
}) => (
  <SlotValue
    value={ausfuehrung.slotValues.find((sv) => sv.slotName === slotName) ?? ''}
  >
    {(value) =>
      new Date(value as string).toLocaleTimeString('de-DE', {
        timeStyle: 'short',
      })
    }
  </SlotValue>
);
