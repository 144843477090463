import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {
  type EditorSelectionStore,
  createEditorSelectionSlice,
} from './editor-selection.ts';
import {
  type ProzessStore,
  createProzessWithAenderungenSlice,
} from './prozess-aenderungen.ts';

export const useProzessEditorStore = create<
  ProzessStore & EditorSelectionStore
>()(
  immer((...a) => ({
    ...createProzessWithAenderungenSlice(...a),
    ...createEditorSelectionSlice(...a),
  })),
);
