import {AnimatePresence, motion} from 'framer-motion';
import {type FC, type PropsWithChildren} from 'react';

export type SidebarProps = PropsWithChildren<{isOpen: boolean}>;

export const Sidebar: FC<SidebarProps> = ({isOpen, children}) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className='flex flex-col border-l'
        initial={{width: '0'}}
        animate={{width: '480px'}}
        exit={{width: '0'}}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
