import {type ReactNode} from 'react';
import {Navigate, type RouteObject} from 'react-router-dom';
import {LoggedInLayout} from '../../components/layouts/logged-in-layout';
import {useAuth} from '../../lib/context/auth/auth.tsx';
import {antraegeRoute} from '../antraege';
import {nutzendenverwaltungRoute} from '../nutzendenverwaltung';
import {prozesseRoute} from '../prozesse';
import {prozesseVerwaltenRoute} from '../prozessverwaltung';

const RedirectIfNotLoggedIn = ({children}: {children?: ReactNode}) => {
  const {isLoggedIn} = useAuth();
  if (!isLoggedIn) {
    return <Navigate to='/auth/login' />;
  }
  return children;
};

export const loggedInRoute: RouteObject = {
  element: (
    <RedirectIfNotLoggedIn>
      <LoggedInLayout />
    </RedirectIfNotLoggedIn>
  ),
  children: [
    {index: true, element: <Navigate to='/antraege/neu' />},
    antraegeRoute,
    prozesseRoute,
    nutzendenverwaltungRoute,
    prozesseVerwaltenRoute,
  ],
};
