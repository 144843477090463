import {Navigate, type RouteObject} from 'react-router-dom';
import {apiDocsRoute} from './api-docs';
import {authRoute} from './auth';
import {deeplinksRoute} from './deeplinks/index.tsx';
import {ErrorPage} from './error-page.tsx';
import {loggedInRoute} from './logged-in';

const catchAllRoute: RouteObject = {
  path: '*',
  element: <Navigate to='/' />,
};

export const rootRoute: RouteObject = {
  path: '/',
  errorElement: <ErrorPage />,
  children: [
    loggedInRoute,
    authRoute,
    apiDocsRoute,
    catchAllRoute,
    deeplinksRoute,
  ],
};
