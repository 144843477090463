import {useFormBuilder} from '@atmina/formbuilder';
import SVG from 'react-inlinesvg';
import Search from '../../../assets/search.svg';
import {InputFormField} from '../../../components/form/fields/input-field';
import {SelectFormField} from '../../../components/form/fields/select-field';
import {Button} from '../../../components/ui/button';
import {NutzendeVerwalten} from './nutzende-verwalten.tsx';
import {useNutzendeQuery} from './nutzende.generated.ts';
import {GruppenVerwalten} from './nutzer-gruppen-verwalten.tsx';

type NutzendeSearch = {
  search: string;
  gruppe: string;
};

type MitarbeiterGruppe = {
  id: string;
  name: string;
};

export const Nutzende = () => {
  const formBuilder = useFormBuilder<NutzendeSearch>();

  const search = formBuilder.fields.search.$useWatch();
  const gruppe = formBuilder.fields.gruppe.$useWatch();

  const [{data}, refetch] = useNutzendeQuery({
    variables: {
      search: search,
      groupId: gruppe,
    },
  });

  const groups =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.mitarbeiterGruppen
      : null;

  const nutzende =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.mitarbeiter?.nodes
      : null;

  if (!nutzende || !groups) return null;

  const transformGroupsToDisplay = (groups: MitarbeiterGruppe[]) =>
    groups.map(({name}) => name).join(', ');

  return (
    <div className='container flex h-full flex-col gap-6 px-4 pt-20'>
      <div className='flex flex-wrap gap-4'>
        <div className='flex-1'>
          <h1 className='text-2xl font-bold'>Nutzendenverwaltung</h1>
          <p>
            In der Tabelle finden Sie eine Übersicht aller Nutzenden und können
            die jeweiligen Gruppen bearbeiten. Mit der Suche können Sie einzelne
            Nutzende suchen.
          </p>
        </div>
        <div className='flex flex-wrap content-center gap-4'>
          <Button variant='outlined'>+ Neu einladen</Button>
          <GruppenVerwalten
            gruppen={groups}
            onSaveDeleteOrUpdate={() => {
              refetch();
            }}
          />
        </div>
      </div>
      <div className='flex flex-wrap gap-4'>
        <InputFormField
          on={formBuilder.fields.search}
          label='Nach Namen oder E-Mail Adressen suchen...'
          endAdornment={
            <Button
              variant='solid'
              className='flex w-min items-center gap-2 rounded-l-none px-4'
            >
              <SVG src={Search} className='size-5 fill-white' />
              Suchen
            </Button>
          }
          className='w-[38rem]'
        />
        <SelectFormField
          on={formBuilder.fields.gruppe}
          label='Nach Gruppen filtern'
          items={[
            {key: 'alle', name: 'Alle Gruppen', value: undefined},
            ...groups.map((g) => ({
              key: g.id,
              value: g.id,
              name: g.name,
            })),
          ]}
          className='w-60'
        />
      </div>
      <table>
        <thead className='text-left'>
          <tr>
            <th className='pb-2 pl-5 pr-12'>Vorname</th>
            <th className='pb-2 pr-20'>Nachname</th>
            <th className='pb-2'>E-Mail Adresse</th>
            <th className='pb-2'>Gruppen</th>
            <th />
          </tr>
        </thead>
        <tbody className='border-t-2'>
          {nutzende.map((n) => (
            <tr key={n.id} className='m-4 even:bg-neutral-100'>
              <td className='py-4 pl-5'>{n.vorname}</td>
              <td>{n.nachname}</td>
              <td>{n.eMail}</td>
              <td>
                <div className='max-w-96 overflow-hidden text-ellipsis text-nowrap'>
                  {transformGroupsToDisplay(n.gruppen)}
                </div>
              </td>
              <td>
                <NutzendeVerwalten nutzender={n} gruppen={groups} />
              </td>
            </tr>
          ))}
        </tbody>
        {nutzende.length === 0 && (
          <tfoot>
            <tr>
              <td colSpan={3}>Keine Nutzenden gefunden.</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
