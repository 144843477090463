import * as Dialog from '@radix-ui/react-dialog';
import {type FC, useState} from 'react';
import {MdEdit, MdOutlineAccountTree} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {Button} from '../../../../components/ui/button';
import {ProzesseVerwalten} from '../prozesse-verwalten.tsx';
import {
  type ProzessGroup,
  type UserFacingProzess,
  type UserFacingProzess_ServicePortalLink,
} from '../prozesse.generated.ts';
import {ServicePortalLinkBearbeitenDialog} from './service-portal-link/service-portal-link-bearbeiten-dialog.tsx';

export const ProzessRow: FC<{
  prozess: UserFacingProzess;
  prozessGroups: ProzessGroup[];
}> = ({prozess, prozessGroups}) => (
  <tr key={prozess.id} className='m-4 even:bg-neutral-100'>
    <td className='py-4 pl-5'>{prozess.name}</td>
    <td className='g-2 flex items-center py-4'>
      <ProzesseVerwalten gruppen={prozessGroups} prozess={prozess} />
      <div className='pl-2'>{prozess.group?.name} </div>
    </td>

    <ProzessRowSpecialColumns prozess={prozess} />
  </tr>
);

const ProzessRowSpecialColumns: FC<{prozess: UserFacingProzess}> = ({
  prozess,
}) => {
  switch (prozess.__typename) {
    case 'Prozess':
      return (
        <>
          <td>{prozess.versionenCount}</td>
          <td>
            <Link
              to={`/prozesse/${prozess.id}`}
              type='button'
              className='flex items-center gap-2 font-bold text-primary'
            >
              <MdOutlineAccountTree />
              Ablauf bearbeiten
            </Link>
          </td>
        </>
      );
    case 'ServicePortalLink':
      return <ProzessRowServicePortalLinkSpecialColumns link={prozess} />;
    default:
      return (
        <>
          <td />
          <td />
        </>
      );
  }
};

const ProzessRowServicePortalLinkSpecialColumns: FC<{
  link: UserFacingProzess_ServicePortalLink;
}> = ({link}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <td>
        <a
          href={link.url}
          target='_blank'
          className='text-primary hover:underline'
          rel='noreferrer'
        >
          Link
        </a>
      </td>
      <td>
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Trigger asChild>
            <Button variant='text' className='flex items-center gap-2 p-0'>
              <MdEdit />
              Link bearbeiten
            </Button>
          </Dialog.Trigger>
          <ServicePortalLinkBearbeitenDialog
            id={link.id}
            onAfterSave={() => setOpen(false)}
          />
        </Dialog.Root>
      </td>
    </>
  );
};
