import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback} from 'react';
import {InputFormField} from '../../../../../components/form/fields/input-field';
import {Form} from '../../../../../components/form/form.tsx';
import {Modal} from '../../../../../components/modal/modal.tsx';
import {Button} from '../../../../../components/ui/button';
import {useKommune} from '../../../../../lib/context/kommune/kommune.tsx';
import {
  type ProzessUpsertInput,
  useProzessQuery,
  useUpsertProzessMutation,
} from './prozess.generated.ts';

export type ProzessBearbeitenDialogProps = {
  id: string | null;
  onAfterSave: () => void;
};

type ProzessBearbeitenFormData = ProzessUpsertInput;

export const ProzessBearbeitenDialog: FC<ProzessBearbeitenDialogProps> = ({
  id,
  onAfterSave,
}) => {
  const {id: kommuneId} = useKommune();

  const [{data}] = useProzessQuery({
    variables: {kommuneId, id: id ?? ''},
    pause: !id,
  });
  const prozess = data?.kommune.prozessById;
  const builder = useFormBuilder<ProzessBearbeitenFormData>({
    values: {
      id: prozess?.id,
      name: prozess?.name ?? '',
    },
  });

  const [, upsertProzess] = useUpsertProzessMutation();

  const onSubmit = useCallback(
    async (form: ProzessBearbeitenFormData) => {
      await upsertProzess({input: form});
      onAfterSave();
    },
    [upsertProzess, onAfterSave],
  );

  return (
    <Modal title='Prozess bearbeiten' className='max-w-2xl' global>
      <Form builder={builder} onSubmit={onSubmit}>
        <InputFormField
          on={builder.fields.name}
          label='Name'
          rules={{required: true}}
        />
        <div className='mt-4 flex justify-end'>
          <Button>Speichern</Button>
        </div>
      </Form>
    </Modal>
  );
};
