import {useEffect, useRef, useState} from 'react';
import SVG from 'react-inlinesvg';
import {IconWithCallbackButton} from 'src/components/ui/button/icon-with-callback-button';
import Delete from '../../../assets/delete.svg';
import EditSquare from '../../../assets/edit-square.svg';
import Save from '../../../assets/save.svg';

export type EditableTextInputProps = {
  onUpdate: (name: string) => Promise<void>;
  onDelete: () => void;
  item: {name: string; id: string};
  error?: string;
  editable?: boolean;
};

export const EditableTextInput = ({
  error,
  item,
  onUpdate,
  onDelete,
  editable = true,
}: EditableTextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    if (saveEnabled) {
      inputRef.current?.focus();
    }
  }, [saveEnabled]);

  return (
    <>
      <div className='mt-2 flex items-center justify-between rounded-md border'>
        <input
          ref={inputRef}
          disabled={!saveEnabled}
          className='mr-3 w-full rounded-md p-2 focus:bg-slate-200 focus:outline-none disabled:bg-transparent '
          type='text'
          id={item.id}
          defaultValue={item.name}
        />
        {editable && (
          <>
            <IconWithCallbackButton
              onClick={
                saveEnabled
                  ? () => {
                      onUpdate(inputRef.current?.value ?? '');
                      setSaveEnabled(!saveEnabled);
                    }
                  : () => setSaveEnabled(!saveEnabled)
              }
              iconSrc={saveEnabled ? Save : EditSquare}
            />
            <button
              type='button'
              className='material-symbols-outlined mx-2 flex gap-2 text-red-500'
              onClick={onDelete}
            >
              <SVG src={Delete} className='fill-red-500' />
            </button>
          </>
        )}
      </div>
      {error && <div className='text-sm text-red-600'>{error}</div>}
    </>
  );
};
