import {type FormBuilder} from '@atmina/formbuilder';
import {
  CheckboxField,
  CheckboxFormField,
} from 'src/components/form/fields/checkbox-field';
import {
  type UntereinheitFragment,
  type PermissionInput,
} from '../nutzende/nutzende.generated';

export type UntereinheitPermissionFormFieldProps = {
  onChange: () => void;
  on: FormBuilder<PermissionInput>;
  untereinheiten: UntereinheitFragment[];
};
export const UntereinheitPermissionFormField = ({
  onChange,
  on,
  untereinheiten,
}: UntereinheitPermissionFormFieldProps) => {
  const untereinheitId = on.untereinheitId.$useWatch();
  const title = untereinheiten.find((u) => u.id === untereinheitId)?.name ?? '';
  return (
    <>
      <div className='flex'>
        <h2 className='text-sm text-primary'>{title}</h2>
        <CheckboxField
          className='ml-auto'
          onChange={onChange}
          orientation='checkbox-right'
          value
        />
      </div>
      <div className='flex gap-6'>
        <CheckboxFormField
          label='Kann News bearbeiten'
          orientation='checkbox-left'
          on={on.canEditNews}
        />
        <CheckboxFormField
          label='Kann Veranstaltungen bearbeiten'
          orientation='checkbox-left'
          on={on.canEditVeranstaltungen}
        />
      </div>
    </>
  );
};
