import {type PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';

export type FormCardProps = PropsWithChildren<{
  heading?: string | null;
  className?: string;
}>;
export const FormCard = ({heading, children, className}: FormCardProps) => (
  <div className={twMerge('space-y-4 rounded-md bg-gray-100 p-4', className)}>
    {heading && (
      <div className='flex items-center gap-4 font-bold'>
        <h2>{heading}</h2>
        <hr className='ml-auto h-px grow' />
      </div>
    )}
    {children}
  </div>
);
