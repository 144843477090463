import ELK, {type ElkExtendedEdge, type ElkNode} from 'elkjs';
import {type ReactElement} from 'react';
import {flushSync} from 'react-dom';
import {createRoot} from 'react-dom/client';
import {
  type Edge,
  type Edge as ReactFlowEdge,
  type Node as ReactFlowNode,
  Position,
} from 'reactflow';
import {KommunalappSchrittNodeRender} from '../components/kommunalapp-schritt-node.tsx';
import {
  type SchrittDaten,
  type VerbindungDaten,
} from './store/prozess-aenderungen.ts';

type LayouterGraph = {
  nodes: ElkNode[];
  edges: ElkExtendedEdge[];
};

export type NodeData = unknown;
export type EdgeData = unknown;
export type LayoutedGraph = {
  nodes: ReactFlowNode<NodeData>[];
  edges: ReactFlowEdge<EdgeData>[];
};

export class Layouter {
  private readonly elk = new ELK({
    workerFactory: () =>
      new Worker(new URL('elkjs/lib/elk-worker.min.js', import.meta.url)),
  });

  private async determineElementSize(node: ReactElement) {
    const measurementContainer = document.getElementById(
      'kommunalapp-grid-measurement-container',
    )!;

    const root = createRoot(measurementContainer);

    await new Promise<void>((res) => {
      queueMicrotask(() => {
        flushSync(() => {
          root.render(node);
        });
        res();
      });
    });

    const height = measurementContainer.clientHeight;
    const width = measurementContainer.clientWidth;

    root.unmount();

    return {height, width};
  }

  private getSchritteWithLayers(
    schritte: SchrittDaten[],
    startId: string,
    verbindungen: VerbindungDaten[],
  ) {
    const startSchritt = schritte.find((s) => s.id === startId)!;

    let currentLayer = 0;
    let layerSchritte: SchrittDaten[] = [startSchritt];
    const knownIds = new Set<string>();

    const result = [{layer: 0, schritt: startSchritt}];

    while (result.length !== schritte.length) {
      for (const {id} of layerSchritte) {
        knownIds.add(id);
      }

      currentLayer += 1;
      const connectedSchritte = layerSchritte.flatMap((s) => {
        const vonVerbindungen = verbindungen.filter((v) => v.vonId === s.id);
        const nextSchritte = vonVerbindungen.map(
          (v) => schritte.find((s) => s.id === v.nachId)!,
        );

        return nextSchritte.filter((s) => !knownIds.has(s.id));
      });

      result.push(
        ...connectedSchritte.map((s) => ({layer: currentLayer, schritt: s})),
      );

      layerSchritte = connectedSchritte;

      if (layerSchritte.length === 0) break;
    }

    return result;
  }

  async layout(
    schritte: SchrittDaten[],
    startId: string,
    verbindungen: VerbindungDaten[],
  ): Promise<LayoutedGraph> {
    const graph: LayouterGraph = {nodes: [], edges: []};
    const reactflowEdges: Edge[] = [];
    const nodeSizes: Record<string, {height: number; width: number}> = {};
    const nodeMap: Record<string, SchrittDaten> = Object.fromEntries(
      schritte.map((s) => [s.id, s]),
    );

    const schritteWithLayers = this.getSchritteWithLayers(
      schritte,
      startId,
      verbindungen,
    );

    for (const verbindung of verbindungen) {
      graph.edges.push({
        id: verbindung.id,
        sources: [verbindung.vonId],
        targets: [verbindung.nachId],
        labels: [{text: verbindung.name ?? '', width: 200, height: 40}],
      });

      reactflowEdges.push({
        id: verbindung.id,
        source: verbindung.vonId,
        target: verbindung.nachId,
        label: verbindung.name,
        animated: true,
        type: 'kommunalEdge',
        labelShowBg: true,
        labelBgBorderRadius: 6,
        labelBgPadding: [8, 4],
        labelBgStyle: {fill: '#FEE2AA'},
        labelStyle: {fontSize: 12},
        updatable: false,
      });
    }

    for (const {schritt, layer} of schritteWithLayers) {
      const {height, width} = await this.determineElementSize(
        <KommunalappSchrittNodeRender schritt={schritt} />,
      );

      graph.nodes.push({
        id: schritt.id,
        width,
        height,
        layoutOptions: {
          'org.eclipse.elk.partitioning.partition': layer.toString(),
        },
      });

      nodeSizes[schritt.id] = {width, height};
    }

    const elkGraph: ElkNode = {
      id: 'root',
      children: graph.nodes,
      edges: graph.edges,
    };

    let result: ElkNode;

    try {
      result = await this.elk.layout(elkGraph, {
        layoutOptions: {
          direction: 'RIGHT',

          'elk.algorithm': 'layered',
          'elk.alignment': 'RIGHT',
          'elk.partitioning.activate': 'true',

          'spacing.nodeNode': '60',
          'spacing.edgeNode': '30',
          'spacing.labelNode': '30',
          'spacing.labelLabel': '30',
          'layered.spacing.nodeNodeBetweenLayers': '60',

          'elk.layered.mergeEdges': 'true',
          'elk.layered.crossingMinimization.forceNodeModelOrder': 'true',

          'nodePlacement.strategy': 'BRANDES_KOEPF',

          'elk.crossingMinimization.forceNodeModelOrder': 'true',
          'elk.layered.layering.strategy': 'LONGEST_PATH_SOURCE',

          'elk.layered.nodePlacement.favorStraightEdges': 'false',

          'elk.edgeRouting': 'ORTHOGONAL',
        },
      });
    } catch (e) {
      console.error(e);

      return {nodes: [], edges: []};
    }

    const nodes =
      result.children?.map<ReactFlowNode>((n) => {
        const node = nodeMap[n.id];
        const {width, height} = nodeSizes[n.id];

        return {
          id: n.id,
          type: 'schritt',
          width,
          height,
          position: {x: n.x ?? 0, y: n.y ?? 0},
          data: {
            schritt: node,
            isStart: node.id === startId,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        };
      }) ?? [];

    return {nodes, edges: reactflowEdges};
  }
}
