import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import {type ChangeEventHandler, type FC, forwardRef} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';
import {type FormularSchrittFeld_CheckboxListFormularFeld_Fragment} from '../../../../routes/antraege/detail/detail.generated.ts';

export type CheckboxFieldProps = {
  orientation?: 'checkbox-left' | 'checkbox-right';
  value?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  disabled?: boolean;
  className?: string;
  name?: string;
};

export type CheckboxFormFieldProps<T> = {
  on: FormBuilder<T>;
  rules?: RegisterOptions<T>;
  checked?: boolean;
} & Omit<CheckboxFieldProps, keyof UseFormRegisterReturn>;

export const CheckboxField: FC<CheckboxFieldProps> = forwardRef<
  HTMLInputElement,
  CheckboxFieldProps
>(
  (
    {
      orientation = 'checkbox-right',
      value,
      onChange,
      label,
      disabled,
      className,
      name,
    },
    ref,
  ) => (
    <label
      className={twMerge(
        'flex gap-2',
        orientation === 'checkbox-left'
          ? 'flex-row'
          : 'flex-row-reverse justify-between',
        className,
      )}
    >
      <div className='relative mt-1'>
        <input
          ref={ref}
          name={name}
          type='checkbox'
          checked={value}
          disabled={disabled}
          className='peer/input hidden'
          onChange={onChange}
        />
        <div className='flex size-4 items-center justify-center rounded border border-gray-500 bg-white' />
        <svg
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 24 24'
          className='absolute inset-0.5 hidden size-3 stroke-2 text-primary peer-checked/input:block'
          height='1em'
          width='1em'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' />
        </svg>
      </div>

      {label && <span>{label}</span>}
    </label>
  ),
);
CheckboxField.displayName = 'CheckboxField';

export const CheckboxFormField: FC<CheckboxFormFieldProps<boolean>> = ({
  on,
  rules,
  ...props
}) => <CheckboxField {...on(rules)} {...props} />;

export const SimpleCheckBoxFormField: FC<{
  on: FormBuilder<boolean>;
  label: string;
}> = ({on, label, ...props}) => (
  <CheckboxField
    label={label}
    orientation='checkbox-left'
    {...on()}
    {...props}
  />
);

export type CheckBoxListFormOptions =
  FormularSchrittFeld_CheckboxListFormularFeld_Fragment['options'];
export const CheckboxListFormField: FC<
  {options: CheckBoxListFormOptions; label: string} & Omit<
    CheckboxFormFieldProps<string | null>,
    keyof CheckboxFieldProps
  >
> = ({on, rules, options, label, ...props}) => {
  const formKeys = on.$useWatch();
  let checkedKeys = formKeys?.split(',') ?? [];

  return (
    <>
      <p>{label}</p>
      {options.map(({key, label}) => (
        <CheckboxField
          value={checkedKeys.includes(key)}
          key={key}
          label={label}
          orientation='checkbox-left'
          onChange={(event) => {
            if (event.target.checked) {
              checkedKeys.push(key);
              on.$setValue(checkedKeys.join(','));
            } else {
              checkedKeys = checkedKeys.filter(
                (currentKey) => currentKey !== key,
              );
              on.$setValue(checkedKeys.join(','));
            }
          }}
          {...props}
        />
      ))}
    </>
  );
};
