import {type FC} from 'react';
import {MdAccountCircle} from 'react-icons/md';
import {InputField} from '../../../../../components/form/fields/input-field';
import {useCrypto} from '../../../../../lib/context/crypto/crypto.tsx';
import {useAsyncMemo} from '../../../../../lib/hooks/use-async-memo.ts';
import {
  type AntragDetailAusfuehrung,
  type AntragDetailSchritt_EmailForwarderExportSchritt,
} from '../../detail.generated.ts';

export type EmailForwarderExportSchrittProps = {
  bearbeitungsDatum?: Date;
  schritt: AntragDetailSchritt_EmailForwarderExportSchritt;
  ausfuehrung: AntragDetailAusfuehrung;
};

export const EmailForwarderExportSchritt: FC<
  EmailForwarderExportSchrittProps
> = ({bearbeitungsDatum, schritt, ausfuehrung}) => {
  const {decryptionKey, cryptoCache} = useCrypto();

  const slots = useAsyncMemo(async () => {
    const map: Record<string, unknown> = {};
    if (!cryptoCache || !decryptionKey) {
      return map;
    }

    for (const slotValue of ausfuehrung.slotValues) {
      if (slotValue.__typename === 'PlainSlotValue') {
        map[slotValue.slotName] = slotValue.plainValue;
      } else if (slotValue.__typename === 'EncryptedSlotValue') {
        map[slotValue.slotName] = await cryptoCache.getDecryptedValueCached(
          decryptionKey,
          slotValue.encryptedValue,
        );
      }
    }

    return map;
  }, []);

  if (!slots) {
    return null;
  }

  return (
    <div className='flex gap-4 p-2'>
      <div className='mt-2 flex flex-col gap-4'>
        <p className='font-bold leading-none'>
          {schritt.subject.evaluate(slots)}
        </p>
        <div className='flex items-center gap-2'>
          <MdAccountCircle className='size-10 text-primary' />
          <div className='flex flex-col'>
            {bearbeitungsDatum && (
              <p className='text-sm font-light'>
                {bearbeitungsDatum.toLocaleDateString('de-DE', {
                  dateStyle: 'medium',
                })}
              </p>
            )}
            <p className='font-bold leading-none'>
              {schritt.emailAddress.evaluate(slots)}
            </p>
          </div>
        </div>
      </div>
      <InputField
        multiLine
        value={schritt.body.evaluate(slots)}
        label='Bemerkung'
        className='w-full bg-gray-50'
        inputClass='resize-none'
      />
    </div>
  );
};
