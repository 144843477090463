import * as Dialog from '@radix-ui/react-dialog';
import {type FC} from 'react';
import SVG from 'react-inlinesvg';
import {GroupCheckboxField} from 'src/components/ui/checkbox/group-checkbox-field.tsx';
import EditSquare from '../../../assets/edit-square.svg';
import {Modal} from '../../../components/modal/modal.tsx';
import {Button} from '../../../components/ui/button';
import {
  type UserFacingProzess,
  type ProzessGroup,
  useSetGroupToProzessMutation,
} from './prozesse.generated.ts';

export type ProzesseVerwaltenProps = {
  prozess: UserFacingProzess;
  gruppen: ProzessGroup[];
};

export const ProzesseVerwalten: FC<ProzesseVerwaltenProps> = ({
  prozess,
  gruppen,
}) => {
  const [, setGroupToProzessMutation] = useSetGroupToProzessMutation();

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button type='button' className='flex gap-2 font-bold text-primary'>
          <SVG src={EditSquare} className='fill-primary' />
        </button>
      </Dialog.Trigger>
      <Modal title='Prozesse verwalten'>
        <div className='space-y-6'>
          <div className='my-3 flex flex-col rounded-lg border-[1px] border-neutral-300 bg-neutral-100 p-4 sm:flex-row'>
            <div className='flex-col pr-7'>
              <div className='text-sm'>Name</div>
              <div className='font-bold'>{prozess.name}</div>
            </div>
            {prozess.__typename == 'Prozess' && (
              <div className='flex-col pr-7'>
                <div className='text-sm'>Version</div>
                <div className='font-bold'>{prozess.versionenCount}</div>
              </div>
            )}
          </div>

          <div>
            <div className='flex items-center'>
              <div className='mr-4 whitespace-nowrap font-bold'>
                folgender Gruppe zugehörig:
              </div>
              <div className='h-px w-full border-0 bg-neutral-300' />
            </div>
            {prozess.group && (
              <GroupCheckboxField
                key={prozess.group.id}
                checkedChange={() =>
                  setGroupToProzessMutation({
                    prozessId: prozess.id,
                    groupId: undefined,
                  })
                }
                gruppe={prozess.group}
                defaultChecked
              />
            )}
          </div>

          <div>
            <div className='flex items-center'>
              <div className='mr-4 whitespace-nowrap font-bold'>
                Weitere Gruppen:
              </div>
              <div className='h-px w-full border-0 bg-neutral-300' />
            </div>
            {gruppen
              .filter((gruppe) => prozess.group?.id != gruppe.id)
              .map((g) => (
                <GroupCheckboxField
                  key={g.id}
                  checkedChange={() =>
                    setGroupToProzessMutation({
                      prozessId: prozess.id,
                      groupId: g.id,
                    })
                  }
                  defaultChecked={false}
                  gruppe={g}
                />
              ))}
          </div>

          <div className='flex justify-end'>
            <Dialog.Close asChild>
              <Button variant='outlined'>Übernehmen</Button>
            </Dialog.Close>
          </div>
        </div>
      </Modal>
    </Dialog.Root>
  );
};
