// Generated from Expressions.g4 by ANTLR 4.13.1

import * as antlr from 'antlr4ng';
import {Token} from 'antlr4ng';

export class ExpressionsLexer extends antlr.Lexer {
  public static readonly Location = 1;
  public static readonly Number = 2;
  public static readonly Bool = 3;
  public static readonly LeftParen = 4;
  public static readonly RightParen = 5;
  public static readonly LeftBracket = 6;
  public static readonly RightBracket = 7;
  public static readonly PLUS = 8;
  public static readonly MINUS = 9;
  public static readonly TIMES = 10;
  public static readonly DIV = 11;
  public static readonly GT = 12;
  public static readonly GTEQ = 13;
  public static readonly LT = 14;
  public static readonly LTEQ = 15;
  public static readonly EQ = 16;
  public static readonly NEQ = 17;
  public static readonly POINT = 18;
  public static readonly AND = 19;
  public static readonly OR = 20;
  public static readonly COMMA = 21;
  public static readonly Identifier = 22;
  public static readonly String = 23;
  public static readonly WS = 24;

  public static readonly channelNames = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN'];

  public static readonly literalNames = [
    null,
    null,
    null,
    null,
    "'('",
    "')'",
    "'['",
    "']'",
    "'+'",
    "'-'",
    "'*'",
    "'/'",
    "'>'",
    "'>='",
    "'<'",
    "'<='",
    "'=='",
    "'!='",
    "'.'",
    "'&&'",
    "'||'",
    "','",
  ];

  public static readonly symbolicNames = [
    null,
    'Location',
    'Number',
    'Bool',
    'LeftParen',
    'RightParen',
    'LeftBracket',
    'RightBracket',
    'PLUS',
    'MINUS',
    'TIMES',
    'DIV',
    'GT',
    'GTEQ',
    'LT',
    'LTEQ',
    'EQ',
    'NEQ',
    'POINT',
    'AND',
    'OR',
    'COMMA',
    'Identifier',
    'String',
    'WS',
  ];

  public static readonly modeNames = ['DEFAULT_MODE'];

  public static readonly ruleNames = [
    'Location',
    'Number',
    'Digit',
    'NonzeroDigit',
    'Bool',
    'LeftParen',
    'RightParen',
    'LeftBracket',
    'RightBracket',
    'PLUS',
    'MINUS',
    'TIMES',
    'DIV',
    'GT',
    'GTEQ',
    'LT',
    'LTEQ',
    'EQ',
    'NEQ',
    'POINT',
    'AND',
    'OR',
    'COMMA',
    'Identifier',
    'IdentifierPart',
    'IdentifierStart',
    'String',
    'SingleStringCharacter',
    'DoubleStringCharacter',
    'EscapeSequence',
    'CharacterEscapeSequence',
    'SingleEscapeCharacter',
    'NonEscapeCharacter',
    'EscapeCharacter',
    'WS',
  ];

  public constructor(input: antlr.CharStream) {
    super(input);
    this.interpreter = new antlr.LexerATNSimulator(
      this,
      ExpressionsLexer._ATN,
      ExpressionsLexer.decisionsToDFA,
      new antlr.PredictionContextCache(),
    );
  }

  public get grammarFileName(): string {
    return 'Expressions.g4';
  }

  public get literalNames(): (string | null)[] {
    return ExpressionsLexer.literalNames;
  }
  public get symbolicNames(): (string | null)[] {
    return ExpressionsLexer.symbolicNames;
  }
  public get ruleNames(): string[] {
    return ExpressionsLexer.ruleNames;
  }

  public get serializedATN(): number[] {
    return ExpressionsLexer._serializedATN;
  }

  public get channelNames(): string[] {
    return ExpressionsLexer.channelNames;
  }

  public get modeNames(): string[] {
    return ExpressionsLexer.modeNames;
  }

  public static readonly _serializedATN: number[] = [
    4, 0, 24, 219, 6, -1, 2, 0, 7, 0, 2, 1, 7, 1, 2, 2, 7, 2, 2, 3, 7, 3, 2, 4,
    7, 4, 2, 5, 7, 5, 2, 6, 7, 6, 2, 7, 7, 7, 2, 8, 7, 8, 2, 9, 7, 9, 2, 10, 7,
    10, 2, 11, 7, 11, 2, 12, 7, 12, 2, 13, 7, 13, 2, 14, 7, 14, 2, 15, 7, 15, 2,
    16, 7, 16, 2, 17, 7, 17, 2, 18, 7, 18, 2, 19, 7, 19, 2, 20, 7, 20, 2, 21, 7,
    21, 2, 22, 7, 22, 2, 23, 7, 23, 2, 24, 7, 24, 2, 25, 7, 25, 2, 26, 7, 26, 2,
    27, 7, 27, 2, 28, 7, 28, 2, 29, 7, 29, 2, 30, 7, 30, 2, 31, 7, 31, 2, 32, 7,
    32, 2, 33, 7, 33, 2, 34, 7, 34, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 1,
    1, 5, 1, 80, 8, 1, 10, 1, 12, 1, 83, 9, 1, 1, 1, 1, 1, 5, 1, 87, 8, 1, 10,
    1, 12, 1, 90, 9, 1, 1, 1, 1, 1, 4, 1, 94, 8, 1, 11, 1, 12, 1, 95, 3, 1, 98,
    8, 1, 1, 2, 1, 2, 1, 3, 1, 3, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1,
    4, 1, 4, 3, 4, 113, 8, 4, 1, 5, 1, 5, 1, 6, 1, 6, 1, 7, 1, 7, 1, 8, 1, 8, 1,
    9, 1, 9, 1, 10, 1, 10, 1, 11, 1, 11, 1, 12, 1, 12, 1, 13, 1, 13, 1, 14, 1,
    14, 1, 14, 1, 15, 1, 15, 1, 16, 1, 16, 1, 16, 1, 17, 1, 17, 1, 17, 1, 18, 1,
    18, 1, 18, 1, 19, 1, 19, 1, 20, 1, 20, 1, 20, 1, 21, 1, 21, 1, 21, 1, 22, 1,
    22, 1, 23, 1, 23, 5, 23, 159, 8, 23, 10, 23, 12, 23, 162, 9, 23, 1, 24, 1,
    24, 3, 24, 166, 8, 24, 1, 25, 3, 25, 169, 8, 25, 1, 26, 1, 26, 5, 26, 173,
    8, 26, 10, 26, 12, 26, 176, 9, 26, 1, 26, 1, 26, 1, 26, 5, 26, 181, 8, 26,
    10, 26, 12, 26, 184, 9, 26, 1, 26, 3, 26, 187, 8, 26, 1, 27, 1, 27, 1, 27,
    3, 27, 192, 8, 27, 1, 28, 1, 28, 1, 28, 3, 28, 197, 8, 28, 1, 29, 1, 29, 1,
    30, 1, 30, 3, 30, 203, 8, 30, 1, 31, 1, 31, 1, 32, 1, 32, 1, 33, 1, 33, 3,
    33, 211, 8, 33, 1, 34, 4, 34, 214, 8, 34, 11, 34, 12, 34, 215, 1, 34, 1, 34,
    0, 0, 35, 1, 1, 3, 2, 5, 0, 7, 0, 9, 3, 11, 4, 13, 5, 15, 6, 17, 7, 19, 8,
    21, 9, 23, 10, 25, 11, 27, 12, 29, 13, 31, 14, 33, 15, 35, 16, 37, 17, 39,
    18, 41, 19, 43, 20, 45, 21, 47, 22, 49, 0, 51, 0, 53, 23, 55, 0, 57, 0, 59,
    0, 61, 0, 63, 0, 65, 0, 67, 0, 69, 24, 1, 0, 10, 1, 0, 48, 57, 1, 0, 49, 57,
    408, 0, 48, 57, 95, 95, 768, 879, 1155, 1159, 1425, 1469, 1471, 1471, 1473,
    1474, 1476, 1477, 1479, 1479, 1552, 1562, 1611, 1641, 1648, 1648, 1750,
    1756, 1759, 1764, 1767, 1768, 1770, 1773, 1776, 1785, 1809, 1809, 1840,
    1866, 1958, 1968, 1984, 1993, 2027, 2035, 2045, 2045, 2070, 2073, 2075,
    2083, 2085, 2087, 2089, 2093, 2137, 2139, 2200, 2207, 2250, 2273, 2275,
    2306, 2362, 2362, 2364, 2364, 2369, 2376, 2381, 2381, 2385, 2391, 2402,
    2403, 2406, 2415, 2433, 2433, 2492, 2492, 2497, 2500, 2509, 2509, 2530,
    2531, 2534, 2543, 2558, 2558, 2561, 2562, 2620, 2620, 2625, 2626, 2631,
    2632, 2635, 2637, 2641, 2641, 2662, 2673, 2677, 2677, 2689, 2690, 2748,
    2748, 2753, 2757, 2759, 2760, 2765, 2765, 2786, 2787, 2790, 2799, 2810,
    2815, 2817, 2817, 2876, 2876, 2879, 2879, 2881, 2884, 2893, 2893, 2901,
    2902, 2914, 2915, 2918, 2927, 2946, 2946, 3008, 3008, 3021, 3021, 3046,
    3055, 3072, 3072, 3076, 3076, 3132, 3132, 3134, 3136, 3142, 3144, 3146,
    3149, 3157, 3158, 3170, 3171, 3174, 3183, 3201, 3201, 3260, 3260, 3263,
    3263, 3270, 3270, 3276, 3277, 3298, 3299, 3302, 3311, 3328, 3329, 3387,
    3388, 3393, 3396, 3405, 3405, 3426, 3427, 3430, 3439, 3457, 3457, 3530,
    3530, 3538, 3540, 3542, 3542, 3558, 3567, 3633, 3633, 3636, 3642, 3655,
    3662, 3664, 3673, 3761, 3761, 3764, 3772, 3784, 3790, 3792, 3801, 3864,
    3865, 3872, 3881, 3893, 3893, 3895, 3895, 3897, 3897, 3953, 3966, 3968,
    3972, 3974, 3975, 3981, 3991, 3993, 4028, 4038, 4038, 4141, 4144, 4146,
    4151, 4153, 4154, 4157, 4158, 4160, 4169, 4184, 4185, 4190, 4192, 4209,
    4212, 4226, 4226, 4229, 4230, 4237, 4237, 4240, 4249, 4253, 4253, 4957,
    4959, 5906, 5908, 5938, 5939, 5970, 5971, 6002, 6003, 6068, 6069, 6071,
    6077, 6086, 6086, 6089, 6099, 6109, 6109, 6112, 6121, 6155, 6157, 6159,
    6169, 6277, 6278, 6313, 6313, 6432, 6434, 6439, 6440, 6450, 6450, 6457,
    6459, 6470, 6479, 6608, 6617, 6679, 6680, 6683, 6683, 6742, 6742, 6744,
    6750, 6752, 6752, 6754, 6754, 6757, 6764, 6771, 6780, 6783, 6793, 6800,
    6809, 6832, 6845, 6847, 6862, 6912, 6915, 6964, 6964, 6966, 6970, 6972,
    6972, 6978, 6978, 6992, 7001, 7019, 7027, 7040, 7041, 7074, 7077, 7080,
    7081, 7083, 7085, 7088, 7097, 7142, 7142, 7144, 7145, 7149, 7149, 7151,
    7153, 7212, 7219, 7222, 7223, 7232, 7241, 7248, 7257, 7376, 7378, 7380,
    7392, 7394, 7400, 7405, 7405, 7412, 7412, 7416, 7417, 7616, 7679, 8204,
    8205, 8255, 8256, 8276, 8276, 8400, 8412, 8417, 8417, 8421, 8432, 11503,
    11505, 11647, 11647, 11744, 11775, 12330, 12333, 12441, 12442, 42528, 42537,
    42607, 42607, 42612, 42621, 42654, 42655, 42736, 42737, 43010, 43010, 43014,
    43014, 43019, 43019, 43045, 43046, 43052, 43052, 43204, 43205, 43216, 43225,
    43232, 43249, 43263, 43273, 43302, 43309, 43335, 43345, 43392, 43394, 43443,
    43443, 43446, 43449, 43452, 43453, 43472, 43481, 43493, 43493, 43504, 43513,
    43561, 43566, 43569, 43570, 43573, 43574, 43587, 43587, 43596, 43596, 43600,
    43609, 43644, 43644, 43696, 43696, 43698, 43700, 43703, 43704, 43710, 43711,
    43713, 43713, 43756, 43757, 43766, 43766, 44005, 44005, 44008, 44008, 44013,
    44013, 44016, 44025, 64286, 64286, 65024, 65039, 65056, 65071, 65075, 65076,
    65101, 65103, 65296, 65305, 65343, 65343, 66045, 66045, 66272, 66272, 66422,
    66426, 66720, 66729, 68097, 68099, 68101, 68102, 68108, 68111, 68152, 68154,
    68159, 68159, 68325, 68326, 68900, 68903, 68912, 68921, 69291, 69292, 69373,
    69375, 69446, 69456, 69506, 69509, 69633, 69633, 69688, 69702, 69734, 69744,
    69747, 69748, 69759, 69761, 69811, 69814, 69817, 69818, 69826, 69826, 69872,
    69881, 69888, 69890, 69927, 69931, 69933, 69940, 69942, 69951, 70003, 70003,
    70016, 70017, 70070, 70078, 70089, 70092, 70095, 70105, 70191, 70193, 70196,
    70196, 70198, 70199, 70206, 70206, 70209, 70209, 70367, 70367, 70371, 70378,
    70384, 70393, 70400, 70401, 70459, 70460, 70464, 70464, 70502, 70508, 70512,
    70516, 70712, 70719, 70722, 70724, 70726, 70726, 70736, 70745, 70750, 70750,
    70835, 70840, 70842, 70842, 70847, 70848, 70850, 70851, 70864, 70873, 71090,
    71093, 71100, 71101, 71103, 71104, 71132, 71133, 71219, 71226, 71229, 71229,
    71231, 71232, 71248, 71257, 71339, 71339, 71341, 71341, 71344, 71349, 71351,
    71351, 71360, 71369, 71453, 71455, 71458, 71461, 71463, 71467, 71472, 71481,
    71727, 71735, 71737, 71738, 71904, 71913, 71995, 71996, 71998, 71998, 72003,
    72003, 72016, 72025, 72148, 72151, 72154, 72155, 72160, 72160, 72193, 72202,
    72243, 72248, 72251, 72254, 72263, 72263, 72273, 72278, 72281, 72283, 72330,
    72342, 72344, 72345, 72752, 72758, 72760, 72765, 72767, 72767, 72784, 72793,
    72850, 72871, 72874, 72880, 72882, 72883, 72885, 72886, 73009, 73014, 73018,
    73018, 73020, 73021, 73023, 73029, 73031, 73031, 73040, 73049, 73104, 73105,
    73109, 73109, 73111, 73111, 73120, 73129, 73459, 73460, 73472, 73473, 73526,
    73530, 73536, 73536, 73538, 73538, 73552, 73561, 78912, 78912, 78919, 78933,
    92768, 92777, 92864, 92873, 92912, 92916, 92976, 92982, 93008, 93017, 94031,
    94031, 94095, 94098, 94180, 94180, 113821, 113822, 118528, 118573, 118576,
    118598, 119143, 119145, 119163, 119170, 119173, 119179, 119210, 119213,
    119362, 119364, 120782, 120831, 121344, 121398, 121403, 121452, 121461,
    121461, 121476, 121476, 121499, 121503, 121505, 121519, 122880, 122886,
    122888, 122904, 122907, 122913, 122915, 122916, 122918, 122922, 123023,
    123023, 123184, 123190, 123200, 123209, 123566, 123566, 123628, 123641,
    124140, 124153, 125136, 125142, 125252, 125258, 125264, 125273, 130032,
    130041, 917760, 917999, 661, 0, 36, 36, 65, 90, 95, 95, 97, 122, 170, 170,
    181, 181, 186, 186, 192, 214, 216, 246, 248, 705, 710, 721, 736, 740, 748,
    748, 750, 750, 880, 884, 886, 887, 890, 893, 895, 895, 902, 902, 904, 906,
    908, 908, 910, 929, 931, 1013, 1015, 1153, 1162, 1327, 1329, 1366, 1369,
    1369, 1376, 1416, 1488, 1514, 1519, 1522, 1568, 1610, 1646, 1647, 1649,
    1747, 1749, 1749, 1765, 1766, 1774, 1775, 1786, 1788, 1791, 1791, 1808,
    1808, 1810, 1839, 1869, 1957, 1969, 1969, 1994, 2026, 2036, 2037, 2042,
    2042, 2048, 2069, 2074, 2074, 2084, 2084, 2088, 2088, 2112, 2136, 2144,
    2154, 2160, 2183, 2185, 2190, 2208, 2249, 2308, 2361, 2365, 2365, 2384,
    2384, 2392, 2401, 2417, 2432, 2437, 2444, 2447, 2448, 2451, 2472, 2474,
    2480, 2482, 2482, 2486, 2489, 2493, 2493, 2510, 2510, 2524, 2525, 2527,
    2529, 2544, 2545, 2556, 2556, 2565, 2570, 2575, 2576, 2579, 2600, 2602,
    2608, 2610, 2611, 2613, 2614, 2616, 2617, 2649, 2652, 2654, 2654, 2674,
    2676, 2693, 2701, 2703, 2705, 2707, 2728, 2730, 2736, 2738, 2739, 2741,
    2745, 2749, 2749, 2768, 2768, 2784, 2785, 2809, 2809, 2821, 2828, 2831,
    2832, 2835, 2856, 2858, 2864, 2866, 2867, 2869, 2873, 2877, 2877, 2908,
    2909, 2911, 2913, 2929, 2929, 2947, 2947, 2949, 2954, 2958, 2960, 2962,
    2965, 2969, 2970, 2972, 2972, 2974, 2975, 2979, 2980, 2984, 2986, 2990,
    3001, 3024, 3024, 3077, 3084, 3086, 3088, 3090, 3112, 3114, 3129, 3133,
    3133, 3160, 3162, 3165, 3165, 3168, 3169, 3200, 3200, 3205, 3212, 3214,
    3216, 3218, 3240, 3242, 3251, 3253, 3257, 3261, 3261, 3293, 3294, 3296,
    3297, 3313, 3314, 3332, 3340, 3342, 3344, 3346, 3386, 3389, 3389, 3406,
    3406, 3412, 3414, 3423, 3425, 3450, 3455, 3461, 3478, 3482, 3505, 3507,
    3515, 3517, 3517, 3520, 3526, 3585, 3632, 3634, 3635, 3648, 3654, 3713,
    3714, 3716, 3716, 3718, 3722, 3724, 3747, 3749, 3749, 3751, 3760, 3762,
    3763, 3773, 3773, 3776, 3780, 3782, 3782, 3804, 3807, 3840, 3840, 3904,
    3911, 3913, 3948, 3976, 3980, 4096, 4138, 4159, 4159, 4176, 4181, 4186,
    4189, 4193, 4193, 4197, 4198, 4206, 4208, 4213, 4225, 4238, 4238, 4256,
    4293, 4295, 4295, 4301, 4301, 4304, 4346, 4348, 4680, 4682, 4685, 4688,
    4694, 4696, 4696, 4698, 4701, 4704, 4744, 4746, 4749, 4752, 4784, 4786,
    4789, 4792, 4798, 4800, 4800, 4802, 4805, 4808, 4822, 4824, 4880, 4882,
    4885, 4888, 4954, 4992, 5007, 5024, 5109, 5112, 5117, 5121, 5740, 5743,
    5759, 5761, 5786, 5792, 5866, 5873, 5880, 5888, 5905, 5919, 5937, 5952,
    5969, 5984, 5996, 5998, 6000, 6016, 6067, 6103, 6103, 6108, 6108, 6176,
    6264, 6272, 6276, 6279, 6312, 6314, 6314, 6320, 6389, 6400, 6430, 6480,
    6509, 6512, 6516, 6528, 6571, 6576, 6601, 6656, 6678, 6688, 6740, 6823,
    6823, 6917, 6963, 6981, 6988, 7043, 7072, 7086, 7087, 7098, 7141, 7168,
    7203, 7245, 7247, 7258, 7293, 7296, 7304, 7312, 7354, 7357, 7359, 7401,
    7404, 7406, 7411, 7413, 7414, 7418, 7418, 7424, 7615, 7680, 7957, 7960,
    7965, 7968, 8005, 8008, 8013, 8016, 8023, 8025, 8025, 8027, 8027, 8029,
    8029, 8031, 8061, 8064, 8116, 8118, 8124, 8126, 8126, 8130, 8132, 8134,
    8140, 8144, 8147, 8150, 8155, 8160, 8172, 8178, 8180, 8182, 8188, 8305,
    8305, 8319, 8319, 8336, 8348, 8450, 8450, 8455, 8455, 8458, 8467, 8469,
    8469, 8473, 8477, 8484, 8484, 8486, 8486, 8488, 8488, 8490, 8493, 8495,
    8505, 8508, 8511, 8517, 8521, 8526, 8526, 8579, 8580, 11264, 11492, 11499,
    11502, 11506, 11507, 11520, 11557, 11559, 11559, 11565, 11565, 11568, 11623,
    11631, 11631, 11648, 11670, 11680, 11686, 11688, 11694, 11696, 11702, 11704,
    11710, 11712, 11718, 11720, 11726, 11728, 11734, 11736, 11742, 11823, 11823,
    12293, 12294, 12337, 12341, 12347, 12348, 12353, 12438, 12445, 12447, 12449,
    12538, 12540, 12543, 12549, 12591, 12593, 12686, 12704, 12735, 12784, 12799,
    13312, 19903, 19968, 42124, 42192, 42237, 42240, 42508, 42512, 42527, 42538,
    42539, 42560, 42606, 42623, 42653, 42656, 42725, 42775, 42783, 42786, 42888,
    42891, 42954, 42960, 42961, 42963, 42963, 42965, 42969, 42994, 43009, 43011,
    43013, 43015, 43018, 43020, 43042, 43072, 43123, 43138, 43187, 43250, 43255,
    43259, 43259, 43261, 43262, 43274, 43301, 43312, 43334, 43360, 43388, 43396,
    43442, 43471, 43471, 43488, 43492, 43494, 43503, 43514, 43518, 43520, 43560,
    43584, 43586, 43588, 43595, 43616, 43638, 43642, 43642, 43646, 43695, 43697,
    43697, 43701, 43702, 43705, 43709, 43712, 43712, 43714, 43714, 43739, 43741,
    43744, 43754, 43762, 43764, 43777, 43782, 43785, 43790, 43793, 43798, 43808,
    43814, 43816, 43822, 43824, 43866, 43868, 43881, 43888, 44002, 44032, 55203,
    55216, 55238, 55243, 55291, 63744, 64109, 64112, 64217, 64256, 64262, 64275,
    64279, 64285, 64285, 64287, 64296, 64298, 64310, 64312, 64316, 64318, 64318,
    64320, 64321, 64323, 64324, 64326, 64433, 64467, 64829, 64848, 64911, 64914,
    64967, 65008, 65019, 65136, 65140, 65142, 65276, 65313, 65338, 65345, 65370,
    65382, 65470, 65474, 65479, 65482, 65487, 65490, 65495, 65498, 65500, 65536,
    65547, 65549, 65574, 65576, 65594, 65596, 65597, 65599, 65613, 65616, 65629,
    65664, 65786, 66176, 66204, 66208, 66256, 66304, 66335, 66349, 66368, 66370,
    66377, 66384, 66421, 66432, 66461, 66464, 66499, 66504, 66511, 66560, 66717,
    66736, 66771, 66776, 66811, 66816, 66855, 66864, 66915, 66928, 66938, 66940,
    66954, 66956, 66962, 66964, 66965, 66967, 66977, 66979, 66993, 66995, 67001,
    67003, 67004, 67072, 67382, 67392, 67413, 67424, 67431, 67456, 67461, 67463,
    67504, 67506, 67514, 67584, 67589, 67592, 67592, 67594, 67637, 67639, 67640,
    67644, 67644, 67647, 67669, 67680, 67702, 67712, 67742, 67808, 67826, 67828,
    67829, 67840, 67861, 67872, 67897, 67968, 68023, 68030, 68031, 68096, 68096,
    68112, 68115, 68117, 68119, 68121, 68149, 68192, 68220, 68224, 68252, 68288,
    68295, 68297, 68324, 68352, 68405, 68416, 68437, 68448, 68466, 68480, 68497,
    68608, 68680, 68736, 68786, 68800, 68850, 68864, 68899, 69248, 69289, 69296,
    69297, 69376, 69404, 69415, 69415, 69424, 69445, 69488, 69505, 69552, 69572,
    69600, 69622, 69635, 69687, 69745, 69746, 69749, 69749, 69763, 69807, 69840,
    69864, 69891, 69926, 69956, 69956, 69959, 69959, 69968, 70002, 70006, 70006,
    70019, 70066, 70081, 70084, 70106, 70106, 70108, 70108, 70144, 70161, 70163,
    70187, 70207, 70208, 70272, 70278, 70280, 70280, 70282, 70285, 70287, 70301,
    70303, 70312, 70320, 70366, 70405, 70412, 70415, 70416, 70419, 70440, 70442,
    70448, 70450, 70451, 70453, 70457, 70461, 70461, 70480, 70480, 70493, 70497,
    70656, 70708, 70727, 70730, 70751, 70753, 70784, 70831, 70852, 70853, 70855,
    70855, 71040, 71086, 71128, 71131, 71168, 71215, 71236, 71236, 71296, 71338,
    71352, 71352, 71424, 71450, 71488, 71494, 71680, 71723, 71840, 71903, 71935,
    71942, 71945, 71945, 71948, 71955, 71957, 71958, 71960, 71983, 71999, 71999,
    72001, 72001, 72096, 72103, 72106, 72144, 72161, 72161, 72163, 72163, 72192,
    72192, 72203, 72242, 72250, 72250, 72272, 72272, 72284, 72329, 72349, 72349,
    72368, 72440, 72704, 72712, 72714, 72750, 72768, 72768, 72818, 72847, 72960,
    72966, 72968, 72969, 72971, 73008, 73030, 73030, 73056, 73061, 73063, 73064,
    73066, 73097, 73112, 73112, 73440, 73458, 73474, 73474, 73476, 73488, 73490,
    73523, 73648, 73648, 73728, 74649, 74880, 75075, 77712, 77808, 77824, 78895,
    78913, 78918, 82944, 83526, 92160, 92728, 92736, 92766, 92784, 92862, 92880,
    92909, 92928, 92975, 92992, 92995, 93027, 93047, 93053, 93071, 93760, 93823,
    93952, 94026, 94032, 94032, 94099, 94111, 94176, 94177, 94179, 94179, 94208,
    100343, 100352, 101589, 101632, 101640, 110576, 110579, 110581, 110587,
    110589, 110590, 110592, 110882, 110898, 110898, 110928, 110930, 110933,
    110933, 110948, 110951, 110960, 111355, 113664, 113770, 113776, 113788,
    113792, 113800, 113808, 113817, 119808, 119892, 119894, 119964, 119966,
    119967, 119970, 119970, 119973, 119974, 119977, 119980, 119982, 119993,
    119995, 119995, 119997, 120003, 120005, 120069, 120071, 120074, 120077,
    120084, 120086, 120092, 120094, 120121, 120123, 120126, 120128, 120132,
    120134, 120134, 120138, 120144, 120146, 120485, 120488, 120512, 120514,
    120538, 120540, 120570, 120572, 120596, 120598, 120628, 120630, 120654,
    120656, 120686, 120688, 120712, 120714, 120744, 120746, 120770, 120772,
    120779, 122624, 122654, 122661, 122666, 122928, 122989, 123136, 123180,
    123191, 123197, 123214, 123214, 123536, 123565, 123584, 123627, 124112,
    124139, 124896, 124902, 124904, 124907, 124909, 124910, 124912, 124926,
    124928, 125124, 125184, 125251, 125259, 125259, 126464, 126467, 126469,
    126495, 126497, 126498, 126500, 126500, 126503, 126503, 126505, 126514,
    126516, 126519, 126521, 126521, 126523, 126523, 126530, 126530, 126535,
    126535, 126537, 126537, 126539, 126539, 126541, 126543, 126545, 126546,
    126548, 126548, 126551, 126551, 126553, 126553, 126555, 126555, 126557,
    126557, 126559, 126559, 126561, 126562, 126564, 126564, 126567, 126570,
    126572, 126578, 126580, 126583, 126585, 126588, 126590, 126590, 126592,
    126601, 126603, 126619, 126625, 126627, 126629, 126633, 126635, 126651,
    131072, 173791, 173824, 177977, 177984, 178205, 178208, 183969, 183984,
    191456, 194560, 195101, 196608, 201546, 201552, 205743, 2, 0, 39, 39, 92,
    92, 2, 0, 34, 34, 92, 92, 9, 0, 34, 34, 39, 39, 92, 92, 98, 98, 102, 102,
    110, 110, 114, 114, 116, 116, 118, 118, 12, 0, 10, 10, 13, 13, 34, 34, 39,
    39, 48, 57, 92, 92, 98, 98, 102, 102, 110, 110, 114, 114, 116, 118, 120,
    120, 3, 0, 48, 57, 117, 117, 120, 120, 3, 0, 9, 10, 13, 13, 32, 32, 222, 0,
    1, 1, 0, 0, 0, 0, 3, 1, 0, 0, 0, 0, 9, 1, 0, 0, 0, 0, 11, 1, 0, 0, 0, 0, 13,
    1, 0, 0, 0, 0, 15, 1, 0, 0, 0, 0, 17, 1, 0, 0, 0, 0, 19, 1, 0, 0, 0, 0, 21,
    1, 0, 0, 0, 0, 23, 1, 0, 0, 0, 0, 25, 1, 0, 0, 0, 0, 27, 1, 0, 0, 0, 0, 29,
    1, 0, 0, 0, 0, 31, 1, 0, 0, 0, 0, 33, 1, 0, 0, 0, 0, 35, 1, 0, 0, 0, 0, 37,
    1, 0, 0, 0, 0, 39, 1, 0, 0, 0, 0, 41, 1, 0, 0, 0, 0, 43, 1, 0, 0, 0, 0, 45,
    1, 0, 0, 0, 0, 47, 1, 0, 0, 0, 0, 53, 1, 0, 0, 0, 0, 69, 1, 0, 0, 0, 1, 71,
    1, 0, 0, 0, 3, 97, 1, 0, 0, 0, 5, 99, 1, 0, 0, 0, 7, 101, 1, 0, 0, 0, 9,
    112, 1, 0, 0, 0, 11, 114, 1, 0, 0, 0, 13, 116, 1, 0, 0, 0, 15, 118, 1, 0, 0,
    0, 17, 120, 1, 0, 0, 0, 19, 122, 1, 0, 0, 0, 21, 124, 1, 0, 0, 0, 23, 126,
    1, 0, 0, 0, 25, 128, 1, 0, 0, 0, 27, 130, 1, 0, 0, 0, 29, 132, 1, 0, 0, 0,
    31, 135, 1, 0, 0, 0, 33, 137, 1, 0, 0, 0, 35, 140, 1, 0, 0, 0, 37, 143, 1,
    0, 0, 0, 39, 146, 1, 0, 0, 0, 41, 148, 1, 0, 0, 0, 43, 151, 1, 0, 0, 0, 45,
    154, 1, 0, 0, 0, 47, 156, 1, 0, 0, 0, 49, 165, 1, 0, 0, 0, 51, 168, 1, 0, 0,
    0, 53, 186, 1, 0, 0, 0, 55, 191, 1, 0, 0, 0, 57, 196, 1, 0, 0, 0, 59, 198,
    1, 0, 0, 0, 61, 202, 1, 0, 0, 0, 63, 204, 1, 0, 0, 0, 65, 206, 1, 0, 0, 0,
    67, 210, 1, 0, 0, 0, 69, 213, 1, 0, 0, 0, 71, 72, 3, 15, 7, 0, 72, 73, 3, 3,
    1, 0, 73, 74, 3, 45, 22, 0, 74, 75, 3, 3, 1, 0, 75, 76, 3, 17, 8, 0, 76, 2,
    1, 0, 0, 0, 77, 81, 3, 7, 3, 0, 78, 80, 3, 5, 2, 0, 79, 78, 1, 0, 0, 0, 80,
    83, 1, 0, 0, 0, 81, 79, 1, 0, 0, 0, 81, 82, 1, 0, 0, 0, 82, 98, 1, 0, 0, 0,
    83, 81, 1, 0, 0, 0, 84, 88, 3, 7, 3, 0, 85, 87, 3, 5, 2, 0, 86, 85, 1, 0, 0,
    0, 87, 90, 1, 0, 0, 0, 88, 86, 1, 0, 0, 0, 88, 89, 1, 0, 0, 0, 89, 91, 1, 0,
    0, 0, 90, 88, 1, 0, 0, 0, 91, 93, 3, 39, 19, 0, 92, 94, 3, 5, 2, 0, 93, 92,
    1, 0, 0, 0, 94, 95, 1, 0, 0, 0, 95, 93, 1, 0, 0, 0, 95, 96, 1, 0, 0, 0, 96,
    98, 1, 0, 0, 0, 97, 77, 1, 0, 0, 0, 97, 84, 1, 0, 0, 0, 98, 4, 1, 0, 0, 0,
    99, 100, 7, 0, 0, 0, 100, 6, 1, 0, 0, 0, 101, 102, 7, 1, 0, 0, 102, 8, 1, 0,
    0, 0, 103, 104, 5, 116, 0, 0, 104, 105, 5, 114, 0, 0, 105, 106, 5, 117, 0,
    0, 106, 113, 5, 101, 0, 0, 107, 108, 5, 102, 0, 0, 108, 109, 5, 97, 0, 0,
    109, 110, 5, 108, 0, 0, 110, 111, 5, 115, 0, 0, 111, 113, 5, 101, 0, 0, 112,
    103, 1, 0, 0, 0, 112, 107, 1, 0, 0, 0, 113, 10, 1, 0, 0, 0, 114, 115, 5, 40,
    0, 0, 115, 12, 1, 0, 0, 0, 116, 117, 5, 41, 0, 0, 117, 14, 1, 0, 0, 0, 118,
    119, 5, 91, 0, 0, 119, 16, 1, 0, 0, 0, 120, 121, 5, 93, 0, 0, 121, 18, 1, 0,
    0, 0, 122, 123, 5, 43, 0, 0, 123, 20, 1, 0, 0, 0, 124, 125, 5, 45, 0, 0,
    125, 22, 1, 0, 0, 0, 126, 127, 5, 42, 0, 0, 127, 24, 1, 0, 0, 0, 128, 129,
    5, 47, 0, 0, 129, 26, 1, 0, 0, 0, 130, 131, 5, 62, 0, 0, 131, 28, 1, 0, 0,
    0, 132, 133, 5, 62, 0, 0, 133, 134, 5, 61, 0, 0, 134, 30, 1, 0, 0, 0, 135,
    136, 5, 60, 0, 0, 136, 32, 1, 0, 0, 0, 137, 138, 5, 60, 0, 0, 138, 139, 5,
    61, 0, 0, 139, 34, 1, 0, 0, 0, 140, 141, 5, 61, 0, 0, 141, 142, 5, 61, 0, 0,
    142, 36, 1, 0, 0, 0, 143, 144, 5, 33, 0, 0, 144, 145, 5, 61, 0, 0, 145, 38,
    1, 0, 0, 0, 146, 147, 5, 46, 0, 0, 147, 40, 1, 0, 0, 0, 148, 149, 5, 38, 0,
    0, 149, 150, 5, 38, 0, 0, 150, 42, 1, 0, 0, 0, 151, 152, 5, 124, 0, 0, 152,
    153, 5, 124, 0, 0, 153, 44, 1, 0, 0, 0, 154, 155, 5, 44, 0, 0, 155, 46, 1,
    0, 0, 0, 156, 160, 3, 51, 25, 0, 157, 159, 3, 49, 24, 0, 158, 157, 1, 0, 0,
    0, 159, 162, 1, 0, 0, 0, 160, 158, 1, 0, 0, 0, 160, 161, 1, 0, 0, 0, 161,
    48, 1, 0, 0, 0, 162, 160, 1, 0, 0, 0, 163, 166, 3, 51, 25, 0, 164, 166, 7,
    2, 0, 0, 165, 163, 1, 0, 0, 0, 165, 164, 1, 0, 0, 0, 166, 50, 1, 0, 0, 0,
    167, 169, 7, 3, 0, 0, 168, 167, 1, 0, 0, 0, 169, 52, 1, 0, 0, 0, 170, 174,
    5, 34, 0, 0, 171, 173, 3, 57, 28, 0, 172, 171, 1, 0, 0, 0, 173, 176, 1, 0,
    0, 0, 174, 172, 1, 0, 0, 0, 174, 175, 1, 0, 0, 0, 175, 177, 1, 0, 0, 0, 176,
    174, 1, 0, 0, 0, 177, 187, 5, 34, 0, 0, 178, 182, 5, 39, 0, 0, 179, 181, 3,
    55, 27, 0, 180, 179, 1, 0, 0, 0, 181, 184, 1, 0, 0, 0, 182, 180, 1, 0, 0, 0,
    182, 183, 1, 0, 0, 0, 183, 185, 1, 0, 0, 0, 184, 182, 1, 0, 0, 0, 185, 187,
    5, 39, 0, 0, 186, 170, 1, 0, 0, 0, 186, 178, 1, 0, 0, 0, 187, 54, 1, 0, 0,
    0, 188, 192, 8, 4, 0, 0, 189, 190, 5, 92, 0, 0, 190, 192, 3, 59, 29, 0, 191,
    188, 1, 0, 0, 0, 191, 189, 1, 0, 0, 0, 192, 56, 1, 0, 0, 0, 193, 197, 8, 5,
    0, 0, 194, 195, 5, 92, 0, 0, 195, 197, 3, 59, 29, 0, 196, 193, 1, 0, 0, 0,
    196, 194, 1, 0, 0, 0, 197, 58, 1, 0, 0, 0, 198, 199, 3, 61, 30, 0, 199, 60,
    1, 0, 0, 0, 200, 203, 3, 63, 31, 0, 201, 203, 3, 65, 32, 0, 202, 200, 1, 0,
    0, 0, 202, 201, 1, 0, 0, 0, 203, 62, 1, 0, 0, 0, 204, 205, 7, 6, 0, 0, 205,
    64, 1, 0, 0, 0, 206, 207, 8, 7, 0, 0, 207, 66, 1, 0, 0, 0, 208, 211, 3, 63,
    31, 0, 209, 211, 7, 8, 0, 0, 210, 208, 1, 0, 0, 0, 210, 209, 1, 0, 0, 0,
    211, 68, 1, 0, 0, 0, 212, 214, 7, 9, 0, 0, 213, 212, 1, 0, 0, 0, 214, 215,
    1, 0, 0, 0, 215, 213, 1, 0, 0, 0, 215, 216, 1, 0, 0, 0, 216, 217, 1, 0, 0,
    0, 217, 218, 6, 34, 0, 0, 218, 70, 1, 0, 0, 0, 17, 0, 81, 88, 95, 97, 112,
    160, 165, 168, 174, 182, 186, 191, 196, 202, 210, 215, 1, 6, 0, 0,
  ];

  private static __ATN: antlr.ATN;
  public static get _ATN(): antlr.ATN {
    if (!ExpressionsLexer.__ATN) {
      ExpressionsLexer.__ATN = new antlr.ATNDeserializer().deserialize(
        ExpressionsLexer._serializedATN,
      );
    }

    return ExpressionsLexer.__ATN;
  }

  private static readonly vocabulary = new antlr.Vocabulary(
    ExpressionsLexer.literalNames,
    ExpressionsLexer.symbolicNames,
    [],
  );

  public override get vocabulary(): antlr.Vocabulary {
    return ExpressionsLexer.vocabulary;
  }

  private static readonly decisionsToDFA =
    ExpressionsLexer._ATN.decisionToState.map(
      (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index),
    );
}
