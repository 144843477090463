import {type DependencyList, useEffect, useState} from 'react';

export function useAsyncMemo<T>(
  factory: () => Promise<T> | undefined | null,
  deps: DependencyList,
): T | undefined {
  const [val, setVal] = useState<T | undefined>(undefined);
  useEffect(() => {
    let cancel = false;
    const promise = factory();
    if (promise === undefined || promise === null) return;
    promise.then((resolvedVal) => {
      if (!cancel) {
        setVal(resolvedVal);
      }
    });
    return () => {
      cancel = true;
    };
  }, deps);
  return val;
}

// The difference to the one above is that this one returns undefined if the deps change until there is a result
export function useAsyncMemoAndResetOnChange<T>(
  factory: () => Promise<T> | undefined | null,
  deps: DependencyList,
): T | undefined {
  const [val, setVal] = useState<T | undefined>(undefined);
  useEffect(() => {
    let cancel = false;
    const promise = factory();
    if (promise === undefined || promise === null) return;
    setVal(undefined);
    promise.then((resolvedVal) => {
      if (!cancel) {
        setVal(resolvedVal);
      }
    });
    return () => {
      cancel = true;
    };
  }, deps);
  return val;
}
