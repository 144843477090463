import {mz} from 'mehrzahl';
import {type FC} from 'react';
import {Handle, type NodeProps, Position} from 'reactflow';
import {twMerge} from 'tailwind-merge';
import {type SchrittDaten} from '../lib/store/prozess-aenderungen.ts';
import {type ProzessEditorSchritt} from '../prozess-version-erstellen.generated.ts';

export type KommunalappSchrittNodeData = {
  schritt: SchrittDaten;
  isStart: boolean;
};

export type KommunalappSchrittNodeProps = NodeProps<KommunalappSchrittNodeData>;

export const KommunalappSchrittNode: FC<KommunalappSchrittNodeProps> = ({
  data: {schritt, isStart},
  selected,
}) => (
  <>
    {!isStart && <Handle type='target' position={Position.Left} />}
    <KommunalappSchrittNodeRender schritt={schritt} selected={selected} />
    {schritt.__typename !== 'EndeSchritt' && (
      <Handle type='source' position={Position.Right} />
    )}
  </>
);

export type KommunalappSchrittNodeRenderProps = {
  schritt: SchrittDaten;
  selected?: boolean;
};

const schrittArtLabels: Record<ProzessEditorSchritt['__typename'], string> = {
  WarteAufBezahlungAbgeschlossenSchritt: 'Bezahlung',
  BezahlenSchritt: 'Bezahlung',
  EndeSchritt: 'Ende',
  FormularSchritt: 'Formular',
  ProgrammatischerSchritt: 'Automatisierung',
  EmailForwarderExportSchritt: 'EMail',
  GovManagerRestExportSchritt: 'GovManager Rest Export',
  WarteAufGovManagerSchritt: 'GovManager Rest Export',
};

export const KommunalappSchrittNodeRender: FC<
  KommunalappSchrittNodeRenderProps
> = ({schritt, selected = false}) => (
  <div className='relative min-w-32 rounded border-[1.5px] border-primary bg-white'>
    <div className='absolute left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border bg-white px-2 text-center text-xs'>
      {schrittArtLabels[schritt.__typename]}
    </div>
    <div className={twMerge('p-4', selected && 'bg-primary text-white')}>
      <p className='text-center'>{schritt.titel}</p>

      {schritt.__typename === 'FormularSchritt' &&
        schritt.felder.length > 0 && (
          <div className='mt-2 grid grid-cols-2 gap-x-3 border-t pt-2'>
            <span className='col-span-2'>
              {mz(schritt.felder.length)`Formularfeld{|er}`}:
            </span>
            {schritt.felder.map((f) => (
              <p
                key={f.id}
                className={
                  (f.angezeigterName?.length ?? 0) > 30 ? 'col-span-2' : ''
                }
              >
                {f.angezeigterName ?? 'Eingabe'}
              </p>
            ))}
          </div>
        )}
    </div>
  </div>
);
