import {useSharedSessionStorage} from '@kommunalapp/common';
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {LoginResult} from '../../../__generated__/graphql';
import {createUrqlClient, NoContextProvidedError} from '../../../urql';
import {
  decryptOrganisationKey,
  decryptPersonalKey,
  getServerLoginPassword,
} from '../../crypto/crypto';
import {
  LoginDocument,
  type LoginMutation,
  type LoginMutationVariables,
  MyDocument,
  type MyQuery,
  type UserFragment,
} from './auth.generated';

type AuthContextData = {
  isLoggedIn: boolean;
  user: UserFragment | null;
  passwordLogin: (
    email: string,
    password: string,
    twoFactorCode?: string,
  ) => Promise<LoginResult | undefined>;
  logout: (passwordChanged?: boolean) => Promise<void>;
  personalPrivateKey: CryptoKey | undefined;
  organisationPrivateKey: CryptoKey | undefined;
  redirectedFromPwChange: boolean;
  token?: string;
};

type SessionData = {
  user?: UserFragment;
  password?: string;
  personalPrivateKey?: CryptoKey;
  organisationPrivateKey?: CryptoKey;
  token?: string;
};

export const AuthContext = createContext<AuthContextData | null>(null);

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new NoContextProvidedError('AuthContext');
  }

  return auth;
};

export type UserTypes = UserFragment['__typename'];
export const userTypePasswordMinLength: Record<UserTypes, number> = {
  Buerger: 8,
  BackofficeUser: 12,
  SystemUser: 100,
};

export const getPasswordMinLengthByUser = (userType?: UserTypes) => {
  if (!userType || !(userType in userTypePasswordMinLength)) {
    return 12;
  }
  return userTypePasswordMinLength[userType];
};

export const AuthProvider: FC<
  PropsWithChildren<{broadcastTimeout?: number}>
> = ({children, broadcastTimeout}) => {
  const [redirectedFromPwChange, setRedirectedFromPwChange] = useState(false);
  const client = createUrqlClient();

  const {
    data: session = {},
    setData: setSessionData,
    loading,
    clear: clearSessionData,
  } = useSharedSessionStorage<SessionData>({broadcastTimeout});

  const isLoggedIn = !!session.user;

  const passwordLogin = useCallback(
    async (email: string, rawPassword: string) => {
      email = email.toLowerCase();

      const serverLoginPassword = await getServerLoginPassword(
        rawPassword,
        email,
      );
      setRedirectedFromPwChange(false);

      if (!serverLoginPassword) return;
      const response = await client.mutation<
        LoginMutation,
        LoginMutationVariables
      >(LoginDocument, {
        input: {
          email,
          password: serverLoginPassword,
        },
      });
      const {result = LoginResult.WrongLogin, token} =
        response.data?.login ?? {};

      if (result !== LoginResult.Success || !token) {
        return result;
      }

      const authenticatedClient = createUrqlClient({token});

      const user = (await authenticatedClient.query<MyQuery>(MyDocument, {}))
        .data?.my;

      if (!user) {
        return result;
      }

      if (
        !(
          ['Buerger', 'BackofficeUser'] satisfies UserTypes[] as string[]
        ).includes(user.__typename) ||
        !('personalPrivateKeySalt' in user)
      ) {
        return result;
      }

      const personalPrivateKey = await decryptPersonalKey(
        rawPassword,
        user.personalPrivateKeySalt!,
        user.personalPrivateKey!,
      );

      let organisationPrivateKey: CryptoKey | undefined;

      if (user.__typename === 'BackofficeUser') {
        organisationPrivateKey = await decryptOrganisationKey(
          personalPrivateKey,
          user.organisationPrivateKey!,
          user.kommune.privateKey!,
        );
      }

      const sessionData = {
        password: rawPassword,
        token,
        user,
        personalPrivateKey,
        organisationPrivateKey,
      };

      await setSessionData(sessionData);

      return result;
    },
    [client, setSessionData],
  );

  const logout = useCallback(
    async (passwordChanged?: boolean) => {
      clearSessionData();
      setRedirectedFromPwChange(passwordChanged || false);
      // Make sure that nothing is still loaded in the cache
      location.reload();
    },
    [clearSessionData],
  );

  const contextValue = useMemo<AuthContextData>(
    () => ({
      isLoggedIn,
      passwordLogin,
      logout,
      user: session.user ?? null,
      personalPrivateKey: session.personalPrivateKey,
      organisationPrivateKey: session.organisationPrivateKey,
      redirectedFromPwChange,
      token: session.token,
    }),
    [
      isLoggedIn,
      passwordLogin,
      logout,
      session.user,
      session.personalPrivateKey,
      session.organisationPrivateKey,
      session.token,
      redirectedFromPwChange,
    ],
  );

  return loading ? null : (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
