import {type FC} from 'react';
import {MdCheck} from 'react-icons/md';
import {type AntragDetailSchritt_BezahlenSchritt} from '../../detail.generated.ts';

export type BezahlenSchrittProps = {
  schritt: AntragDetailSchritt_BezahlenSchritt;
};

export const BezahlenSchritt: FC<BezahlenSchrittProps> = ({schritt}) => (
  <div className='flex items-center p-2'>
    Zahlung über {schritt.betrag.toFixed(2).replace('.', ',')}€ war erfolgreich.{' '}
    <MdCheck />
  </div>
);
