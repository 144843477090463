import {type FC, type PropsWithChildren, type ReactNode} from 'react';
import {
  MdAccountCircle,
  MdArrowDropDown,
  MdDashboardCustomize,
  MdLogout,
  MdMenu,
  MdOutlineEditNote,
} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {useAuth} from '../../lib/context/auth/auth.tsx';
import {KommuneAdminPolicy} from '../kommune-admin-policy.tsx';
import {DropdownMenu} from '../ui/dropdown-menu/dropdown-menu.tsx';

export const mainMenuItems: {
  label: string;
  href: string;
  icon: ReactNode;
  external?: boolean;
  kommuneAdminRoute?: boolean;
}[] = [
  {
    label: 'Nutzendenverwaltung',
    href: '/nutzendenverwaltung',
    icon: <MdAccountCircle />,
    kommuneAdminRoute: true,
  },
  {
    label: 'Inhaltspflege',
    href: '/cms',
    icon: <MdDashboardCustomize />,
    external: true,
    kommuneAdminRoute: false,
  },
  {
    label: 'Prozessverwaltung',
    href: '/prozessverwaltung',
    icon: <MdOutlineEditNote />,
    kommuneAdminRoute: true,
  },
];

export const NavMenu = () => {
  const {logout} = useAuth();

  return (
    <div className='ml-auto flex gap-4 justify-self-end'>
      <UserMenu />
      <VerticalDivider />
      <div className='relative flex h-full items-center'>
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <MdMenu className='size-8 hover:cursor-pointer' />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content>
              {mainMenuItems.map(
                ({label, href, external, icon, kommuneAdminRoute}) =>
                  kommuneAdminRoute ? (
                    <KommuneAdminPolicy key={label}>
                      <DropdownMenu.Item key={label}>
                        <MenuItem href={href} external={external}>
                          {icon}
                          {label}
                        </MenuItem>
                      </DropdownMenu.Item>
                    </KommuneAdminPolicy>
                  ) : (
                    <DropdownMenu.Item key={label}>
                      <MenuItem href={href} external={external}>
                        {icon}
                        {label}
                      </MenuItem>
                    </DropdownMenu.Item>
                  ),
              )}
              <DropdownMenu.Arrow />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu>
      </div>
      <VerticalDivider />
      <button
        type='button'
        className='flex items-center text-primary'
        onClick={() => logout()}
      >
        Abmelden
        <MdLogout className='ml-2 inline-block size-6' />
      </button>
    </div>
  );
};

export const UserMenu: FC<{mobile?: boolean; className?: string}> = ({
  className,
}) => {
  const {user} = useAuth();

  const vorname =
    user?.__typename != 'Buerger' && user?.__typename != 'SystemUser'
      ? user?.vorname
      : undefined;
  const nachname =
    user?.__typename != 'Buerger' && user?.__typename != 'SystemUser'
      ? user?.nachname
      : undefined;

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <div
          className={twMerge(
            'flex items-center gap-2 hover:cursor-pointer',
            className,
          )}
        >
          <MdAccountCircle className='my-auto size-10 text-primary' />
          <div className='flex flex-col'>
            {vorname && nachname && (
              <p className='font-bold'>{`${vorname.at(0)}. ${nachname}`}</p>
            )}
            <p className='text-xs'>
              {user && 'kommune' in user && user.kommune.name}
            </p>
          </div>
          <MdArrowDropDown />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <DropdownMenu.Item>Menüpunkt 1</DropdownMenu.Item>
          <DropdownMenu.Item>Menüpunkt 2</DropdownMenu.Item>
          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

const VerticalDivider: FC = () => (
  <hr className='my-auto h-8 w-[1px] bg-gray-200' />
);

const MenuItem: FC<PropsWithChildren<{href: string; external?: boolean}>> = ({
  href,
  external,
  children,
}) => (
  <Link
    to={href}
    className='flex w-full items-center gap-2 text-gray-600'
    reloadDocument={external}
  >
    {children}
  </Link>
);
