import {type FormBuilder} from '@atmina/formbuilder';
import {
  type BrowserNativeObject,
  type Primitive,
  type RegisterOptions,
} from 'react-hook-form';

export type FieldRules<T> = RegisterOptions<
  {__: T; b: T},
  T extends Primitive | BrowserNativeObject ? '__' : '__'
>;

export type FieldProps<T = string> = {
  label: string;
  on: FormBuilder<T>;
  className?: string;
  helperText?: string;
  rules?: FieldRules<T> | null;
  type?: string;
  monospace?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
};

export const getFieldError = (on: FormBuilder<unknown>) => {
  const errorMap: Record<string, string> = {
    required: 'Dieses Feld ist ein Pflichtfeld.',
  };

  const {errors} = on.$useState();

  return errors
    ? errors.message || (errors.type && errorMap[errors.type]) || true
    : undefined;
};
