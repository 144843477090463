import {type FC} from 'react';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {type AntragDetailAusfuehrung} from '../../detail.generated.ts';

export type DateFormularFeldProps = {
  slotName: string;
  ausfuehrung: AntragDetailAusfuehrung;
};

export const DateFormularFeld: FC<DateFormularFeldProps> = ({
  slotName,
  ausfuehrung,
}) => (
  <SlotValue
    value={ausfuehrung.slotValues.find((sv) => sv.slotName === slotName) ?? ''}
  >
    {(value) =>
      new Date(value as string).toLocaleDateString('de-DE', {
        dateStyle: 'medium',
      })
    }
  </SlotValue>
);
