import maplibregl from 'maplibre-gl';
import {useEffect, type FC, useId} from 'react';
import MaengelIcon from '../../../../../assets/maengel.svg?raw';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {mapTheme} from '../../../../../utils/mapTheme.ts';
import 'maplibre-gl/dist/maplibre-gl.css';
import {type AntragDetailAusfuehrung} from '../../detail.generated.ts';

export type LocationFormularFeldProps = {
  slotName: string;
  ausfuehrung: AntragDetailAusfuehrung;
  renderAsMap?: boolean;
};
type LocationProps = {
  addressString: string;
  coordinates: [lng: number, lat: number];
};

const MapComponent: FC<LocationProps> = ({coordinates}) => {
  const idSuffix = useId();

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(MaengelIcon, 'image/svg+xml');

    const map = new maplibregl.Map({
      container: `map${idSuffix}`,
      style: mapTheme,
      center: [coordinates[0], coordinates[1]],
      zoom: 14,
      minZoom: 7,
    });
    new maplibregl.Marker({
      element: doc.documentElement,
      anchor: 'bottom',
    })
      .setLngLat([coordinates[0], coordinates[1]])
      .addTo(map);

    return () => {
      map.remove();
    };
  }, [idSuffix, coordinates]);

  return (
    <div className='p-4'>
      <div id={`map${idSuffix}`} className='h-96 w-full rounded-md' />
    </div>
  );
};

export const LocationFormularFeld: FC<LocationFormularFeldProps> = ({
  slotName,
  ausfuehrung,
  renderAsMap = false,
}) => {
  const value =
    ausfuehrung.slotValues.find((sv) => sv.slotName === slotName) ?? '';

  return (
    <SlotValue<LocationProps> value={value}>
      {(value) =>
        typeof value === 'string' ? (
          value
        ) : renderAsMap ? (
          <MapComponent
            addressString={value.addressString}
            coordinates={[value.coordinates[0], value.coordinates[1]]}
          />
        ) : (
          <div>{value.addressString}</div>
        )
      }
    </SlotValue>
  );
};
