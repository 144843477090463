import {type FC} from 'react';
import {MdClose} from 'react-icons/md';
import {useStore} from 'reactflow';

export type SidebarHeaderProps = {
  title: string;
};

export const SidebarHeader: FC<SidebarHeaderProps> = ({title}) => {
  const unselectNodesAndEdges = useStore(
    (state) => state.unselectNodesAndEdges,
  );

  return (
    <div className='flex h-[72px] shrink-0 items-center gap-3 border-b border-b-gray-300 p-4'>
      <h2 className='flex-1 font-bold'>{title}</h2>
      <button
        className='shrink-0 p-3'
        type='button'
        onClick={() => unselectNodesAndEdges()}
      >
        <MdClose className='size-4' />
      </button>
    </div>
  );
};
