import {type FC, type ReactNode} from 'react';
import {type EncryptedValueFragment} from '../../lib/context/auth/auth.generated';
import {useCrypto} from '../../lib/context/crypto/crypto.tsx';
import {symmetricDecryptValueWithCache} from '../../lib/crypto/crypto';
import {useAsyncMemo} from '../../lib/hooks/use-async-memo.ts';
import {type CryptoCache} from '../../lib/hooks/use-crypto-cache.ts';

export type DecryptValueProps = {
  value: EncryptedValueFragment | string;
  decryptionKey?: CryptoKey;
  cryptoCache?: CryptoCache;
  children?: (decrypted: string) => ReactNode;
};

export const DecryptValue: FC<DecryptValueProps> = ({
  value,
  decryptionKey: providedKey,
  cryptoCache: providedCache,
  children,
}) => {
  const {decryptionKey = providedKey, cryptoCache = providedCache} =
    useCrypto();

  const decrypted = useAsyncMemo(async () => {
    if (typeof value === 'string') return value;
    if (decryptionKey === undefined) {
      return '';
    }

    try {
      return await symmetricDecryptValueWithCache(
        decryptionKey,
        value,
        cryptoCache,
      );
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [value, decryptionKey, cryptoCache]);

  if (decrypted === undefined || decryptionKey === undefined) {
    // TODO: Skeleton
    return null;
  }

  if (!children) {
    return <>{decrypted}</>;
  }

  return <>{children(decrypted)}</>;
};

type ObjectWithMultiple = {
  [key: string]: EncryptedValueFragment | string | null | undefined;
};
type MapPropertiesToString<T extends ObjectWithMultiple> = {
  [P in keyof T]: string;
};

export function DecryptValueMultiple<T extends ObjectWithMultiple>({
  value,
  decryptionKey: providedKey,
  cryptoCache: providedCache,
  children,
}: {
  value: T;
  decryptionKey?: CryptoKey;
  cryptoCache?: CryptoCache;
  children: (decrypted: MapPropertiesToString<T>) => ReactNode;
}) {
  const {decryptionKey = providedKey, cryptoCache = providedCache} =
    useCrypto();

  const decrypted = useAsyncMemo<MapPropertiesToString<T>>(async () => {
    const promises = Object.entries(value).map(([k, v]) => {
      if (decryptionKey === undefined || v === null || v === undefined) {
        return Promise.resolve([k, '']);
      } else if (typeof v === 'string') {
        return Promise.resolve([k, v]);
      } else {
        return symmetricDecryptValueWithCache(
          decryptionKey,
          v,
          cryptoCache,
        ).then((d) => [k, d]);
      }
    });

    return Object.fromEntries(await Promise.all(promises));
  }, [value, decryptionKey, cryptoCache]);
  if (decrypted === undefined || decryptionKey === undefined) {
    // TODO: Skeleton
    return null;
  }

  return <>{children(decrypted)}</>;
}
