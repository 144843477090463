import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect, useState} from 'react';
import {Form} from '../../../../components/form/form.tsx';
import {Sidebar} from '../../../../components/sidebar/sidebar.tsx';
import {Button} from '../../../../components/ui/button';
import {useProzessEditorStore} from '../lib/store';
import {type SchrittDaten} from '../lib/store/prozess-aenderungen.ts';
import {BearbeitendeGruppeSelect} from './form/bearbeitende-gruppe-select.tsx';
import {FormularSchrittForm} from './form/formular-schritt-form.tsx';
import {GovManagerRestExportSchritt} from './form/govmanager-export-schritt.tsx';
import {SidebarHeader} from './sidebar-header.tsx';

type ProzessSchrittForm = SchrittDaten;

export const ProzessSidebar: FC = () => {
  const selectedSchritt = useProzessEditorStore(
    (state) => state.selectedSchritt,
  );
  const currentProzess = useProzessEditorStore((state) => state.prozess);
  const [subFormIsValid, setSubFormIsValid] = useState(true);

  const builder = useFormBuilder<ProzessSchrittForm>();
  const {reset, formState} = builder;
  useEffect(() => {
    if (!selectedSchritt) {
      reset();
      return;
    }

    reset(selectedSchritt);
  }, [reset, selectedSchritt]);

  const updateSchritt = useProzessEditorStore((state) => state.updateSchritt);
  const handleSubmit = useCallback(
    (form: ProzessSchrittForm) => {
      if (!selectedSchritt?.id) return;
      updateSchritt(selectedSchritt.id, form);
    },
    [updateSchritt, selectedSchritt?.id],
  );

  const [__typename, narrowed] = builder.fields.$discriminate('__typename');
  const isValid = formState.isValid;
  return (
    <Sidebar isOpen={!!selectedSchritt}>
      <SidebarHeader title={selectedSchritt?.titel ?? ''} />

      <Form
        builder={builder}
        className='flex h-full w-[480px] flex-1 flex-col gap-4 overflow-auto p-4'
        onSubmit={handleSubmit}
      >
        <p>Zuständigkeit für diesen Prozessknoten zuweisen</p>
        <BearbeitendeGruppeSelect on={builder.fields.mitarbeiterGruppe.id} />
        <div className='flex flex-col'>
          {__typename === 'FormularSchritt' && (
            <FormularSchrittForm on={narrowed} />
          )}
          {__typename === 'GovManagerRestExportSchritt' && (
            <GovManagerRestExportSchritt
              subFormIsValid={(value: boolean) => setSubFormIsValid(value)}
              on={narrowed}
              storeProzess={currentProzess}
            />
          )}
        </div>
        <Button variant='solid' disabled={!subFormIsValid || !isValid}>
          Übernehmen
        </Button>
      </Form>
    </Sidebar>
  );
};
