import {motion} from 'framer-motion';
import {type FC, useEffect, useState} from 'react';

import HannoverBackground from '../../../assets/hannover-background.png';
import HannoverLogo from '../../../assets/hannover-logo.svg';
import HaPyBackground from '../../../assets/hapy-background.png';
import HaPyLogo from '../../../assets/hapy-logo.png';

type KommuneSlide = {
  kommuneLogo: string;
  backgroundImage: string;
};

const slides: KommuneSlide[] = [
  {
    kommuneLogo: HaPyLogo,
    backgroundImage: HaPyBackground,
  },
  {
    kommuneLogo: HannoverLogo,
    backgroundImage: HannoverBackground,
  },
];

const nextSlideIndex = (currentIndex: number) =>
  (currentIndex + 1) % slides.length;

const Slide: FC<KommuneSlide & {kind: 'animate-out' | 'animate-in'}> = ({
  kommuneLogo,
  backgroundImage,
  kind,
}) => (
  <motion.div
    transition={{duration: 0.5, type: 'tween', ease: 'easeInOut'}}
    initial={{x: kind === 'animate-out' ? 0 : '100%'}}
    animate={{x: kind === 'animate-out' ? '-100%' : 0}}
    className='absolute size-full @container'
    key={kommuneLogo}
  >
    <img className='h-full object-cover' src={backgroundImage} alt='' />

    <div className='absolute bottom-20 left-1/2 flex h-36 w-5/6 -translate-x-1/2 items-center justify-between gap-6 rounded-lg bg-white px-12 py-8 @6xl:w-3/5'>
      <h2 className='text-4xl font-bold text-gray-700'>
        Wir sind schon dabei!
      </h2>

      <div className='w-[150px] shrink-0'>
        <img src={kommuneLogo} alt='Logo der Kommune' className='ml-auto' />
      </div>
    </div>
  </motion.div>
);

export const SlidingKommuneDisplay: FC = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setSlideIndex(nextSlideIndex), 5_000);

    return () => clearInterval(interval);
  }, []);

  const currentSlide = slides[slideIndex];
  const nextSlide = slides[nextSlideIndex(slideIndex)];

  return (
    <div className='relative size-full overflow-hidden'>
      <Slide {...currentSlide} kind='animate-out' />
      <Slide {...nextSlide} kind='animate-in' />
    </div>
  );
};
