import {type FormBuilder} from '@atmina/formbuilder';
import {type FC, useMemo} from 'react';
import {SelectFormField} from '../../../../../components/form/fields/select-field';
import {useMitarbeiterGruppenQuery} from '../../prozess-editor.generated.ts';

export type BearbeitendeGruppeSelectProps = {
  on: FormBuilder<string>;
};

export const BearbeitendeGruppeSelect: FC<BearbeitendeGruppeSelectProps> = ({
  on,
}) => {
  const [{data}] = useMitarbeiterGruppenQuery();
  const gruppen = useMemo(() => {
    if (data?.my.__typename !== 'BackofficeUser') return [];

    return data.my.kommune.mitarbeiterGruppen;
  }, [data]);

  const items = useMemo(
    () => gruppen.map((g) => ({name: g.name, value: g.id})),
    [gruppen],
  );
  const nichtZugeordnet = {name: 'Nicht zugeordnet', value: null};

  return (
    <SelectFormField
      on={on}
      label='Zugewiesen zu'
      items={[nichtZugeordnet, ...items]}
    />
  );
};
