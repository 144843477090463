import {format, parseJSON} from 'date-fns';
import {type FC, useState} from 'react';
import {FaRegEdit} from 'react-icons/fa';
import {MdChevronLeft} from 'react-icons/md';
import {NavLink} from 'react-router-dom';
import {TableVirtuoso} from 'react-virtuoso';
import {twMerge} from 'tailwind-merge';
import {useAsyncMemo} from '../../../lib/hooks/use-async-memo.ts';
import {useCryptoCache} from '../../../lib/hooks/use-crypto-cache.ts';
import {useDecryptedKey} from '../../../lib/hooks/use-decrypted-key.ts';
import {type AusfuehrungFragment} from '../ausfuehrungen.generated.ts';

export type AusfuehrungenListProps = {
  ausfuehrungen: AusfuehrungFragment[];
  className?: string;
};

export const AusfuehrungenList = ({
  ausfuehrungen,
  className,
}: AusfuehrungenListProps) => {
  const [filteredAusfuehrungen, setFilteredAusfuehrungen] =
    useState<AusfuehrungFragment[]>(ausfuehrungen);

  const filterByAntragsArt = (antragsArt: string) => {
    if (!antragsArt) {
      setFilteredAusfuehrungen(ausfuehrungen);
    } else {
      setFilteredAusfuehrungen(
        ausfuehrungen.filter(
          (ausfuehrung) =>
            ausfuehrung.prozessVersion.prozess.name === antragsArt,
        ),
      );
    }
  };

  const antragsArten = [
    ...new Set(
      ausfuehrungen.map(
        (ausfuehrung) => ausfuehrung.prozessVersion.prozess.name,
      ),
    ),
  ];

  return (
    <div className={twMerge('flex h-full flex-col gap-6', className)}>
      <div className='flex justify-between'>
        <p className='rounded-md border border-gray-300 p-2'>
          Hier kommt die Suche hin
        </p>
        <label className='relative min-w-[12rem] rounded-md border border-gray-300'>
          <span className='absolute -top-2 left-2 bg-white text-xs'>
            Nach Antragsart filtern
          </span>
          <div className='flex items-center'>
            <select
              className='w-full appearance-none p-2'
              onChange={(e) => filterByAntragsArt(e.currentTarget.value)}
            >
              <option value=''>Alle</option>
              {antragsArten.map((antragsArt) => (
                <option key={antragsArt} value={antragsArt}>
                  {antragsArt}
                </option>
              ))}
            </select>
            <MdChevronLeft className='inline-block -rotate-90' />
          </div>
        </label>
      </div>
      <TableVirtuoso
        className='w-full flex-1 table-fixed'
        data={filteredAusfuehrungen ?? []}
        components={{
          Table: ({...props}) => (
            <table
              {...props}
              className='min-w-[900px] table-fixed overflow-x-auto lg:w-full'
            />
          ),
          TableRow: ({...props}) => (
            <tr
              {...props}
              className={twMerge(
                'border-t border-gray-400',
                props['data-index'] % 2 == 0 && 'bg-gray-100',
                props['data-index'] % 2 == 1 && 'bg-white',
              )}
            />
          ),
        }}
        fixedHeaderContent={() => (
          <tr className='bg-white'>
            <th className='w-48 p-4 text-left'>Antragseingang</th>
            <th className='p-4 text-left'>Antragsart</th>
            <th className='p-4 text-left'>Beschreibungstext</th>
            <th className='p-4 text-left'>Verantwortliche Gruppe</th>
            <th className='w-48 p-4' />
          </tr>
        )}
        itemContent={(_, ausfuehrung) => (
          <OverviewLine key={ausfuehrung.id} ausfuehrung={ausfuehrung} />
        )}
      />
    </div>
  );
};

const OverviewLine: FC<{
  ausfuehrung: AusfuehrungFragment;
}> = ({ausfuehrung}) => {
  const antragsArt = ausfuehrung.prozessVersion.prozess.name;

  const cryptoCache = useCryptoCache();
  const decryptionKey = useDecryptedKey(
    ausfuehrung.sharedKeyEncryptedForKommune,
    cryptoCache,
  );

  const slots = useAsyncMemo(async () => {
    const map: Record<string, unknown> = {};

    if (!decryptionKey) return map;

    for (const slotValue of ausfuehrung.beschreibungstextSlotValues) {
      if (slotValue.__typename === 'PlainSlotValue') {
        map[slotValue.slotName] = slotValue.plainValue;
      } else if (slotValue.__typename === 'EncryptedSlotValue') {
        map[slotValue.slotName] = await cryptoCache.getDecryptedValueCached(
          decryptionKey,
          slotValue.encryptedValue,
        );
      }
    }

    return map;
  }, [decryptionKey]);

  if (!slots) return null;

  return (
    <>
      <td className='p-4'>
        {format(parseJSON(ausfuehrung.createdAt), 'dd.MM.yyyy')}
      </td>
      <td className='break-words p-4'>{antragsArt}</td>
      <td className='break-words p-4'>
        {ausfuehrung.prozessVersion.beschreibungstext.evaluate(slots)}
      </td>
      <td className='break-words p-4'>
        {ausfuehrung.currentSchritt.mitarbeiterGruppe?.name ?? 'Bürger'}
      </td>

      <td className='w-full p-4'>
        <NavLink
          to={`/antraege/${ausfuehrung.id}`}
          type='button'
          className='flex w-full items-center justify-start font-bold text-primary'
        >
          <FaRegEdit className='mr-4 inline-block' />
          <span>Bearbeiten</span>
        </NavLink>
      </td>
    </>
  );
};
