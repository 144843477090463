import {type StyleSpecification} from 'maplibre-gl';
const mapFont = ['Noto Sans Regular'];
const gebaeudeColor = '#d9d9d9';

export const mapTheme: StyleSpecification = {
  bearing: 0,
  center: [9.7424, 52.3739],
  glyphs:
    'https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/fonts/{fontstack}/{range}.pbf',
  layers: [
    {
      filter: [
        'in',
        'klasse',
        'Baumschule',
        'Gartenland',
        'Gras',
        'Grünland',
        'Hecke',
        'Heide',
        'Hopfen',
        'Landwirtschaft',
        'Laubbaum',
        'Laubholz',
        'Nadelholz',
        'Laub- und Nadelholz',
        'Obstplantage',
        'Streuobst',
        'Streuobstacker',
        'Streuobstwiese',
        'Wald',
        'Weingarten',
        'Baumbestand, Laub- und Nadelholz',
        'Baumbestand, Laubholz',
        'Baumbestand, Nadelholz',
      ],
      id: 'landuse_greens',
      paint: {
        'fill-color': '#d2edae',
      },
      source: 'smarttiles_de',
      'source-layer': 'Vegetationsflaeche',
      type: 'fill',
    },
    {
      filter: ['in', 'klasse', 'Fels', 'Geröll', 'Steine, Schotter'],
      id: 'rocky_terrain',
      paint: {
        'fill-color': '#848884',
      },
      source: 'smarttiles_de',
      'source-layer': 'Vegetationsflaeche',
      type: 'fill',
    },
    {
      filter: ['all', ['==', '$type', 'LineString']],
      id: 'waterway',
      paint: {
        'line-color': '#a0cfdf',
        'line-width': ['step', ['zoom'], 1.4, 8, 0.5, 20, 15],
      },
      source: 'smarttiles_de',
      'source-layer': 'Gewaesserlinie',
      type: 'line',
    },
    {
      id: 'water',
      paint: {
        'fill-color': '#a0cfdf',
      },
      source: 'smarttiles_de',
      'source-layer': 'Gewaesserflaeche',
      type: 'fill',
    },
    {
      id: 'area-verkehr',
      paint: {
        'fill-color': '#FFFFFF',
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrsflaeche',
      type: 'fill',
    },
    {
      id: 'building-gebaeude',
      paint: {
        'fill-color': '#f8f9fa',
        'fill-outline-color': '#2e2e2e',
      },
      source: 'smarttiles_de',
      'source-layer': 'Gebaeudeflaeche',
      type: 'fill',
    },
    {
      id: 'building-bauwerk',
      paint: {
        'fill-color': '#f8f9fa',
        'fill-outline-color': '#2e2e2e',
      },
      source: 'smarttiles_de',
      'source-layer': 'Bauwerksflaeche',
      type: 'fill',
    },
    {
      id: 'building-siedlung',
      paint: {
        'fill-color': '#F8F9FA',
        'fill-outline-color': '#2e2e2e',
      },
      source: 'smarttiles_de',
      'source-layer': 'Siedlungsflaeche',
      type: 'fill',
    },
    {
      id: 'Gebaeude2D_alle',
      paint: {
        'fill-color': '#F1F3F4',
        'fill-outline-color': gebaeudeColor,
      },
      source: 'smarttiles_de',
      'source-layer': 'Gebaeudeflaeche',
      type: 'fill',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Gleis',
            'Fußweg',
            'Radweg',
            'Rad- und Fußweg',
            'Weg, Pfad, Steig',
            'Hauptwirtschaftsweg',
          ],
          ['==', 'bauwerk', 'Tunnel, Unterführung'],
        ],
      ],
      id: 'tunnel_minor',
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#efefef',
        'line-dasharray': [0.36, 0.18],
        'line-width': ['step', ['zoom'], 1.55, 4, 0.25, 20, 30],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Bundesautobahn',
            'Bundesstraße',
            'Kreisstraße',
            'Gemeindestraße',
          ],
          ['==', 'bauwerk', 'Tunnel, Unterführung'],
        ],
      ],
      id: 'tunnel_major',
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#fff',
        'line-dasharray': [0.28, 0.14],
        'line-width': ['step', ['zoom'], 1.4, 6, 0.5, 20, 30],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Gleis',
            'Fußweg',
            'Radweg',
            'Rad- und Fußweg',
            'Weg, Pfad, Steig',
            'Hauptwirtschaftsweg',
          ],
          ['any', ['!has', 'bauwerk'], ['in', 'bauwerk', 'Steg', 'Wehr']],
        ],
      ],
      id: 'road_minor',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#efefef',
        'line-width': ['step', ['zoom'], 1.55, 4, 0.25, 20, 30],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['in', 'klasse', 'Eisenbahn', 'S-Bahn', 'Stadtbahn', 'U-Bahn'],
      ],
      id: 'track',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#9e9e9e',
        'line-width': ['step', ['zoom'], 1.4, 4, 0.25, 20, 10],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Bundesautobahn',
            'Bundesstraße',
            'Kreisstraße',
            'Gemeindestraße',
          ],
          ['any', ['!has', 'bauwerk'], ['in', 'bauwerk', 'Steg', 'Wehr']],
        ],
      ],
      id: 'road_major',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-width': ['step', ['zoom'], 1.4, 6, 0.5, 20, 30],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Gleis',
            'Fußweg',
            'Radweg',
            'Rad- und Fußweg',
            'Weg, Pfad, Steig',
            'Hauptwirtschaftsweg',
          ],
          [
            'in',
            'bauwerk',
            'Brücke',
            'Drehbrücke',
            'Hebebrücke',
            'Hängebrücke',
            'Pontonbrücke',
            'Zugbrücke',
            'Mehrstöckige Brücke',
          ],
        ],
      ],
      id: 'bridge_minor',
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#dedede',
        'line-gap-width': ['step', ['zoom'], 1.55, 4, 0.25, 20, 30],
        'line-width': ['step', ['zoom'], 1.6, 12, 0.5, 20, 10],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'klasse',
            'Bundesautobahn',
            'Bundesstraße',
            'Kreisstraße',
            'Gemeindestraße',
          ],
          [
            'in',
            'bauwerk',
            'Brücke',
            'Drehbrücke',
            'Hebebrücke',
            'Hängebrücke',
            'Pontonbrücke',
            'Zugbrücke',
            'Mehrstöckige Brücke',
          ],
        ],
      ],
      id: 'bridge_major',
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#dedede',
        'line-gap-width': ['step', ['zoom'], 1.55, 4, 0.25, 20, 30],
        'line-width': ['step', ['zoom'], 1.6, 12, 0.5, 20, 10],
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'line',
    },
    {
      filter: ['all', ['==', '$type', 'Point']],
      id: 'poi_label',
      layout: {
        'icon-image': '{maki}-11',
        'icon-size': 1,
        'text-anchor': 'top',
        'text-field': '{name}',
        'text-font': mapFont,
        'text-max-width': 8,
        'text-offset': [0, 0.5],
        'text-size': 11,
      },
      minzoom: 5,
      paint: {
        'text-color': '#666',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-width': 1,
      },
      source: 'smarttiles_de',
      'source-layer': 'Besonderer_Punkt',
      type: 'symbol',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'in',
          'klasse',
          'Bundesautobahn',
          'Bundesstraße',
          'Kreisstraße',
          'Gemeindestraße',
        ],
      ],
      id: 'road_major_label',
      layout: {
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': mapFont,
        'text-letter-spacing': 0.1,
        'text-size': ['step', ['zoom'], 1.4, 10, 8, 20, 14],
        'text-transform': 'uppercase',
      },
      paint: {
        'text-color': '#666',
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-width': 2,
      },
      source: 'smarttiles_de',
      'source-layer': 'Verkehrslinie',
      type: 'symbol',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['any', ['in', 'klasse', 'Insel', 'Ort']],
        ['in', 'art', 'Kreis', 'Stadtteil', 'Gemeinde', 'Gemeindeteil'],
      ],
      id: 'place_label_other',
      layout: {
        'text-field': '{name}',
        'text-font': mapFont,
        'text-max-width': 6,
        'text-rotation-alignment': 'viewport',
        'text-size': ['step', 6, 12, 12, 16],
      },
      minzoom: 8,
      paint: {
        'text-color': '#666',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-width': 1,
      },
      source: 'smarttiles_de',
      'source-layer': 'Name_Punkt',
      type: 'symbol',
    },
    {
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['in', 'art', 'Stadt', 'Landeshauptstadt'],
      ],
      id: 'place_label_city',
      layout: {
        'text-field': '{name}',
        'text-font': mapFont,
        'text-max-width': 10,
        'text-rotation-alignment': 'viewport',
        'text-size': ['step', 3, 12, 8, 16],
      },
      maxzoom: 16,
      paint: {
        'text-color': '#666',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-width': 1,
      },
      source: 'smarttiles_de',
      'source-layer': 'Name_Punkt',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', '$type', 'Point'], ['==', 'art', 'Staat']],
      id: 'country_label',
      layout: {
        'text-field': '{name}',
        'text-font': mapFont,
        'text-max-width': 10,
        'text-rotation-alignment': 'viewport',
        'text-size': ['step', 3, 14, 8, 22],
      },
      maxzoom: 12,
      paint: {
        'text-color': '#666',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-width': 1,
      },
      source: 'smarttiles_de',
      'source-layer': 'Name_Punkt',
      type: 'symbol',
    },
  ],
  metadata: {
    'basemapde:style-version': 3.1,
    'mapbox:autocomposite': false,
    'mapbox:type': 'template',
    'maputnik:renderer': 'mbgljs',
  },
  name: 'bm_web_col',
  pitch: 0,
  sources: {
    smarttiles_de: {
      attribution:
        '&copy; 2023 basemap.de / BKG | Datenquellen: © GeoBasis-DE',
      type: 'vector',
      url: 'https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/tiles/v1/bm_web_de_3857/bm_web_de_3857.json',
    },
  },
  sprite:
    'https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/sprites/bm_web_col_sprite',
  version: 8,
  zoom: 26,
};
