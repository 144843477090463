import {useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {Outlet} from 'react-router-dom';
import {useAuth} from '../../../lib/context/auth/auth.tsx';
import {KommuneProvider} from '../../../lib/context/kommune/kommune.tsx';
import {OffScreenWrapper} from '../../offscreen.tsx';
import {useLoggedInLayoutQuery} from './logged-in-layout.generated.ts';
import {DesktopNavigation} from './navigation/desktop-navigation/desktop-navigation.tsx';
import {MobileNavigationToggle} from './navigation/mobile-navigation/mobile-navigation-toggle.tsx';
import {MobileNavigation} from './navigation/mobile-navigation/mobile-navigation.tsx';

export const LoggedInLayout = () => {
  const {user} = useAuth();
  const context = useMemo(() => ({additionalTypenames: ['Ausfuehrung']}), []);
  const [{data: {my} = {my: {}}}] = useLoggedInLayoutQuery({
    pause: !user,
    context,
  });

  if (!user || !('kommune' in user)) {
    return null;
  }

  const {
    neu = 0,
    inBearbeitung = 0,
    abgeschlossen = 0,
  } = 'assignedAusfuehrungenCount' in my ? my.assignedAusfuehrungenCount : {};

  return (
    <KommuneProvider kommuneId={user.kommune.id}>
      <Helmet>
        <title>Anträge</title>
      </Helmet>
      <OffScreenWrapper
        offScreen={
          <MobileNavigation
            ausfuehrungenNeuCount={neu}
            ausfuehrungenInBearbeitungCount={inBearbeitung}
            ausfuehrungenAbgeschlossenCount={abgeschlossen}
          />
        }
        offScreenWidth={300}
      >
        <div className='flex h-screen flex-col overflow-hidden print:overflow-visible'>
          <DesktopNavigation
            ausfuehrungenNeuCount={neu}
            ausfuehrungenInBearbeitungCount={inBearbeitung}
            ausfuehrungenAbgeschlossenCount={abgeschlossen}
          />
          <MobileNavigationToggle />
          <div
            id='main-content'
            className='relative flex-1 overflow-y-auto overflow-x-hidden print:overflow-visible'
          >
            <Outlet />
          </div>
          <div className='flex justify-center gap-x-4 px-4 py-2 text-gray-400 print:hidden'>
            <a href='/impressum' rel='noreferrer noopener' target='_blank'>
              Impressum
            </a>
            <a href='/datenschutz' rel='noreferrer noopener' target='_blank'>
              Datenschutzerklärung
            </a>
          </div>
        </div>
      </OffScreenWrapper>
    </KommuneProvider>
  );
};
