import type {FormBuilder} from '@atmina/formbuilder';
import {type SchrittDaten} from '../../lib/store/prozess-aenderungen.ts';
import {FeldHinzufuegenButton} from './feld-hinzufuegen-button.tsx';
import {FormCard} from './form-card.tsx';
import {ProzessEditorFormFeld} from './form-feld.tsx';

export type FormularSchritt = Extract<
  SchrittDaten,
  {__typename: 'FormularSchritt'}
>;
export type FormularSchrittFormFelderType = FormularSchritt['felder'][0];

export const FormularSchrittForm = ({
  on,
}: {
  on: FormBuilder<FormularSchritt>;
}) => {
  const {fields, append, remove} = on.felder.$useFieldArray();
  return (
    <div className='space-y-6 '>
      <div className='sticky -top-4 z-10 flex items-center space-x-4 bg-white font-bold'>
        <h2>Felder</h2>
        <hr className='h-px w-full' />
        <FeldHinzufuegenButton onAdd={append} />
      </div>
      {fields.map((feld, index) => (
        <FormCard key={feld.$key}>
          <ProzessEditorFormFeld on={feld} onRemove={() => remove(index)} />
        </FormCard>
      ))}
    </div>
  );
};
