import {type StateCreator} from 'zustand';
import {
  type ProzessStore,
  type SchrittDaten,
  type VerbindungDaten,
} from './prozess-aenderungen.ts';

export type EditorSelectionStore = {
  selectedSchritt: SchrittDaten | null;
  selectSchritt: (selectSchritt: SchrittDaten | null) => void;

  selectedVerbindung: VerbindungDaten | null;
  selectVerbindung: (verbindung: VerbindungDaten | null) => void;
};

export const createEditorSelectionSlice: StateCreator<
  EditorSelectionStore & ProzessStore,
  [['zustand/immer', never]],
  [],
  EditorSelectionStore
> = (set) => ({
  selectedSchritt: null,
  selectSchritt: (newSelection) =>
    set((state) => {
      state.selectedSchritt = newSelection;
    }),

  selectedVerbindung: null,
  selectVerbindung: (newSelection) =>
    set((state) => {
      state.selectedVerbindung = newSelection;
    }),
});
