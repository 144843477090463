import * as Dialog from '@radix-ui/react-dialog';
import {Cross2Icon} from '@radix-ui/react-icons';
import {type FC, type ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export type ModalProps = {
  title: string;
  description?: string;
  children: ReactNode;
  className?: string;
  global?: boolean;
};

export const Modal: FC<ModalProps> = ({
  title,
  children,
  className,
  description,
  global = false,
}) => {
  const mainContent: HTMLElement | undefined | null = global
    ? undefined
    : document.getElementById('main-content');

  return (
    <Dialog.Portal container={mainContent}>
      <Dialog.Overlay className='icy-backdrop absolute inset-0 z-10 data-[state=open]:animate-overlayShow '>
        <Dialog.Content
          className={twMerge(
            'relative mx-auto mt-32 max-h-fit min-w-24 max-w-5xl overflow-hidden rounded-xl border-2 border-neutral-300 bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
            className,
          )}
        >
          <Dialog.Title className='text-mauve12 m-0 rounded-t-xl border-b-[1px] border-neutral-300 bg-neutral-100 px-5 py-3 text-[17px] font-medium'>
            {title}
          </Dialog.Title>
          <div className='h-full max-h-[50vh] overflow-y-auto px-5 py-3'>
            {description && (
              <Dialog.Description className='text-mauve11 mb-5 mt-[10px] text-[15px] leading-normal'>
                {description}
              </Dialog.Description>
            )}
            {children}
            <Dialog.Close asChild>
              <button
                className='absolute right-[10px] top-[10px] inline-flex size-[25px] appearance-none items-center justify-center rounded-full focus:outline-none'
                aria-label='Close'
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};
