import {
  type ATNSimulator,
  BaseErrorListener,
  type RecognitionException,
  type Recognizer,
} from 'antlr4ng';

export class ExpressionErrorListener extends BaseErrorListener {
  syntaxError<T extends ATNSimulator>(
    _recognizer: Recognizer<T> | null,
    _offendingSymbol: unknown,
    _line: number,
    _column: number,
    msg: string,
    _e: RecognitionException | null,
  ): void {
    throw msg;
  }
}
