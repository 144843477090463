import {Color} from '@jgleman/color-box';
import {
  createContext,
  type CSSProperties,
  type ReactNode,
  useContext,
  useEffect,
} from 'react';
import {type Kommune, useKommuneQuery} from './kommune.generated.ts';

type KommuneProviderProps = {
  children?: ReactNode;
} & (
  | {
      kommuneId: string;
    }
  | {kommune: Kommune}
);

type KommuneContextValue = {
  id: string;
  name: string;
  logoUrl?: string;
};

const KommuneContext = createContext<KommuneContextValue | null>(null);

export const KommuneProvider = ({children, ...props}: KommuneProviderProps) => {
  const kommuneId = 'kommuneId' in props ? props.kommuneId : null;
  const [{data}] = useKommuneQuery({
    variables: {kommuneId: kommuneId ?? ''},
    pause: !kommuneId,
  });
  const kommune = 'kommune' in props ? props.kommune : data?.kommune;
  const {name, design} = kommune ?? {};
  const {colors, logo} = design ?? {};

  useEffect(() => {
    const oldStyle: Record<string, CSSProperties[keyof CSSProperties]> = {};
    const newStyle = createCssVariables(colors ?? {});

    for (const key in newStyle) {
      const newValue = newStyle[key as keyof CSSProperties];
      oldStyle[key] = newValue;
      document.documentElement.style.setProperty(
        key,
        newValue?.toString() ?? null,
      );
    }
  }, [colors]);

  if (!kommune || !name) return null;
  return (
    <KommuneContext.Provider
      value={{id: kommune.id, logoUrl: logo?.url ?? undefined, name}}
    >
      {children}
    </KommuneContext.Provider>
  );
};

export const useKommune = () => {
  const kommune = useContext(KommuneContext);
  if (!kommune) {
    throw new Error('Missing KommuneContext');
  }
  return kommune;
};
const createCssVariables = (colors: Record<string, string | null>) =>
  Object.fromEntries(
    Object.entries(colors ?? {})
      .filter(([k]) => !k.startsWith('_'))
      .map(([key, value]) => [
        `--color-${kebabCase(key)}`,
        toNakedRgb(value ?? '#000'),
      ]),
  ) as CSSProperties;
const kebabCase = (s: string) =>
  s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const toNakedRgb = (hex: string) => {
  const {
    rgb: {r, g, b},
  } = new Color(hex);
  return `${r} ${g} ${b}`;
};
