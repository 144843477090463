import * as Dialog from '@radix-ui/react-dialog';
import {useState, type FC} from 'react';
import {Modal} from '../../../components/modal/modal.tsx';
import {Button} from '../../../components/ui/button/index.ts';
import {
  EditableTextInput,
  type EditableTextInputProps,
} from '../components/editable-text-input.tsx';
import {UntereinheitenVerwalten} from '../untereinheiten/untereinheiten-verwalten.tsx';
import {
  useAddUserGroupMutation,
  useDeleteUserGroupMutation,
  useUpdateUserGroupMutation,
} from './mitarbeitergruppen.generated.ts';
import {
  type UserGroupUpdateErrorFragment,
  type UserGroupDeleteErrorFragment,
} from './nutzende.generated.ts';

export type GruppenVerwaltenProps = {
  gruppen: EditableTextInputProps['item'][];
  onSaveDeleteOrUpdate: () => void;
};

type GruppenErrorType =
  | UserGroupUpdateErrorFragment['__typename']
  | UserGroupDeleteErrorFragment['__typename'];
export const GruppenVerwalten: FC<GruppenVerwaltenProps> = ({
  gruppen,
  onSaveDeleteOrUpdate,
}) => {
  const [, addUserGroupMutation] = useAddUserGroupMutation();
  const [, updateUserGroupMutation] = useUpdateUserGroupMutation();
  const [, deleteUserGroupMutation] = useDeleteUserGroupMutation();
  const [errors, setError] = useState<Record<string, GruppenErrorType>>({});

  const errorMap: Record<GruppenErrorType, string> = {
    GroupAssignedToSchritt:
      'Die Gruppe ist einem Prozessschritt zugeordnet und kann deshalb nicht gelöscht werden.',
    GroupNotFound:
      'Die Gruppe existiert nicht und konnte deshalb nicht gelöscht werden.',
    GroupNameInvalid: 'Gruppen Name nicht gültig',
  };

  const maybeSetError = (
    id: string,
    maybeError?: {__typename: GruppenErrorType},
  ) => {
    if (maybeError) {
      setError((prev) => {
        prev[id] = maybeError.__typename;
        return prev;
      });
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant='outlined'>Gruppen & Untereinheiten verwalten</Button>
      </Dialog.Trigger>
      <Modal title='Gruppen verwalten'>
        <div className='flex flex-col'>
          <p className='font-bold'>Gruppen:</p>
          {gruppen.map((g) => (
            <EditableTextInput
              key={g.id}
              onUpdate={async (name: string) => {
                const result = await updateUserGroupMutation({
                  input: {id: g.id, name: name},
                });
                const maybeError = result.data?.updateUserGroup.errors?.[0];
                maybeSetError(g.id, maybeError);
                onSaveDeleteOrUpdate();
              }}
              onDelete={async () => {
                const result = await deleteUserGroupMutation({
                  input: {id: g.id},
                });
                const maybeError = result.data?.deleteUserGroup.errors?.[0];
                maybeSetError(g.id, maybeError);
                onSaveDeleteOrUpdate();
              }}
              error={errors[g.id] && errorMap[errors[g.id]]}
              item={g}
            />
          ))}

          <Button
            className='ml-auto mt-3 w-64 self-center'
            variant='outlined'
            onClick={() => {
              addUserGroupMutation({
                input: {
                  name: 'Neue Gruppe',
                },
              });
              onSaveDeleteOrUpdate();
            }}
          >
            + Neue Gruppe erstellen
          </Button>
          <UntereinheitenVerwalten />
        </div>
      </Modal>
    </Dialog.Root>
  );
};
