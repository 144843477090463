import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import {type CryptoCache} from '../../hooks/use-crypto-cache.ts';

type CryptoContextData = {
  decryptionKey?: CryptoKey;
  cryptoCache?: CryptoCache;
};

const CryptoContext = createContext<CryptoContextData>({
  decryptionKey: undefined,
  cryptoCache: undefined,
});

export type CryptoProviderProps = PropsWithChildren<{
  decryptionKey: CryptoKey;
  cryptoCache: CryptoCache;
}>;

export const CryptoProvider: FC<CryptoProviderProps> = ({
  decryptionKey,
  cryptoCache,
  children,
}) => {
  const value = useMemo(
    () => ({decryptionKey, cryptoCache}),
    [decryptionKey, cryptoCache],
  );

  return (
    <CryptoContext.Provider value={value}>{children}</CryptoContext.Provider>
  );
};

export const useCrypto = () => {
  const context = useContext(CryptoContext);

  if (!context) throw new Error('useCrypto used outside of CryptoProvider');

  return context;
};
