import {type RouteObject} from 'react-router-dom';
import {AbgeschlosseneAntraege} from './abgeschlossen/abgeschlossen.tsx';
import {AntraegeInBearbeitung} from './antraege-in-bearbeitung/antraege-in-bearbeitung.tsx';
import {AntragDetail} from './detail/detail.tsx';
import {NeueAntraege} from './neu/neu.tsx';

export const antraegeRoute: RouteObject = {
  path: 'antraege',
  children: [
    {
      path: 'neu',
      element: <NeueAntraege />,
    },
    {
      path: 'in-bearbeitung',
      element: <AntraegeInBearbeitung />,
    },
    {
      path: 'abgeschlossen',
      element: <AbgeschlosseneAntraege />,
    },
    {
      path: ':id',
      element: <AntragDetail />,
    },
  ],
};
