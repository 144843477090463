import type {RouteObject} from 'react-router-dom';
import {KommuneAdminPolicy} from 'src/components/kommune-admin-policy.tsx';
import {Nutzende} from './nutzende/nutzende.tsx';

export const nutzendenverwaltungRoute: RouteObject = {
  path: 'nutzendenverwaltung',
  children: [
    {
      index: true,
      element: (
        <KommuneAdminPolicy>
          <Nutzende />
        </KommuneAdminPolicy>
      ),
    },
  ],
};
