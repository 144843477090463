import {type FC} from 'react';
import {FaFilePdf} from 'react-icons/fa';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {type AntragDetailAusfuehrung} from '../../detail.generated.ts';

const guessIsPdf = (data: string) => data.startsWith('JVBERi0');

export type UploadFormularFeldProps = {
  slotName: string;
  anweisung?: string;
  ausfuehrung: AntragDetailAusfuehrung;
};

export const UploadFormularFeld: FC<UploadFormularFeldProps> = ({
  slotName,
  anweisung,
  ausfuehrung,
}) => (
  <div className='flex gap-4'>
    <div className='flex max-w-[50%] flex-col gap-4'>
      <SlotValue<string>
        value={
          ausfuehrung.slotValues.find((sv) => sv.slotName === slotName) ?? ''
        }
      >
        {(value) =>
          guessIsPdf(value) ? (
            <a
              download='document.pdf'
              href={'data:application/pdf;base64,' + value}
              className='flex min-h-20 w-full items-center justify-center border border-black hover:cursor-pointer'
            >
              <FaFilePdf className='size-12 text-red-600' />
            </a>
          ) : (
            <img
              alt=''
              src={'data:image/jpg;base64,' + value}
              className='max-h-96 border border-black'
            />
          )
        }
      </SlotValue>
    </div>
    <p className='min-h-[4rem]'>{anweisung}</p>
  </div>
);
