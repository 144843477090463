import {type RouteObject} from 'react-router-dom';
import {KommuneAdminPolicy} from 'src/components/kommune-admin-policy.tsx';
import {Prozesse} from './prozesse/prozesse.tsx';

export const prozesseVerwaltenRoute: RouteObject = {
  path: 'prozessverwaltung',
  element: (
    <KommuneAdminPolicy>
      <Prozesse />
    </KommuneAdminPolicy>
  ),
};
