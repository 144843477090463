import * as Dialog from '@radix-ui/react-dialog';
import {type FC, useCallback, useState} from 'react';
import {DropdownMenu} from '../../../../../components/ui/dropdown-menu/dropdown-menu.tsx';
import {ServicePortalLinkBearbeitenDialog} from './service-portal-link-bearbeiten-dialog.tsx';

type ServicePortalLinkAnlegenMenuItemProps = {
  onDismiss?: () => void;
};

export const ServicePortalLinkAnlegenMenuItem: FC<
  ServicePortalLinkAnlegenMenuItemProps
> = ({onDismiss}) => {
  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      setOpen(newOpen);
      if (!newOpen) {
        onDismiss?.();
      }
    },
    [onDismiss],
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        {/* https://github.com/radix-ui/primitives/issues/1836#issuecomment-1674338372 */}
        <DropdownMenu.Item onSelect={(e) => e.preventDefault()}>
          Service-Portal-Link
        </DropdownMenu.Item>
      </Dialog.Trigger>
      <ServicePortalLinkBearbeitenDialog
        id={null}
        onAfterSave={() => onOpenChange(false)}
      />
    </Dialog.Root>
  );
};
