import {
  type SlotValue_EncryptedSlotValue_Fragment,
  type SlotValue_PlainSlotValue_Fragment,
} from '../../components/ui/slot-value/slot-value.generated';
import {useAsyncMemo} from '../../lib/hooks/use-async-memo.ts';
import {type CryptoCache} from '../../lib/hooks/use-crypto-cache';

type SlotValues = Array<
  | ({
      __typename?: 'EncryptedSlotValue';
    } & SlotValue_EncryptedSlotValue_Fragment)
  | ({__typename?: 'PlainSlotValue'} & SlotValue_PlainSlotValue_Fragment)
>;

export const useDecryptedSlots = (
  cryptoCache: CryptoCache | undefined,
  decryptionKey: CryptoKey | undefined,
  slotValues: SlotValues | undefined,
) => {
  const slots = useAsyncMemo(async () => {
    const map: Record<string, unknown> = {};
    if (!cryptoCache || !decryptionKey || !slotValues) {
      return map;
    }

    for (const slotValue of slotValues) {
      if (slotValue.__typename === 'PlainSlotValue') {
        map[slotValue.slotName] = slotValue.plainValue;
      } else if (slotValue.__typename === 'EncryptedSlotValue') {
        map[slotValue.slotName] = await cryptoCache.getDecryptedValueCached(
          decryptionKey,
          slotValue.encryptedValue,
        );
      }
    }

    return map;
  }, [cryptoCache, decryptionKey, slotValues]);
  return slots;
};
