import {type WithUnparsedExpressions} from '@expressions/deep-unparse-expressions.ts';
import {BoolExpression} from '@expressions/expressions.ts';
import {type GovManagerRestExportSchritt} from '../components/form/govmanager-export-schritt.tsx';
import {type JsonValueWithSlotsFragment} from '../json-fragments.generated.ts';
import {
  type ProzessEditorFormularFeld_CheckboxListFormularFeld_Fragment,
  type ProzessEditorFormularFeld_StringFormularFeld_Fragment,
} from '../prozess-editor-formular-feld.generated.ts';
import {
  type CheckboxListFeldOptionInput,
  type CheckboxListFormularFeldInput,
  type FormularFeldInput,
  type FormularSchrittInput,
  type GovManagerRestExportSchrittInput,
  type JsonPartInput,
  type JsonValueWithSlotsInput,
  type PartInput,
  type ProzessEditorSchritt_FormularSchritt,
  type SchrittInput,
  type StringFormularFeldInput,
  type StringFormularFeldOptionInput,
  type VerbindungInput,
} from '../prozess-version-erstellen.generated.ts';
import type {
  SchrittDaten,
  VerbindungDaten,
} from './store/prozess-aenderungen.ts';

export const checkboxListOptionenZuInput = (
  option: ProzessEditorFormularFeld_CheckboxListFormularFeld_Fragment['options'][number],
  index: number,
): CheckboxListFeldOptionInput => {
  const {__typename, order, ...rest} = option;

  return {order: index, ...rest};
};

export const stringFeldOptionenZuInput = (
  option: ProzessEditorFormularFeld_StringFormularFeld_Fragment['options'][number],
): StringFormularFeldOptionInput => {
  const {__typename, ...rest} = option;

  return rest;
};

export const jsonZuInput = (
  jsonPart: JsonValueWithSlotsFragment,
): JsonValueWithSlotsInput => {
  const {__typename} = jsonPart;

  switch (__typename) {
    case 'JsonStringValue':
      return {jsonStringValueInput: {value: jsonPart.value}};
    case 'JsonSlotValue':
      return {jsonSlotValueInput: {slotName: jsonPart.slotName}};
    case 'JsonObject':
      return {
        jsonObjectInput: {
          fields: jsonPart.fields.map(({name, value}) => ({
            name,
            value: jsonZuInput(value as JsonValueWithSlotsFragment),
          })),
        },
      };
  }
};

export const partZuInput = (
  part: GovManagerRestExportSchritt['exportParts'][number],
): PartInput => {
  const {__typename, ...rest} = part;

  const targetKey = ((): keyof PartInput => {
    switch (__typename) {
      case 'FilePart':
        return 'filePartInput';
      case 'JsonPart':
        return 'jsonPartInput';
    }
  })();

  if (__typename === 'JsonPart') {
    const jsonInput = rest as unknown as JsonPartInput;
    jsonInput.value = jsonZuInput(part.value);
  }

  return {[targetKey]: rest} as unknown as PartInput;
};

export const feldZuInput = (
  feld: WithUnparsedExpressions<
    ProzessEditorSchritt_FormularSchritt['felder'][number]
  >,
  index: number,
): FormularFeldInput => {
  const {__typename, ...rest} = feld;

  const targetKey = ((): keyof FormularFeldInput => {
    switch (__typename) {
      case 'CheckboxListFormularFeld':
        return 'checkboxListFormularFeldInput';
      case 'DateFormularFeld':
        return 'dateFormularFeldInput';
      case 'TimeFormularFeld':
        return 'timeFormularFeldInput';
      case 'LocationFormularFeld':
        return 'locationFormularFeldInput';
      case 'StringFormularFeld':
        return 'stringFormularFeldInput';
      case 'UploadFormularFeld':
        return 'uploadFormularFeldInput';
      case 'SignatureFormularFeld':
        return 'signatureFormularFeldInput';
    }

    throw new Error(`Out of range ${__typename} for FormularFeldInput`);
  })();

  const input = rest as unknown as FormularFeldInput[keyof FormularFeldInput];
  input!.order = index;

  if (__typename === 'CheckboxListFormularFeld') {
    const checkboxFeld = input as CheckboxListFormularFeldInput;
    checkboxFeld.options = feld.options.map((option, index) =>
      checkboxListOptionenZuInput(option, index),
    );
  } else if (__typename === 'StringFormularFeld') {
    const stringFeld = input as StringFormularFeldInput;
    stringFeld.options = feld.options.map(stringFeldOptionenZuInput);
  }

  return {[targetKey]: rest} as unknown as FormularFeldInput;
};

export const schrittZuInput = (schritt: SchrittDaten): SchrittInput => {
  const {
    __typename,
    erledigungDurchBuerger,
    verbindungen,
    mitarbeiterGruppe,
    slots,
    ...rest
  } = schritt;

  const targetKey = ((): keyof SchrittInput => {
    switch (__typename) {
      case 'BezahlenSchritt':
        return 'bezahlenSchrittInput';
      case 'WarteAufBezahlungAbgeschlossenSchritt':
        return 'warteAufBezahlungAbgeschlossenSchrittInput';
      case 'FormularSchritt':
        return 'formularSchrittInput';
      case 'ProgrammatischerSchritt':
        return 'programmatischerSchrittInput';
      case 'EndeSchritt':
        return 'endeSchrittInput';
      case 'EmailForwarderExportSchritt':
        return 'emailForwardSchrittInput';
      case 'GovManagerRestExportSchritt':
        return 'govManagerRestExportSchrittInput';
      case 'WarteAufGovManagerSchritt':
        return 'warteAufGovManagerSchrittInput';
    }
  })();
  const input: SchrittInput[keyof SchrittInput] = {
    ...rest,
    mitarbeiterGruppeId: mitarbeiterGruppe?.id,
  };

  if (__typename === 'FormularSchritt') {
    const formularInput = input as FormularSchrittInput;
    formularInput.felder = schritt.felder.map((feld, index) =>
      feldZuInput(feld, index),
    );
  }

  if (__typename === 'GovManagerRestExportSchritt') {
    const exportSchrittInput = input as GovManagerRestExportSchrittInput;
    exportSchrittInput.exportParts = schritt.exportParts.map(partZuInput);
  }

  return {[targetKey]: input} as unknown as SchrittInput;
};

export const verbindungZuInput = (
  verbindung: VerbindungDaten,
): VerbindungInput => {
  const {__typename, istRelevant, ...rest} = verbindung;

  return {
    ...rest,
    istRelevant: new BoolExpression(istRelevant),
  };
};
