import {useMemo, useState} from 'react';
import {Button} from 'src/components/ui/button';
import {EditableTextInput} from '../components/editable-text-input';
import {
  type UntereinheitDeleteMutation,
  type UntereinheitUpdateMutation,
  useUntereinheitenQuery,
  useUntereinheitCreateMutation,
  useUntereinheitDeleteMutation,
  useUntereinheitUpdateMutation,
} from './untereinheit.generated';

type GruppenErrorType =
  | NonNullable<
      UntereinheitUpdateMutation['untereinheitUpdate']['errors']
    >[number]['__typename']
  | NonNullable<
      UntereinheitDeleteMutation['untereinheitDelete']['errors']
    >[number]['__typename'];

export const UntereinheitenVerwalten = () => {
  const context = useMemo(() => ({additionalTypenames: ['Untereinheit']}), []);
  const [{data}, refetch] = useUntereinheitenQuery({
    requestPolicy: 'network-only',
    context: context,
  });
  const untereinheiten =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.untereinheiten
      : [];

  const [, createUntereinheit] = useUntereinheitCreateMutation();
  const [, updateUntereinheit] = useUntereinheitUpdateMutation();
  const [, deleteUntereinheit] = useUntereinheitDeleteMutation();
  const [errors, setError] = useState<Record<string, GruppenErrorType>>({});

  const errorMap: Record<GruppenErrorType, string> = {
    UntereinheitNotFoundError:
      'Die Untereinheit existiert nicht und konnte deshalb nicht gelöscht werden.',
    UntereinheitAssignedToUser:
      'Die Untereinheit ist Nutzenden zugeordnet und kann deswegen nicht gelöscht werden.',
    UntereinheitIsGemeindeError:
      'Die Untereinheit ist eine Gemeinde und kann nicht bearbeitet oder gelöscht werden.',
  };

  const maybeSetError = (
    id: string,
    maybeError?: {__typename: GruppenErrorType},
  ) => {
    if (maybeError) {
      setError((prev) => {
        prev[id] = maybeError.__typename;
        return prev;
      });
    }
  };
  return (
    <div className='flex flex-col'>
      <p className='font-bold'>Untereinheiten:</p>
      {untereinheiten.map((u) => (
        <EditableTextInput
          key={u.id}
          onUpdate={async (name: string) => {
            const result = await updateUntereinheit({
              input: {untereinheitId: u.id, name: name},
            });
            const maybeError = result.data?.untereinheitUpdate.errors?.[0];
            maybeSetError(u.id, maybeError);
          }}
          onDelete={async () => {
            const result = await deleteUntereinheit({
              input: {untereinheitId: u.id},
            });
            const maybeError = result.data?.untereinheitDelete.errors?.[0];
            maybeSetError(u.id, maybeError);
            refetch();
          }}
          editable={!u.isGemeinde}
          error={errors[u.id] && errorMap[errors[u.id]]}
          item={u}
        />
      ))}

      <Button
        className='ml-auto mt-3 w-64 self-center'
        variant='outlined'
        onClick={async () => {
          await createUntereinheit({
            input: {name: 'Neue Untereinheit'},
          });
        }}
      >
        + Neue Untereinheit erstellen
      </Button>
    </div>
  );
};
