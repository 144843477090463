import {useFormBuilder} from '@atmina/formbuilder';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router';
import {type RouteObject, useSearchParams} from 'react-router-dom';
import {LoginResult} from '../../../__generated__/graphql.ts';
import {InputFormField} from '../../../components/form/fields/input-field';
import {PasswordInputFormField} from '../../../components/form/fields/password-input-field';
import {Form} from '../../../components/form/form.tsx';
import {Button} from '../../../components/ui/button';
import {useAuth} from '../../../lib/context/auth/auth.tsx';

type EmailPasswordForm = {
  email: string;
  password: string;
  twoFactorCode?: string;
};

export const Login = () => {
  const {isLoggedIn, passwordLogin} = useAuth();
  const [loginResult, setLoginResult] = useState<LoginResult | undefined>(
    undefined,
  );
  const builder = useFormBuilder<EmailPasswordForm>();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const redirectTo = params.get('redirect');
  const forceLogin = params.has('force');
  const to =
    redirectTo && redirectTo.startsWith('/') ? redirectTo : '/antraege/neu';

  const redirect = useCallback(
    (to: string) => {
      if (to.startsWith('/cms')) {
        // Can't use navigate() because it's not part of the Router.
        window.location.assign(new URL(to, window.location.href));
      } else {
        navigate(to);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (isLoggedIn && !forceLogin) {
      redirect(to);
    }
  }, [forceLogin, isLoggedIn, redirect, to]);

  const login = useCallback(
    async (form: EmailPasswordForm, withTwoFactorCode = true) => {
      if (!withTwoFactorCode) {
        builder.reset({
          ...form,
          twoFactorCode: '',
        });
      }

      const result = await passwordLogin(
        form.email,
        form.password,
        withTwoFactorCode ? form.twoFactorCode : undefined,
      );

      if (result !== LoginResult.Success) {
        setLoginResult(result);
      } else {
        redirect(to);
      }
    },
    [builder, passwordLogin, redirect, to],
  );

  const submit = useCallback(
    async (form: EmailPasswordForm) => {
      await login(form);
    },
    [login],
  );

  const errorMessage = useMemo(() => {
    if (loginResult !== undefined) {
      if (loginResult === LoginResult.WrongLogin) {
        return 'Entweder Nutzername oder Passwort sind falsch.';
      }
    }
  }, [loginResult]);

  return (
    <>
      <Helmet>
        <title>Anmelden</title>
      </Helmet>
      <div className='m-auto flex w-full max-w-md flex-col gap-4'>
        <h1 className='text-3xl font-bold text-gray-700'>
          Herzlich Willkommen!
        </h1>

        <Form builder={builder} onSubmit={submit}>
          <InputFormField
            label='E-Mail'
            on={builder.fields.email}
            type='email'
            autoComplete='email'
            rules={{
              required: true,
              validate: (email: string) => {
                if (email.trim() && !/^[\u{20}-\u{7F}]*@/gu.test(email)) {
                  return 'Bitte geben Sie eine gültige E-Mail Adresse ohne Sonderzeichen vor dem @ ein.';
                }
              },
            }}
            className='w-full'
          />
          <PasswordInputFormField
            label='Passwort'
            on={builder.fields.password}
            autoComplete='current-password'
            rules={{required: true}}
            className='w-full'
          />

          {errorMessage && (
            <p className='mt-2 text-sm text-primary'>{errorMessage}</p>
          )}

          <Button variant='outlined' className='mt-4 w-full'>
            Anmelden
          </Button>
        </Form>
      </div>
    </>
  );
};

export const loginRoute: RouteObject = {
  path: 'login',
  element: <Login />,
};
