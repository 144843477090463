import * as Types from '../../../__generated__/graphql';

import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddUserGroupInput = {
  name: string;
};

export type DeleteUserGroupInput = {
  id: string;
};

export type UpdateUserGroupInput = {
  id: string;
  name: string;
};

export type UpdateUserGroupMutationVariables = Types.Exact<{
  input: UpdateUserGroupInput;
}>;

export type UpdateUserGroupMutation = {
  __typename?: 'Mutation';
  updateUserGroup: {
    __typename?: 'UpdateUserGroupPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
    errors?: Array<{__typename: 'GroupNameInvalid'}> | null;
  };
};

export type DeleteUserGroupMutationVariables = Types.Exact<{
  input: DeleteUserGroupInput;
}>;

export type DeleteUserGroupMutation = {
  __typename?: 'Mutation';
  deleteUserGroup: {
    __typename: 'DeleteUserGroupPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
    errors?: Array<
      {__typename: 'GroupAssignedToSchritt'} | {__typename: 'GroupNotFound'}
    > | null;
  };
};

export type AddUserGroupMutationVariables = Types.Exact<{
  input: AddUserGroupInput;
}>;

export type AddUserGroupMutation = {
  __typename?: 'Mutation';
  addUserGroup: {
    __typename?: 'AddUserGroupPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
  };
};

export const UpdateUserGroupDocument = gql`
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      mitarbeiterGruppe {
        __typename
        id
        name
      }
      errors {
        __typename
      }
    }
  }
`;

export function useUpdateUserGroupMutation() {
  return Urql.useMutation<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >(UpdateUserGroupDocument);
}
export const DeleteUserGroupDocument = gql`
  mutation DeleteUserGroup($input: DeleteUserGroupInput!) {
    deleteUserGroup(input: $input) {
      __typename
      mitarbeiterGruppe {
        __typename
        id
        name
      }
      errors {
        __typename
      }
    }
  }
`;

export function useDeleteUserGroupMutation() {
  return Urql.useMutation<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >(DeleteUserGroupDocument);
}
export const AddUserGroupDocument = gql`
  mutation AddUserGroup($input: AddUserGroupInput!) {
    addUserGroup(input: $input) {
      mitarbeiterGruppe {
        __typename
        id
        name
      }
    }
  }
`;

export function useAddUserGroupMutation() {
  return Urql.useMutation<AddUserGroupMutation, AddUserGroupMutationVariables>(
    AddUserGroupDocument,
  );
}
