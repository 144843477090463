import {type FC} from 'react';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {
  type FormularSchrittFeld_StringFormularFeld_Fragment,
  type AntragDetailAusfuehrung,
} from '../../detail.generated.ts';

export type StringFormularFeldProps = {
  ausfuehrung: AntragDetailAusfuehrung;
  feld: FormularSchrittFeld_StringFormularFeld_Fragment;
};

export const StringFormularFeld: FC<StringFormularFeldProps> = ({
  ausfuehrung,
  feld,
}) => {
  const slotValue =
    ausfuehrung.slotValues.find((sv) => sv.slotName === feld.slotName) ?? '';
  const hasOptions = feld.options.length > 0;
  return (
    <SlotValue<string> value={slotValue}>
      {(value) => (
        <>
          {hasOptions
            ? feld.options.find((o) => o.key === value)?.value
            : value}
        </>
      )}
    </SlotValue>
  );
};
