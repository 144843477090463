import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect} from 'react';
import {useEdges, useStore} from 'reactflow';
import {ExpressionInputFormField} from '../../../../components/form/fields/expression-input-field/expression-input-field.tsx';
import {InputFormField} from '../../../../components/form/fields/input-field';
import {Form} from '../../../../components/form/form.tsx';
import {Sidebar} from '../../../../components/sidebar/sidebar.tsx';
import {Button} from '../../../../components/ui/button';
import {useProzessEditorStore} from '../lib/store';
import {type VerbindungDaten} from '../lib/store/prozess-aenderungen.ts';
import {SidebarHeader} from './sidebar-header';

type VerbindungForm = VerbindungDaten;

export const VerbindungSidebar: FC = () => {
  const selectedVerbindung = useProzessEditorStore(
    (store) => store.selectedVerbindung,
  );
  const updateVerbindung = useProzessEditorStore(
    (store) => store.updateVerbindung,
  );

  const builder = useFormBuilder<VerbindungForm>();

  const {reset} = builder;
  useEffect(() => {
    if (!selectedVerbindung) {
      reset();
      return;
    }

    reset(selectedVerbindung);
  }, [reset, selectedVerbindung]);

  const edges = useEdges();
  const setEdges = useStore((store) => store.setEdges);

  const handleSubmit = useCallback(
    (form: VerbindungForm) => {
      const id = selectedVerbindung?.id;
      if (!id) return;
      updateVerbindung(id, form);

      const target = edges.find((e) => e.id === id);
      if (!target || target.label === form.name) return;

      target.label = form.name;

      setEdges(edges);
    },
    [selectedVerbindung, updateVerbindung, setEdges, edges],
  );

  return (
    <Sidebar isOpen={!!selectedVerbindung}>
      <SidebarHeader title={selectedVerbindung?.name ?? 'Verbindung'} />

      <Form
        builder={builder}
        onSubmit={handleSubmit}
        className='flex h-full w-[480px] flex-1 flex-col gap-4 overflow-auto p-4'
      >
        <InputFormField label='Name' on={builder.fields.name} />
        <InputFormField
          label='Statusänderung (Titel)'
          on={builder.fields.statusAenderung}
        />
        <InputFormField
          label='Statusänderung (Beschreibung)'
          on={builder.fields.statusAenderungBeschreibung}
        />
        <ExpressionInputFormField
          label='Bedingung'
          on={builder.fields.istRelevant}
          kind='bool'
        />

        <Button variant='solid'>Übernehmen</Button>
      </Form>
    </Sidebar>
  );
};
