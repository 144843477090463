import * as Types from '../../../__generated__/graphql';

import {ProzessEditorVerbindungFragment} from './prozess-editor-verbindung.generated';
import {
  ProzessEditorFormularFeld_CheckboxListFormularFeld_Fragment,
  ProzessEditorFormularFeld_DateFormularFeld_Fragment,
  ProzessEditorFormularFeld_LocationFormularFeld_Fragment,
  ProzessEditorFormularFeld_SignatureFormularFeld_Fragment,
  ProzessEditorFormularFeld_StringFormularFeld_Fragment,
  ProzessEditorFormularFeld_TimeFormularFeld_Fragment,
  ProzessEditorFormularFeld_UploadFormularFeld_Fragment,
} from './prozess-editor-formular-feld.generated';
import {JsonPartFragment} from './json-fragments.generated';
import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import {ProzessEditorVerbindungFragmentDoc} from './prozess-editor-verbindung.generated';
import {ProzessEditorFormularFeldFragmentDoc} from './prozess-editor-formular-feld.generated';
import {JsonPartFragmentDoc} from './json-fragments.generated';

export type ProzessEditorSchritt_BezahlenSchritt_Fragment = {
  __typename: 'BezahlenSchritt';
  betrag: number;
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  slots: Array<{
    __typename: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
};

export type ProzessEditorSchritt_EmailForwarderExportSchritt_Fragment = {
  __typename: 'EmailForwarderExportSchritt';
  emailAddress: StringExpression;
  subject: StringExpression;
  body: StringExpression;
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchritt_EndeSchritt_Fragment = {
  __typename: 'EndeSchritt';
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchritt_FormularSchritt_Fragment = {
  __typename: 'FormularSchritt';
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  felder: Array<
    | ({
        __typename?: 'CheckboxListFormularFeld';
      } & ProzessEditorFormularFeld_CheckboxListFormularFeld_Fragment)
    | ({
        __typename?: 'DateFormularFeld';
      } & ProzessEditorFormularFeld_DateFormularFeld_Fragment)
    | ({
        __typename?: 'LocationFormularFeld';
      } & ProzessEditorFormularFeld_LocationFormularFeld_Fragment)
    | ({
        __typename?: 'SignatureFormularFeld';
      } & ProzessEditorFormularFeld_SignatureFormularFeld_Fragment)
    | ({
        __typename?: 'StringFormularFeld';
      } & ProzessEditorFormularFeld_StringFormularFeld_Fragment)
    | ({
        __typename?: 'TimeFormularFeld';
      } & ProzessEditorFormularFeld_TimeFormularFeld_Fragment)
    | ({
        __typename?: 'UploadFormularFeld';
      } & ProzessEditorFormularFeld_UploadFormularFeld_Fragment)
  >;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchritt_GovManagerRestExportSchritt_Fragment = {
  __typename: 'GovManagerRestExportSchritt';
  vorgangsTyp: string;
  mandantenSchluessel: string;
  vorgangsSchluessel: string;
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  exportParts: Array<
    | {__typename: 'FilePart'; slotName: string}
    | ({__typename: 'JsonPart'} & JsonPartFragment)
  >;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchritt_ProgrammatischerSchritt_Fragment = {
  __typename: 'ProgrammatischerSchritt';
  implementationAssemblyQualifiedName: string;
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchritt_WarteAufBezahlungAbgeschlossenSchritt_Fragment =
  {
    __typename: 'WarteAufBezahlungAbgeschlossenSchritt';
    bezahlenSchrittId: string;
    id: string;
    titel?: string | null;
    anweisung?: string | null;
    erledigungDurchBuerger: boolean;
    mitarbeiterGruppe?: {
      __typename?: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
    verbindungen: Array<
      {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
    >;
    slots: Array<{
      __typename?: 'Slot';
      name: string;
      encrypted: boolean;
      valueType: Types.SlotValueType;
      optional: boolean;
    }>;
  };

export type ProzessEditorSchritt_WarteAufGovManagerSchritt_Fragment = {
  __typename: 'WarteAufGovManagerSchritt';
  govManagerSchrittId: string;
  id: string;
  titel?: string | null;
  anweisung?: string | null;
  erledigungDurchBuerger: boolean;
  mitarbeiterGruppe?: {
    __typename?: 'MitarbeiterGruppe';
    id: string;
    name: string;
  } | null;
  verbindungen: Array<
    {__typename?: 'Verbindung'} & ProzessEditorVerbindungFragment
  >;
  slots: Array<{
    __typename?: 'Slot';
    name: string;
    encrypted: boolean;
    valueType: Types.SlotValueType;
    optional: boolean;
  }>;
};

export type ProzessEditorSchrittFragment =
  | ProzessEditorSchritt_BezahlenSchritt_Fragment
  | ProzessEditorSchritt_EmailForwarderExportSchritt_Fragment
  | ProzessEditorSchritt_EndeSchritt_Fragment
  | ProzessEditorSchritt_FormularSchritt_Fragment
  | ProzessEditorSchritt_GovManagerRestExportSchritt_Fragment
  | ProzessEditorSchritt_ProgrammatischerSchritt_Fragment
  | ProzessEditorSchritt_WarteAufBezahlungAbgeschlossenSchritt_Fragment
  | ProzessEditorSchritt_WarteAufGovManagerSchritt_Fragment;

export const ProzessEditorSchrittFragmentDoc = gql`
  fragment ProzessEditorSchritt on Schritt {
    __typename
    id
    titel
    anweisung
    erledigungDurchBuerger
    mitarbeiterGruppe {
      id
      name
    }
    verbindungen {
      ...ProzessEditorVerbindung
    }
    slots {
      name
      encrypted
      valueType
      optional
    }
    ... on FormularSchritt {
      felder {
        ...ProzessEditorFormularFeld
      }
    }
    ... on BezahlenSchritt {
      betrag
      slots {
        __typename
        name
      }
    }
    ... on WarteAufBezahlungAbgeschlossenSchritt {
      bezahlenSchrittId
    }
    ... on GovManagerRestExportSchritt {
      exportParts {
        __typename
        ... on FilePart {
          slotName
        }
        ... on JsonPart {
          ...JsonPart
        }
      }
      vorgangsTyp
      mandantenSchluessel
      vorgangsSchluessel
    }
    ... on WarteAufGovManagerSchritt {
      govManagerSchrittId
    }
    ... on ProgrammatischerSchritt {
      implementationAssemblyQualifiedName
    }
    ... on EmailForwarderExportSchritt {
      emailAddress
      subject
      body
    }
  }
  ${ProzessEditorVerbindungFragmentDoc}
  ${ProzessEditorFormularFeldFragmentDoc}
  ${JsonPartFragmentDoc}
`;
