import {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
  type OnConnect,
  type OnConnectStart,
  type OnConnectEnd,
} from 'reactflow';
import {useAsyncMemo} from '../../../../lib/hooks/use-async-memo.ts';
import {Layouter} from '../lib/layouter.tsx';

import 'reactflow/dist/style.css';
import './reactflow-style-overrides.css';
import {useProzessEditorStore} from '../lib/store';
import {useProzessEditorSelectionHandler} from '../lib/store/use-prozess-editor-selection-handler.ts';
import {type ProzessEditorSchritt} from '../prozess-version-erstellen.generated.ts';
import {KommunalappProzessEdge} from './kommunalapp-prozess-edge.tsx';
import {KommunalappSchrittNode} from './kommunalapp-schritt-node.tsx';
import {
  NeuerSchrittSelect,
  newSchrittByType,
  type NeuerSchrittSelectProps,
} from './neuer-schritt.tsx';

const layouter = new Layouter();
const edgeTypes = {
  kommunalEdge: KommunalappProzessEdge,
};
const nodeTypes = {
  schritt: KommunalappSchrittNode,
};

export const EditorPane: FC = () => {
  const prozess = useProzessEditorStore((state) => state.prozess);
  const updateSchritt = useProzessEditorStore((state) => state.updateSchritt);
  const createSchritt = useProzessEditorStore((state) => state.createSchritt);
  const createVerbindung = useProzessEditorStore(
    (state) => state.createVerbindung,
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [neuerSchrittSelect, setNeuerSchrittSelect] = useState<
    Omit<NeuerSchrittSelectProps, 'onSelect' | 'onClickAway'> | undefined
  >(undefined);

  const reactFlowRef = useRef<HTMLDivElement | null>(null);
  const connectingNodeId = useRef<string | null>(null);

  const schritte = prozess.schritte;
  const verbindungen = prozess.verbindungen;

  const layouted = useAsyncMemo(async () => {
    const startId = prozess?.startId;

    if (schritte.length === 0 || !startId) {
      return {nodes: [], edges: []};
    }

    return await layouter.layout(schritte, startId, verbindungen);
  }, [prozess, schritte, verbindungen]);

  useEffect(() => {
    setNodes(layouted?.nodes ?? []);
    setEdges(layouted?.edges ?? []);
  }, [setNodes, setEdges, layouted]);

  const selectionHandler = useProzessEditorSelectionHandler();

  const onConnect = useCallback<OnConnect>(
    ({source, target}) => {
      connectingNodeId.current = null;

      if (!source || !target) return;

      const sourceSchritt = schritte.find((s) => s.id === source);
      const targetSchritt = schritte.find((s) => s.id === target);

      if (!source || !target) return;
      if (!sourceSchritt || !targetSchritt) return;

      const newVerbindung = createVerbindung({
        vonId: source,
        nachId: target,
        name: null,
        statusAenderung: null,
        statusAenderungBeschreibung: null,
        istRelevant: 'true',
      });

      updateSchritt(source, {
        verbindungen: [...sourceSchritt.verbindungen, newVerbindung],
      });
    },
    [schritte, createVerbindung, updateSchritt],
  );

  const onConnectStart = useCallback<OnConnectStart>((_, {nodeId}) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback<OnConnectEnd>(
    (event) => {
      if (
        !connectingNodeId.current ||
        !reactFlowRef.current ||
        !('clientX' in event)
      )
        return;

      const eventTarget = event.target as HTMLElement | null;

      const targetIsPane = eventTarget?.classList.contains('react-flow__pane');
      const pane = reactFlowRef.current.getBoundingClientRect();

      const offsetY = 200;
      const offsetX = 250;

      if (targetIsPane) {
        setNeuerSchrittSelect({
          top:
            event.clientY < pane.bottom - offsetY
              ? event.clientY - pane.top
              : undefined,
          left:
            event.clientX < pane.right - offsetX
              ? event.clientX - pane.left
              : undefined,
          right:
            event.clientX >= pane.right - offsetX
              ? pane.width - event.clientX
              : undefined,
          bottom:
            event.clientY >= pane.bottom - offsetY
              ? pane.height - event.clientY + pane.top
              : undefined,
        });
      }
    },
    [setNeuerSchrittSelect],
  );

  const addNewSchritt = (
    schrittLabel: string,
    typeName: ProzessEditorSchritt['__typename'],
  ) => {
    const vonId = connectingNodeId.current!;
    const vonSchritt = schritte.find((s) => s.id === vonId);

    if (!vonSchritt) return;

    const schrittData = newSchrittByType({
      titel: schrittLabel,
      __typename: typeName,
    });

    const newSchritt = createSchritt(schrittData);

    const newVerbindung = createVerbindung({
      vonId,
      nachId: newSchritt.id,
      name: null,
      statusAenderung: null,
      statusAenderungBeschreibung: null,
      istRelevant: 'true',
    });

    updateSchritt(vonId, {
      verbindungen: [...vonSchritt.verbindungen, newVerbindung],
    });
  };

  const onSchrittSelect: NeuerSchrittSelectProps['onSelect'] = (
    label,
    typename,
  ) => {
    addNewSchritt(label, typename);
    setNeuerSchrittSelect(undefined);
  };

  if (!prozess) {
    return (
      <div className='flex flex-1 flex-col items-center justify-center'>
        Kein Prozess ausgewählt.
      </div>
    );
  }

  return (
    <div className='size-full bg-gray-100'>
      <ReactFlow
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onPaneMouseEnter={() => setNeuerSchrittSelect(undefined)}
        edgesFocusable={false}
        multiSelectionKeyCode={null}
        selectionKeyCode={null}
        selectNodesOnDrag={false}
        nodeDragThreshold={1}
        snapGrid={[10, 10]}
        snapToGrid
        ref={reactFlowRef}
        onSelectionChange={({nodes, edges}) => selectionHandler(nodes, edges)}
      >
        <Background gap={10} />
        <Controls />
        <MiniMap pannable zoomable zoomStep={1} />
        {neuerSchrittSelect && (
          <NeuerSchrittSelect
            onSelect={onSchrittSelect}
            onClickAway={() => setNeuerSchrittSelect(undefined)}
            {...neuerSchrittSelect}
          />
        )}
      </ReactFlow>
    </div>
  );
};
