import {Fragment, type ReactNode} from 'react';
import {type CryptoCache} from '../../../lib/hooks/use-crypto-cache.ts';
import {DecryptValue} from '../decrypt-value.tsx';
import {type SlotValueFragment} from './slot-value.generated.ts';

export type SlotValueProps<T> = {
  value: SlotValueFragment | string;
  children?: (value: T | string) => ReactNode;

  decryptionKey?: CryptoKey;
  cryptoCache?: CryptoCache;
};

export function SlotValue<T>({value, children, ...decrypt}: SlotValueProps<T>) {
  children ??= (value: T | string) => <>{value}</>;

  if (typeof value === 'string') return children(value);

  if (value.__typename === 'PlainSlotValue') {
    if (Array.isArray(value.plainValue)) {
      return (
        <>
          {value.plainValue.map((v, i) => (
            <Fragment key={i}>{children?.(v as T)}</Fragment>
          ))}
        </>
      );
    }

    return children(value.plainValue as T);
  }

  if (value.__typename === 'EncryptedSlotValue') {
    return (
      <DecryptValue value={value.encryptedValue} {...decrypt}>
        {children}
      </DecryptValue>
    );
  }
}
