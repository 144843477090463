import type {FormBuilder} from '@atmina/formbuilder';
import {type ReactNode, type PropsWithChildren} from 'react';
import {MdAdd, MdClear} from 'react-icons/md';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {ExpressionInputFormField} from 'src/components/form/fields/expression-input-field/expression-input-field.tsx';
import {ToolTip} from 'src/components/ui/tool-tip.tsx';
import {CheckboxFormField} from '../../../../../components/form/fields/checkbox-field';
import {InputFormField} from '../../../../../components/form/fields/input-field';
import {Button} from '../../../../../components/ui/button';
import {type FormularSchrittFormFelderType} from './formular-schritt-form.tsx';

const feldTypeRecord: Record<
  FormularSchrittFormFelderType['__typename'],
  string
> = {
  CheckboxListFormularFeld: 'Checkboxfeld',
  DateFormularFeld: 'Datumsfeld',
  StringFormularFeld: 'Textfeld',
  UploadFormularFeld: 'Uploadfeld',
  LocationFormularFeld: 'Standortfeld',
  TimeFormularFeld: 'Uhrzeitfeld',
  SignatureFormularFeld: 'Unterschriftfeld',
};
export const ProzessEditorFormFeld = ({
  on,
  onRemove,
}: {
  on: FormBuilder<FormularSchrittFormFelderType>;
  onRemove: () => void;
}) => {
  const [__typename, narrowed] = on.$discriminate('__typename');
  return (
    <div className='space-y-4'>
      <FormFeldSectionHeading
        titel={feldTypeRecord[__typename]}
        toolTip={
          __typename === 'SignatureFormularFeld' && (
            <ToolTip tip='Dieses Feld dient der Erfassung von Unterschriften, die nicht weiter verarbeitet werden. Für Signaturen, die weiterverarbeitet werden, verwenden Sie bitte das Upload-Feld.' />
          )
        }
      >
        <FormFeldRemoveButton onClick={onRemove} />
      </FormFeldSectionHeading>
      <InputFormField
        rules={{required: true}}
        on={on.slotName}
        label='Name des Slot *'
      />
      <InputFormField on={on.angezeigterName} label='Name des Formularfeld ' />
      <InputFormField on={on.anweisung} label='Anweisung (Platzhalter)' />
      <ExpressionInputFormField
        kind='bool'
        on={on.istRelevant}
        label='Ist relevant'
      />
      <div>
        {__typename === 'StringFormularFeld' && <StringFeld on={narrowed} />}
        {__typename === 'CheckboxListFormularFeld' && (
          <CheckboxListFeld on={narrowed} />
        )}
        {__typename === 'UploadFormularFeld' && <UploadFeld on={narrowed} />}
        {__typename === 'LocationFormularFeld' && <LocationFeld />}
        {__typename === 'DateFormularFeld' && <DateFeld />}
      </div>
    </div>
  );
};

type StringFormularFeld = Extract<
  FormularSchrittFormFelderType,
  {__typename: 'StringFormularFeld'}
>;
const StringFeld = ({on}: {on: FormBuilder<StringFormularFeld>}) => {
  const options = on.options.$useFieldArray();
  return (
    <>
      <FormFeldSectionHeading titel='Optionen'>
        <FormFeldAddButton
          onClick={() =>
            options.append({key: `option-${options.fields.length}`, value: ''})
          }
        />
      </FormFeldSectionHeading>
      {options.fields.map((option, index) => (
        <div key={option.$key} className='flex w-full gap-2'>
          <InputFormField
            className='hidden w-full bg-white'
            on={option.key}
            label={`Option-${index + 1}`}
          />
          <InputFormField
            className='hidden w-full bg-white'
            on={option.key}
            label={`Option-${index + 1}`}
          />
          <InputFormField
            className='w-full bg-white'
            on={option.value}
            label={`Option ${index + 1}`}
          />
          <button type='button' onClick={() => options.remove(index)}>
            <RiDeleteBin6Line className='size-6 text-red-500' />
          </button>
        </div>
      ))}
    </>
  );
};

type CheckboxListFormularFeld = Extract<
  FormularSchrittFormFelderType,
  {__typename: 'CheckboxListFormularFeld'}
>;
const CheckboxListFeld = ({
  on,
}: {
  on: FormBuilder<CheckboxListFormularFeld>;
}) => {
  const options = on.options.$useFieldArray();
  return (
    <>
      <FormFeldSectionHeading titel='Optionen'>
        <FormFeldAddButton
          onClick={() =>
            options.append({
              key: `option-${options.fields.length}`,
              label: 'Neuer Eintrag',
              defaultChecked: false,
              order: options.fields.length,
            })
          }
        />
      </FormFeldSectionHeading>
      {options.fields.map((option, index) => (
        <div key={option.$key} className='flex w-full gap-2'>
          <InputFormField
            className='hidden w-full bg-white'
            on={option.key}
            label={`Option-${index + 1}`}
          />
          <div className='mb-4 flex w-full flex-col'>
            <div className='flex gap-2'>
              <InputFormField
                className='w-full bg-white'
                on={option.label}
                label={`Option ${index + 1}`}
              />
              <button type='button' onClick={() => options.remove(index)}>
                <RiDeleteBin6Line className='size-6 text-red-500' />
              </button>
            </div>
            <CheckboxFormField
              on={option.defaultChecked}
              label='Vorausgewählt'
              orientation='checkbox-left'
            />
          </div>
        </div>
      ))}
    </>
  );
};

type UploadFormularFeld = Extract<
  FormularSchrittFormFelderType,
  {__typename: 'UploadFormularFeld'}
>;
const UploadFeld = ({on}: {on: FormBuilder<UploadFormularFeld>}) => (
  <>
    <FormFeldSectionHeading titel='Optionen' />
    <CheckboxFormField
      on={on.multi}
      label='Mehrere Uploads erlauben?'
      orientation='checkbox-left'
    />
    <div className='flex'>
      <CheckboxFormField
        on={on.document}
        label='Bürger bei der Eingabe von Dokumenten unterstützen? '
        orientation='checkbox-left'
        className='items-start'
      />
      <ToolTip
        tip='Wenn das Kontrollkästchen aktiviert ist, werden dem Bürger die Optionen
      Foto-Upload und Dateiauswahl angeboten. Andernfalls wird nur die normale
      Fotofunktion des Mobilgeräts angeboten.'
      />
    </div>
  </>
);

const LocationFeld = () => null;
const DateFeld = () => null;

export type FormFeldSectionHeadingProps = {
  titel: string;
  toolTip?: ReactNode;
};

const FormFeldSectionHeading = ({
  titel,
  toolTip,
  children,
}: PropsWithChildren<FormFeldSectionHeadingProps>) => (
  <div className='flex items-center font-bold'>
    <p className='text-sm font-normal'>{titel}</p>
    {toolTip && <div className='px-2'>{toolTip}</div>}
    <hr className='ml-4 h-px w-full' />
    {children}
  </div>
);

export const FormFeldActionButton = ({
  onClick,
  children,
}: PropsWithChildren<{onClick: () => void}>) => (
  <Button
    variant='text'
    type='button'
    className='flex items-center font-normal text-gray-500'
    onClick={onClick}
  >
    {children}
  </Button>
);

export const FormFeldRemoveButton = ({onClick}: {onClick: () => void}) => (
  <FormFeldActionButton onClick={onClick}>
    <MdClear />
  </FormFeldActionButton>
);

export const FormFeldAddButton = ({onClick}: {onClick: () => void}) => (
  <FormFeldActionButton onClick={onClick}>
    <MdAdd />
    Hinzufügen
  </FormFeldActionButton>
);
