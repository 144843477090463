import type {FormBuilder, RegisterOptions} from '@atmina/formbuilder';
import {type ForwardedRef, forwardRef, useCallback, useState} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';
import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';
import {InputField, type InputFieldProps} from '../input-field';

const expressionMap = {
  bool: BoolExpression,
  int: IntExpression,
  string: StringExpression,
} as const;

type ExpressionKind = keyof typeof expressionMap;
type ExpressionInstance<T extends ExpressionKind> = InstanceType<
  (typeof expressionMap)[T]
>;

export type ExpressionInputFieldProps<TKind extends ExpressionKind> = {
  value?: string;
  onChange?: (newValue: string) => void;
  kind: TKind;
} & Omit<InputFieldProps, 'value' | 'onChange'>;

const tryParse = <T extends ExpressionKind>(
  rawExpression: string,
  kind: T,
):
  | {success: true; expression: ExpressionInstance<T>}
  | {success: false; expression?: undefined; error?: unknown} => {
  try {
    const classConstructor = expressionMap[kind];
    const expression = new classConstructor(
      rawExpression,
    ) as ExpressionInstance<T>;

    return {success: true, expression};
  } catch (e) {
    return {success: false, error: e};
  }
};

export const ExpressionInputField = forwardRef(function ExpressionInputField<
  T extends ExpressionKind,
>(
  {value, onChange, kind, error, ...rest}: ExpressionInputFieldProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [errorState, setErrorState] = useState(error);
  const onChangeParsed = useCallback(
    (rawExpression: string) => {
      const parseResult = tryParse(rawExpression, kind);

      if (!parseResult.success && 'error' in parseResult) {
        setErrorState(parseResult.error as string);
      }
      if (parseResult.success) {
        setErrorState(undefined);
      }

      onChange?.(rawExpression);
    },
    [kind, onChange],
  );
  return (
    <InputField
      onChange={(e) => onChangeParsed(e.target.value)}
      value={value}
      ref={ref}
      error={errorState}
      {...rest}
    />
  );
});

export type ExpressionInputFormFieldProps<T extends ExpressionKind> = Omit<
  ExpressionInputFieldProps<T>,
  keyof UseFormRegisterReturn
> & {
  on: FormBuilder<string>;
  rules?: RegisterOptions<ExpressionInstance<T>>;
};

export function ExpressionInputFormField<T extends ExpressionKind>({
  on,
  rules,
  className,
  kind,
  ...rest
}: ExpressionInputFormFieldProps<T>) {
  const {
    field,
    fieldState: {error},
  } = on.$useController({
    rules: {
      validate: (value, _) => {
        const parseResult = tryParse(value, kind);
        return parseResult.success || String(parseResult.error);
      },
    },
  });
  return (
    <ExpressionInputField
      {...field}
      {...rest}
      kind={kind}
      error={error?.message}
      className={twMerge(className, 'font-mono')}
    />
  );
}
