import {SmartStepEdge} from '@tisoap/react-flow-smart-edge';
import {type CSSProperties, type FC} from 'react';
import {type EdgeProps} from 'reactflow';
import {useProzessEditorStore} from '../lib/store';

export type KommunalappProzessEdgeProps = EdgeProps<unknown>;

const selectedEdgeStyle: CSSProperties = {
  stroke: 'rgb(var(--color-primary))',
  strokeWidth: 2,
};

export const KommunalappProzessEdge: FC<KommunalappProzessEdgeProps> = (
  props,
) => {
  const selectedSchritt = useProzessEditorStore(
    (state) => state.selectedSchritt,
  );

  const {style, source, target} = props;
  const isRelatedToSelectedNode =
    source === selectedSchritt?.id || target === selectedSchritt?.id;

  const appliedStyle = isRelatedToSelectedNode
    ? {...style, ...selectedEdgeStyle}
    : style;

  return <SmartStepEdge {...props} style={appliedStyle} />;
};
