import {type StateCreator} from 'zustand';
import {
  deepUnparseExpressions,
  type WithUnparsedExpressions,
} from '@expressions/deep-unparse-expressions.ts';
import {type DistributiveOmit} from '../../../../../utils/common-types.ts';
import {type ProzessEditorVerbindungFragment} from '../../prozess-editor-verbindung.generated.ts';
import {type ProzessEditorSchritt} from '../../prozess-version-erstellen.generated.ts';
import {type ProzessEditorProzess} from '../../verfuegbare-prozesse.generated.ts';

export type SchrittDaten = WithUnparsedExpressions<ProzessEditorSchritt>;
export type VerbindungDaten =
  WithUnparsedExpressions<ProzessEditorVerbindungFragment>;

export type StoreProzess = {
  id: string;
  name: string;
  startId: string;
  schritte: SchrittDaten[];
  verbindungen: VerbindungDaten[];
  latestVersionId?: string;
};

export type ProzessStore = {
  prozess: StoreProzess;
  hasAenderungen: boolean;

  initializeFromProzess: (prozess: StoreProzess | null) => void;

  createSchritt: (data: DistributiveOmit<SchrittDaten, 'id'>) => SchrittDaten;
  updateSchritt: (id: string, data: Partial<SchrittDaten>) => void;
  deleteSchritt: (id: string) => void;

  createVerbindung: (data: Omit<VerbindungDaten, 'id'>) => VerbindungDaten;
  updateVerbindung: (id: string, data: Partial<VerbindungDaten>) => void;
  deleteVerbindung: (id: string) => void;
};

const emptyProzess: StoreProzess = {
  id: '',
  startId: '',
  name: 'Neuer Prozess',
  schritte: [],
  verbindungen: [],
};

export const dataToStoreProzess = (
  prozess: ProzessEditorProzess,
): StoreProzess => {
  const schritte = prozess.latestVersion?.alleSchritte ?? [
    {
      id: crypto.randomUUID(),
      titel: 'Neuer Schritt',
      verbindungen: [],
      erledigungDurchBuerger: true,
      slots: [],
      mitarbeiterGruppe: null,
      anweisung: null,
      felder: [
        {
          id: crypto.randomUUID(),
          __typename: 'StringFormularFeld',
          angezeigterName: 'Neues Feld',
          multiline: false,
          istRelevant: 'true',
          options: [],
          slotName: 'Neuer Slot',
          order: 0,
          encrypted: false,
        },
      ],
      __typename: 'FormularSchritt',
    },
  ];
  return {
    id: prozess.id,
    latestVersionId: prozess.latestVersion?.id,
    startId: prozess.latestVersion?.start?.id ?? schritte[0].id,
    name: prozess.name,
    schritte: deepUnparseExpressions(schritte),
    verbindungen: deepUnparseExpressions(
      schritte.flatMap((s) => s.verbindungen.map((v) => ({...v}))),
    ),
  };
};

const copy = <T>(from: Partial<T>, to: T) => {
  for (const key in from) {
    const newValue = from[key];
    if (newValue) to[key] = newValue;
  }
};

export const createProzessWithAenderungenSlice: StateCreator<
  ProzessStore,
  [['zustand/immer', never]],
  [],
  ProzessStore
> = (set) => ({
  prozess: emptyProzess,
  hasAenderungen: false,

  initializeFromProzess: (prozess) =>
    set((state) => {
      state.prozess = prozess ?? emptyProzess;
      state.hasAenderungen = false;
    }),

  createSchritt: (data) => {
    const newSchritt = {...data, id: crypto.randomUUID()} as SchrittDaten;

    set((state) => {
      state.prozess.schritte.push(newSchritt);
      state.hasAenderungen = true;
    });

    return newSchritt;
  },
  updateSchritt: (id, data) => {
    set((state) => {
      const schritte = state.prozess.schritte;
      const target = schritte.find((s) => s.id === id);
      if (!target) return;

      if (Object.keys(data).length > 0) {
        copy(data, target);
        state.hasAenderungen = true;
      }
    });
  },
  deleteSchritt: (id) =>
    set((state) => {
      const schritte = state.prozess.schritte;
      const targetIndex = schritte.findIndex((s) => s.id === id);
      if (targetIndex > -1) {
        schritte.splice(targetIndex, 1);
        state.hasAenderungen = true;
      }
    }),

  createVerbindung: (data) => {
    const newVerbindung = {...data, id: crypto.randomUUID()};

    set((state) => {
      state.prozess.verbindungen.push(newVerbindung);

      state.hasAenderungen = true;
    });

    return newVerbindung;
  },
  updateVerbindung: (id, data) => {
    set((state) => {
      const target = state.prozess.verbindungen.find((v) => v.id === id);
      if (!target) return;

      if (Object.keys(data).length > 0) {
        copy(data, target);
        state.hasAenderungen = true;
      }
    });
  },
  deleteVerbindung: (id) => {
    set((state) => {
      state.hasAenderungen = true;
      const verbindungen = state.prozess.verbindungen;
      const index = verbindungen.findIndex((v) => v.id === id);
      if (index > -1) {
        verbindungen.splice(index, 1);
        state.hasAenderungen = true;
      }
    });
  },
});
