import {useMemo, useRef} from 'react';
import {
  asymmetricUnwrapKey,
  type EncryptedValue,
  symmetricDecryptValue,
} from '../crypto/crypto.tsx';

export type CryptoCache = {
  getDecryptedValueCached: (
    key: CryptoKey,
    value: EncryptedValue,
  ) => Promise<string>;
  getAsymmetricUnwrappedKeyCached: (
    key: CryptoKey,
    value: string,
  ) => Promise<CryptoKey>;
};

const createCacheKey = (encryptedValue: EncryptedValue) =>
  `${encryptedValue.tag};${encryptedValue.cipher};${encryptedValue.nonce}`;

export const useCryptoCache = () => {
  const cacheRef = useRef<Map<string, string>>();
  const keyCacheRef = useRef<Map<string, CryptoKey>>();

  let cache: Map<string, string>;

  if (!cacheRef.current) {
    cache = new Map<string, string>();
    cacheRef.current = cache;
  } else {
    cache = cacheRef.current;
  }

  let keyCache: Map<string, CryptoKey>;

  if (!keyCacheRef.current) {
    keyCache = new Map<string, CryptoKey>();
    keyCacheRef.current = keyCache;
  } else {
    keyCache = keyCacheRef.current;
  }

  return useMemo<CryptoCache>(
    () => ({
      async getDecryptedValueCached(key, value: EncryptedValue) {
        const cached = cache.get(createCacheKey(value));

        if (cached) {
          return cached;
        }

        const decrypted = await symmetricDecryptValue(key, value);
        cache.set(createCacheKey(value), decrypted);

        return decrypted;
      },
      async getAsymmetricUnwrappedKeyCached(key, value) {
        const cached = keyCache.get(value);

        if (cached) {
          return cached;
        }

        const decrypted = await asymmetricUnwrapKey(key, value);

        keyCache.set(value, decrypted);

        return decrypted;
      },
    }),
    [cache, keyCache],
  );
};
