import {useState} from 'react';
import {Button} from '../../../../components/ui/button';
import {DropdownMenu} from '../../../../components/ui/dropdown-menu/dropdown-menu.tsx';
import {ProzessAnlegenMenuItem} from './prozess/prozess-anlegen-menu-item.tsx';
import {ServicePortalLinkAnlegenMenuItem} from './service-portal-link/service-portal-link-anlegen-menu-item.tsx';

export const NeuenProzessAnlegenButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <Button>Neuen Prozess anlegen</Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <ProzessAnlegenMenuItem onDismiss={() => setOpen(false)} />
          <DropdownMenu.Item disabled>EfaLeistung</DropdownMenu.Item>
          <ServicePortalLinkAnlegenMenuItem onDismiss={() => setOpen(false)} />
          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};
