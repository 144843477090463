import {authExchange} from '@urql/exchange-auth';
import {type FC, type PropsWithChildren, useMemo} from 'react';
import {type NavigateFunction} from 'react-router';
import {
  cacheExchange,
  createClient,
  errorExchange,
  fetchExchange,
  Provider,
} from 'urql';
import customScalarsExchange from 'urql-custom-scalars-exchange';
import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';
import introspectionSchema from './__generated__/introspection';
import {useAuth} from './lib/context/auth/auth.tsx';

export const createUrqlClient = ({
  token,
  cache,
  navigate,
}: {token?: string; cache?: boolean; navigate?: NavigateFunction} = {}) =>
  createClient({
    url: '/graphql',
    requestPolicy: cache ? 'cache-first' : 'network-only',
    fetchOptions: {
      headers: {
        // https://github.com/mswjs/msw/issues/1593#issuecomment-1509003528
        Accept: '*/*',
      },
    },
    exchanges: [
      customScalarsExchange({
        schema: introspectionSchema,
        scalars: {
          DateTime: (value?: unknown) =>
            typeof value === 'string' ? new Date(value) : value,
          Date: (value?: unknown) =>
            typeof value === 'string' ? new Date(value) : value,
          Instant: (value?: unknown) =>
            typeof value === 'string' ? new Date(value) : value,
          BoolExpression: (value?: unknown) =>
            typeof value === 'string' ? new BoolExpression(value) : value,
          IntExpression: (value?: unknown) =>
            typeof value === 'string' ? new IntExpression(value) : value,
          StringExpression: (value?: unknown) =>
            typeof value === 'string' ? new StringExpression(value) : value,
        },
      }),
      authExchange(async (util) => ({
        didAuthError(): boolean {
          return false;
        },
        async getAuth(): Promise<null> {
          return null;
        },
        willAuthError(): boolean {
          return false;
        },
        refreshAuth() {
          return Promise.resolve();
        },
        addAuthToOperation(operation) {
          if (token === undefined) {
            return operation;
          }

          return util.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
      })),
      ...(cache ? [cacheExchange] : []),
      ...(navigate
        ? [
            errorExchange({
              onError(error) {
                if (
                  error?.graphQLErrors.some(
                    (error) => error.message === 'Unauthenticated',
                  )
                ) {
                  navigate('/auth/login');
                }
              },
            }),
          ]
        : []),
      fetchExchange,
    ],
  });

export const UrqlProvider: FC<
  PropsWithChildren<{navigate?: NavigateFunction}>
> = ({children, navigate}) => {
  const {token} = useAuth();
  const client = useMemo(
    () => createUrqlClient({token, cache: true, navigate}),
    [navigate, token],
  );

  return <Provider value={client}>{children}</Provider>;
};

export class NoContextProvidedError extends Error {
  constructor(contextName: string) {
    super(`${contextName} must be provided before using it.`);
  }
}
