import {useEffect} from 'react';
import {type RouteObject} from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import {useTitle} from 'react-use';
import appstoreLogo from '../../assets/apps/appstore.svg';
import playstoreLogo from '../../assets/apps/playstore.svg';

const iOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const android = () => /android/i.test(navigator.userAgent);

const playstoreLink =
  'https://play.google.com/store/apps/details?id=de.atmina.kommunalapp.internal';
const appstoreLink = 'https://apps.apple.com/de/app/kommunal-app/id6464620334';

export const Deeplinks = () => {
  useTitle('Kommunal App');

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (iOS()) window.location.href = appstoreLink;
      else if (android()) window.open(playstoreLink);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className='flex h-screen flex-col items-center justify-center space-y-4 p-6'>
      <div className='mb-6'>
        <PulseLoader size={20} />
      </div>
      <p>Sie werden gleich weitergeleitet.</p>
      <p>
        Dies ist ein Link der KommunalApp. Um den Link öffnen zu können, stellen
        Sie sicher, dass Sie die App von Google Play oder dem AppStore
        heruntergeladen haben.
      </p>
      <div className='flex space-x-8'>
        <a href={playstoreLink}>
          <img
            src={playstoreLogo}
            alt='Logo des Google Play Store'
            className='h-16'
          />
        </a>
        <a href={appstoreLink}>
          <img
            src={appstoreLogo}
            alt='Logo des Apple App Store'
            className='h-16'
          />
        </a>
      </div>
    </div>
  );
};

export const deeplinksRoute: RouteObject = {
  path: 'deeplinks/*',
  element: <Deeplinks />,
};
