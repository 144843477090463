import {SlotValueType} from '../../../../__generated__/graphql.ts';
import {
  type GovManagerRestExportSchritt,
  type JsonSlotFormFieldProps,
  type JsonStringFormFieldProps,
} from '../components/form/govmanager-export-schritt.tsx';
import {
  type JsonFieldFragment,
  type JsonPartFragment,
  type JsonValueWithSlots_JsonObject_Fragment,
  type JsonValueWithSlotsFragment,
  type JsonValueWithSlotsRecursive_JsonObject_Fragment,
  type JsonValueWithSlotsRecursiveFragment,
} from '../json-fragments.generated.ts';

type SlotType = {
  __typename?: 'Slot' | 'String' | undefined;
  name: string;
  encrypted: boolean;
  valueType: SlotValueType;
  optional: boolean;
};

type JsonValueTypes = Exclude<
  JsonValueWithSlotsFragment,
  JsonValueWithSlots_JsonObject_Fragment
>['__typename'];

export const dotPathToExportParts = (
  allProcessSlots: SlotType[],
  nextExportParts: Record<
    string,
    JsonSlotFormFieldProps | JsonSlotFormFieldProps
  >,
  nextStringExportParts: JsonStringFormFieldProps[],
) => {
  const exportsParts = [];
  const jsonPart: JsonPartFragment = {
    __typename: 'JsonPart',
    value: {__typename: 'JsonObject', fields: []},
  };
  const createNestedObject = (
    fields: JsonValueWithSlotsRecursive_JsonObject_Fragment['fields'],
    path: string[],
    leafType: JsonValueTypes,
    value: string,
  ) => {
    const isLeaf = path.length === 1;
    const pathToken = path.shift();
    if (!pathToken) return;
    if (isLeaf && pathToken) {
      const nextJsonField: Omit<JsonFieldFragment, 'value'> = {
        __typename: 'JsonField',
        name: pathToken,
      };
      if (leafType === 'JsonSlotValue') {
        fields.push({
          ...nextJsonField,
          value: {__typename: 'JsonSlotValue', slotName: value},
        });
      } else if (leafType === 'JsonStringValue') {
        fields.push({
          ...nextJsonField,
          value: {__typename: 'JsonStringValue', value: value},
        });
      }
    } else {
      const existingPath = fields
        .filter(
          (field): field is JsonFieldFragment =>
            field.__typename === 'JsonField',
        )
        .find((field) => field.name === pathToken);
      if (existingPath && path.length > 0) {
        if (existingPath.value.__typename === 'JsonObject') {
          createNestedObject(existingPath.value.fields, path, leafType, value);
        }
        return;
      }
      const jsonField: Omit<JsonFieldFragment, 'value'> & {
        value: JsonValueWithSlotsRecursive_JsonObject_Fragment;
      } = {
        __typename: 'JsonField',
        name: pathToken,
        value: {
          __typename: 'JsonObject',
          fields: [],
        },
      };
      fields.push(jsonField);
      createNestedObject(jsonField.value.fields, path, leafType, value);
    }
  };

  for (const slot of allProcessSlots) {
    const currentExportPart = nextExportParts[slot.name];
    if (!currentExportPart?.exportField) continue;
    if (slot.valueType === SlotValueType.Upload) {
      exportsParts.push({
        __typename: 'FilePart' as const,
        slotName: slot.name,
      });
      continue;
    }
    if (jsonPart.value.__typename === 'JsonObject') {
      createNestedObject(
        jsonPart.value.fields,
        currentExportPart.path.split('.'),
        'JsonSlotValue',
        slot.name,
      );
    }
  }

  for (const stringField of nextStringExportParts) {
    // Todo make this work
    if (jsonPart.value.__typename === 'JsonObject') {
      createNestedObject(
        jsonPart.value.fields,
        stringField.path.split('.'),
        'JsonStringValue',
        stringField.value,
      );
    }
  }
  exportsParts.push(jsonPart);
  return exportsParts;
};

export const getDefaultValuesFromExportParts = (
  slotNames: string[],
  exportParts: GovManagerRestExportSchritt['exportParts'],
) => {
  const defaultJsonsSlotFormFields: Record<string, JsonSlotFormFieldProps> =
    Object.fromEntries(
      slotNames.map((slot) => [slot, {exportField: false, path: ''}]),
    );
  const defaultJsonStringFormFields: JsonStringFormFieldProps[] = [];
  for (const exportPart of exportParts) {
    if (exportPart.__typename === 'FilePart') {
      defaultJsonsSlotFormFields[exportPart.slotName].exportField = true;
      defaultJsonsSlotFormFields[exportPart.slotName].path =
        exportPart.slotName; // Todo make path editable
    } else if (exportPart.__typename === 'JsonPart') {
      const fillFromNestedJsonValues = (
        value: JsonValueWithSlotsRecursiveFragment,
        currentPath: string,
      ) => {
        if (value.__typename === 'JsonSlotValue') {
          defaultJsonsSlotFormFields[value.slotName].exportField = true;
          defaultJsonsSlotFormFields[value.slotName].path = currentPath;
        } else if (value.__typename === 'JsonObject') {
          for (const field of value.fields) {
            const nextPath = currentPath
              ? `${currentPath}.${field.name}`
              : field.name;
            fillFromNestedJsonValues(field.value, nextPath);
          }
        } else if (value.__typename === 'JsonStringValue') {
          defaultJsonStringFormFields.push({
            path: currentPath,
            value: value.value,
          });
        }
      };
      fillFromNestedJsonValues(
        exportPart.value as JsonValueWithSlotsRecursiveFragment,
        '',
      );
    }
  }
  return {defaultJsonsSlotFormFields, defaultJsonStringFormFields};
};
