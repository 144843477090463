import {type FC} from 'react';
import {CheckboxField} from 'src/components/form/fields/checkbox-field';

type CheckboxFieldProps = {
  gruppe: {
    id: string;
    name: string;
  };
  defaultChecked?: boolean;
  checkedChange: () => void;
};

export const GroupCheckboxField: FC<CheckboxFieldProps> = ({
  gruppe,
  defaultChecked = false,
  checkedChange,
}) => (
  <CheckboxField
    key={gruppe.id}
    value={defaultChecked}
    label={gruppe.name}
    onChange={() => checkedChange()}
    orientation='checkbox-right'
    className='cursor-pointer'
  />
);
