import {CharStreams, CommonTokenStream} from 'antlr4ng';
import {ExpressionErrorListener} from '@expressions/expression-error-listener.ts';
import {ExpressionsLexer} from './parser/ExpressionsLexer.ts';
import {
  ExpressionsParser,
  type BoolValueContext,
  type BracketsContext,
  type CallContext,
  type ExprCallContext,
  type ExpressionContext,
  type ExpressionFileContext,
  type ExprValueContext,
  type IdContext,
  type NumberValueContext,
  type OperatorContext,
  type StringValueContext,
} from './parser/ExpressionsParser.ts';
import {ExpressionsVisitor} from './parser/ExpressionsVisitor.ts';

export abstract class Expression {
  public readonly parsed: ExpressionContext;

  public constructor(public readonly expression: string) {
    const inputStream = CharStreams.fromString(expression);
    const lexer = new ExpressionsLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new ExpressionsParser(tokenStream);
    // We remove the default listener which writes errors to stdout
    // We rather want to throw and catch parser errors.
    parser.removeErrorListeners();
    parser.addErrorListener(new ExpressionErrorListener());

    this.parsed = parser.expressionFile().expression()!;
  }

  evaluate(slots: Record<string, unknown>): unknown {
    return new EvaluatingVisitor(slots).visit(this.parsed);
  }

  // This function is implicitly called by JSON.stringify and should not be removed!
  // noinspection JSUnusedGlobalSymbols
  toJSON(): string {
    return this.expression;
  }
}

export class BoolExpression extends Expression {
  evaluate(slots: Record<string, unknown>): boolean {
    return super.evaluate(slots) as boolean;
  }
}

export class StringExpression extends Expression {
  evaluate(slots: Record<string, unknown>): string {
    return super.evaluate(slots) as string;
  }
}

export class IntExpression extends Expression {
  evaluate(slots: Record<string, unknown>): number {
    return super.evaluate(slots) as number;
  }
}

class EvaluatingVisitor extends ExpressionsVisitor<unknown> {
  constructor(private slots: Record<string, unknown>) {
    super();
  }

  visitExpressionFile = (ctx: ExpressionFileContext): unknown =>
    this.visit(ctx.expression());

  visitExprCall = (ctx: ExprCallContext): unknown => this.visit(ctx.call());

  visitExprValue = (ctx: ExprValueContext): unknown => this.visit(ctx.value());

  visitId = (ctx: IdContext): unknown => ctx.Identifier().getText();

  visitOperator = (ctx: OperatorContext): unknown => {
    if (ctx.EQ() !== null) {
      return this.visit(ctx._left!) === this.visit(ctx._right!);
    }

    if (ctx.NEQ() !== null) {
      return this.visit(ctx._left!) !== this.visit(ctx._right!);
    }

    if (ctx.GT() !== null) {
      return (
        (this.visit(ctx._left!) as string | number) >
        (this.visit(ctx._right!) as string | number)
      );
    }

    if (ctx.GTEQ() !== null) {
      return (
        (this.visit(ctx._left!) as string | number) >=
        (this.visit(ctx._right!) as string | number)
      );
    }

    if (ctx.LT() !== null) {
      return (
        (this.visit(ctx._left!) as string | number) <
        (this.visit(ctx._right!) as string | number)
      );
    }

    if (ctx.LTEQ() !== null) {
      return (
        (this.visit(ctx._left!) as string | number) <=
        (this.visit(ctx._right!) as string | number)
      );
    }

    if (ctx.AND() !== null) {
      return this.visit(ctx._left!) && this.visit(ctx._right!);
    }

    if (ctx.OR() !== null) {
      return this.visit(ctx._left!) || this.visit(ctx._right!);
    }

    if (ctx.PLUS() !== null) {
      return (
        (this.visit(ctx._left!) as string) + (this.visit(ctx._right!) as string)
      );
    }

    if (ctx.MINUS() !== null) {
      return (
        (this.visit(ctx._left!) as number) - (this.visit(ctx._right!) as number)
      );
    }

    if (ctx.TIMES() !== null) {
      return (
        (this.visit(ctx._left!) as number) * (this.visit(ctx._right!) as number)
      );
    }

    if (ctx.DIV() !== null) {
      return (
        (this.visit(ctx._left!) as number) / (this.visit(ctx._right!) as number)
      );
    }

    throw new Error(`Unknown operator ${ctx}`);
  };

  visitBrackets = (ctx: BracketsContext): unknown =>
    this.visit(ctx.expression());

  visitCall = (ctx: CallContext): unknown => {
    const functionName = ctx._name!.text;
    switch (functionName) {
      case 'slot': {
        const parameter = this.visit(ctx._parameter!);

        return this.slots[parameter as string];
      }
      case 'isInside':
        // Todo implement handling
        // const expressions = ctx._parameter?.expression() ?? [];
        // const nameParameter = expressions[0];
        // const locationParameter = expressions[1];
        // const strippedNameParameter = await this.visit(nameParameter);
        //
        // const location = await this.visit(locationParameter) as FormLocation;
        //   const isInside = await geoservice.isPointInGeoflaeche(
        //   strippedNameParameter,
        //   location.position,
        // );

        return false;

      default:
        throw new Error(`Unknown function ${functionName}`);
    }
  };

  visitNumberValue = (ctx: NumberValueContext): unknown =>
    parseInt(ctx.Number().getText(), 10);

  visitStringValue = (ctx: StringValueContext): unknown =>
    ctx
      .String()
      .getText()
      .slice(1, -1)
      .replace(/\\(.?)/g, (_, found) => {
        switch (found) {
          case '\\':
            return '\\';
          case '':
            return '';
          default:
            return found;
        }
      });

  visitBoolValue = (ctx: BoolValueContext): unknown =>
    ctx.Bool().getText() === 'true';
}
