import {type FC, type PropsWithChildren} from 'react';
import {NavLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

export type LinkProps = PropsWithChildren<{
  to: string;
  setIsActive?: (active: boolean) => void;
  className?: string;
}>;

export const Link: FC<LinkProps> = ({to, setIsActive, children, className}) => (
  <NavLink
    to={to}
    className={({isActive}) => {
      setIsActive?.(isActive);

      return twMerge(
        'flex h-16 items-center pt-1 font-bold',
        isActive && `text-primary`,
        className,
      );
    }}
  >
    {children}
  </NavLink>
);
