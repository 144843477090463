import {type FC} from 'react';
import {CheckboxField} from '../../../../../components/form/fields/checkbox-field';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {type AntragDetailAusfuehrung} from '../../detail.generated.ts';

export type CheckboxListFormularFeldProps = {
  slotName: string;
  ausfuehrung: AntragDetailAusfuehrung;
};

export const CheckboxListFormularFeld: FC<CheckboxListFormularFeldProps> = ({
  slotName,
  ausfuehrung,
}) => {
  const slotValue =
    ausfuehrung.slotValues.find((sv) => sv.slotName === slotName) ?? '';
  const feld = ausfuehrung.prozessVersion.vergangeneSchritte
    .flatMap((s) =>
      s.schritt.__typename === 'FormularSchritt' ? s.schritt.felder : [],
    )
    .find(
      (f) =>
        f.slotName === slotName && f.__typename === 'CheckboxListFormularFeld',
    );

  if (!feld || feld.__typename !== 'CheckboxListFormularFeld') return;

  return (
    <SlotValue<string> value={slotValue}>
      {(value) => {
        const checkedKeys = new Set(value.split(','));

        return (
          <div className='mt-2 flex flex-col gap-2'>
            {feld.options.map((o) => (
              <CheckboxField
                key={o.key}
                label={o.label}
                value={checkedKeys.has(o.key)}
                orientation='checkbox-left'
                disabled
              />
            ))}
          </div>
        );
      }}
    </SlotValue>
  );
};
